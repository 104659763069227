<app-header></app-header>

<main>
	<h1>Sobre o {{global.Titulo_Portal}}</h1>
	<section>
		<div class="card">
			<div class="sobre">
				<img src="{{global.Api_Imagem + 'portal/img-sobre-o-portal.jpg'}}" alt="Imagem ilustrativa Sobre o Portal Imobiliario Tráfego Imóveis">
				<div class="conteudo">
					<h2>
						Nosso objetivo é buscar e encontrar a oportunidade imobiliária que você precisa para fazer o negócio dos seus sonhos
					</h2>
					<p>
						A Tráfego Imóveis não é uma imobiliária, é um portal imobiliário que encontra
						para você milhares de oportunidades em imóveis, seja para comprar ou alugar.
					</p>
					<p>
						Contamos com uma vasta base de dados, com milhares de imóveis cadastrados e
						centenas de imobiliárias que você pode consultar diretamente de seu computador ou celular sem sair de casa, em um único site.
					</p>
				</div>
			</div>
		</div>
	</section>

	<app-chamada-anuncie></app-chamada-anuncie>

	<section>
		<ul class="ferramentas">
			<li>
				<div class="card">
					<div>
						<i class="fas fa-search"></i>
						<h3>Ferramentas de Busca Avançadas</h3>
					</div>
					<p>
						Na Tráfego, você utiliza filtros inteligentes que permitem encontrar imóveis com as
						características que você deseja, entre elas: quantidade de cômodos específicos,
						localização e preço.
					</p>
				</div>
			</li>
			<li>
				<div class="card">
					<div>
						<i class="fas fa-arrow-circle-up"></i>
						<h3>Sistema de Busca com Direcionamento</h3>
					</div>
					<p>
						Na Tráfego, você utiliza filtros inteligentes que permitem encontrar imóveis com as
						características que você deseja, entre elas: quantidade de cômodos específicos,
						localização e preço.
					</p>
				</div>
			</li>
			<li>
				<div class="card">
					<div>
						<i class="fas fa-envelope"></i>
						<h3>Receba Notificações dos Imóveis</h3>
					</div>
					<p>
						Em sua área especial Tráfego Imóveis, monte uma lista de imóveis de seu interesse e
						realize comparativos entre eles, como suas características e valores.
					</p>
				</div>
			</li>
		</ul>
	</section>
</main>

<app-footer></app-footer>

<div class="modal" id="modal-inativar-alerta" *ngIf="abrir_modal">

  <div class="loading-conteudo" *ngIf="!token">
    <img src="{{global.Api_Imagem + 'portal/loading.gif'}}" class="text-center" alt="">
  </div>

  <div class="conteudo">
    <div class="fechar">
      <button class="btn alerta" (click)="fechar()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="geral">
      <div class="dados">
        <div class="formulario">
          <div class="form">
            <form *ngIf="ExibirFormulario" (ngSubmit)="onSubmit()" #testForm="ngForm" ngNativeValidate>

              <h4>
                <i class="fas fa-exclamation-triangle"></i>
                Inativar alerta?
              </h4>

              <p>Precisamos saber qual o motivo que está inativando seu alerta. Essa informação é muito importante, irá
                ajudar a manter um serviço melhor para você e todos os usuários do portal!</p>


              <label for="cmp-inativa-motivo">Selecione o motivo</label>
              <div class="campo">
                <select class="form-control" id="cmp-inativa-motivo" [(ngModel)]="form.motivo"
                        [ngModelOptions]="{standalone: true}">
                  <option *ngFor="let valor of motivoOpcoes"
                          [value]="valor['titulo']">
                    {{ valor['titulo'] }}
                  </option>
                </select>
              </div>

              <button type="submit" [disabled]="!testForm.form.valid" class="btn laranja">
                <i class="fab fa-telegram-plane"></i> Inativar
              </button>
            </form>
            <div *ngIf="!ExibirFormulario" class="enviando">
              <img *ngIf="ExibirFormularioEnviando"
                   src="{{global.Api_Imagem + 'portal/loading.gif'}}"
                   width="50"
                   alt="Enviando a mensagem">
              <br>
              <h3 *ngIf="ExibirFormularioEnviando">
                Solicitando a inativação do alerta!
              </h3>
              <h3 *ngIf="!ExibirFormularioEnviando">
                <i class="far fa-thumbs-up"></i>
                <br>
                Seu alerta foi inativado com sucesso
              </h3>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-header></app-header>

<main>

	<app-busca-inicial></app-busca-inicial>

	<section>
		<div class="filtros-rapidos">
			<div class="maispesquisadas">
				<div class="card ">
					<div class="header laranja">
						<h2>As mais pesquisadas</h2>
						<p>Top buscas feitas no portal</p>
					</div>
					<div class="content">
						<div *ngIf="!maispesquisadas" class="loading-conteudo">
							<img src="{{global.Api_Imagem + 'portal/loading.gif'}}" alt="">
						</div>
						<ul *ngIf="maispesquisadas">
							<li *ngFor="let link of maispesquisadas">
								<a href="{{link.link}}"
								   (click)="somar('conta_click', 'maisbuscadas', link.id, 'click')"
								   title="{{link.titulo}}">
									{{link.titulo}}
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="cidadesemdestaque">
				<div class="card">
					<div class="header">
						<h2>Cidades em destaque</h2>
						<p>Top buscas feitas no portal</p>
					</div>
					<div class="content">
						<div *ngIf="!cidadesemdestaque" class="loading-conteudo">
							<img src="{{global.Api_Imagem + 'portal/loading.gif'}}" alt="">
						</div>
						<div *ngIf="cidadesemdestaque" class="itens">
							<div *ngFor="let cidade of cidadesemdestaque; let i = index" class="bloco bloco_{{i}}">
								<div class="card">
									<div class="header midia"
										 [style.background-image]="'url(' + global.Api_Imagem + 'portal/' + cidade.arquivo + ')'">
										<h2>Imóveis em {{cidade.cidade}}</h2>
									</div>
									<ul>
										<li *ngFor="let opcao of cidade.opcoes">
											<a href="/imoveis?tipo={{opcao.tipo}}&localizacao={{cidade.cidade}}/{{cidade.estado}}"
											   (click)="somar('conta_click', 'cidades', opcao.id, opcao.campo)"
											   title="Mais de {{opcao.imoveis}} {{opcao.tipo}} em {{cidade.cidade}}">
												{{opcao.tipo}}
												<span>{{opcao.imoveis}}</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<app-chamada-anuncie></app-chamada-anuncie>

	<app-destaque-imobiliarias></app-destaque-imobiliarias>

	<section class="sobre">
		<article class="institucional">
			<div class="card">
				<div class="imagem"
					 [style.background-image]="'url(' + global.Api_Imagem + 'portal/capa-chamada-sobre.jpg' + ')'"></div>
				<div class="avatar">
					<img src="{{global.Api_Imagem + 'portal/logo-chamada-sobre.jpg'}}"
						 alt="Logo {{global.Titulo_Portal}}">
				</div>
				<div class="content">
					<h3>
						Nosso objetivo é buscar e encontrar a oportunidade imobiliária que você precisa para fazer o
						negócio dos seus sonhos
					</h3>
					<hr>
					<p>
						A Tráfego Imóveis não é uma imobiliária, é um portal imobiliário que encontra para você milhares
						de oportunidades em imóveis, seja para comprar ou alugar.
					</p>
					<p>
						Contamos com uma vasta base de dados, com milhares de imóveis cadastrados e centenas de
						imobiliárias que você pode consultar diretamente de seu computador ou celular sem sair de casa,
						em um único site.
					</p>
					<a href="/sobre-o-portal-imobiliario-trafego-imoveis" title="Veja mais sobre o"
					   class="btn azul">
						Saiba mais sobre nós
					</a>
				</div>
			</div>
		</article>
		<app-chamada-nossos-numeros></app-chamada-nossos-numeros>
		<aside *ngIf="!mobile" class="blog">
			<div class="card">
				<div class="header">
					<h2>Mundo Imobiliário</h2>
				</div>
				<div class="content">
					<div class="loading-conteudo" *ngIf="!post">
						<img src="{{global.Api_Imagem + 'portal/loading.gif'}}" class="text-center" alt="">
					</div>
					<div class="post" *ngIf="post && post[0]">
						<img src="{{global.Api_Imagem + 'blog/' + post[0]['arquivo']}}"
							 alt="{{post[0]['titulo']}}">
						<span>{{post[0]['data']}}</span>
						<h4>{{post[0]['titulo']}}</h4>
						<p>{{post[0]['conteudo']}}</p>

						<a href="{{post[0]['url']}}" target="_blank"
						   title="Veja o posto completo - {{post[0]['titulo']}}"
						   class="btn azul">
							Veja o post completo
						</a>
					</div>
				</div>
			</div>
		</aside>
	</section>

</main>

<app-footer></app-footer>

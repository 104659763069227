import {Component} from '@angular/core';
import {RequestService} from "../../shared/services/request.service";
import {Globals} from "../../app.global";

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

	public exibirAcao: boolean = false;
	public ExibirFormularioEnviando: boolean = false;
	public ExibirFormulario: boolean = true;
	public ExibirFormularioEnviado: boolean = false;
	public mensagem: any = '';
	public reacao: any = 3;

	constructor(public request: RequestService, public global: Globals) {

	}

	abrirAcao() {
		this.ExibirFormularioEnviando = false;
		this.ExibirFormulario = true;
		this.ExibirFormularioEnviado = false;
		if (this.exibirAcao == true) {
			this.exibirAcao = false;
		} else {
			this.exibirAcao = true;
		}
	}

	setReacao(numero) {
		this.reacao = numero;
	}

	onSubmit() {
		this.ExibirFormularioEnviando = true;
		this.ExibirFormulario = false;
		this.ExibirFormularioEnviado = false;
		this.request.get('/Estatistica/avaliacao?mensagem=' + this.mensagem + '&reacao=' + this.reacao)
			.then(res => {
					this.ExibirFormularioEnviando = false;
					this.ExibirFormulario = false;
					this.ExibirFormularioEnviado = true;

					this.mensagem = '';
					this.reacao = 0;
				},
				err => err);


	}

}

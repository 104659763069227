
import {fromEvent as observableFromEvent, Observable} from 'rxjs';

import {distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import {Component, ElementRef, ViewChild, OnInit, Input, OnChanges, SimpleChanges, AfterViewInit} from '@angular/core';
import {RequestService} from "../../shared/services/request.service";
import {Globals} from "../../app.global";
import {Router} from "@angular/router";
import 'rxjs/Rx';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
	selector: 'app-busca-inicial',
	templateUrl: './buscaInicial.component.html',
	styleUrls: ['./buscaInicial.component.scss']
})
export class BuscaInicialComponent implements AfterViewInit  {
	public buscaLocalizacao: boolean = true;
	public buscaCodigo: boolean = false;
	public _sugestoesLocalizacaoCidades: object;
	public _sugestoesLocalizacaoBairros: object;
	public _operacaoOpcoes: object;
	//private _operacao: string = 'Venda';
	public codigo: string = '';
	public operacao: string = 'Venda';
	public _tipoOpcoes: object;
	//private _tipo: string = null;
	public tipo: string = 'Apartamento';
	private _localizacao: string = '';
	public exbir_localizacao: boolean = false;

	@ViewChild('localizacaoRef') localizacaoRef: ElementRef;

	@Input()
	set localizacao(localizacao: string) {
		this._localizacao = localizacao;
	}

	constructor(public router: Router, public request: RequestService, public global: Globals, public deviceService: DeviceDetectorService) {
		this.request.get('/Buscas/operacao')
			.then(res => {
					if (res.status == 1) {
						this._operacaoOpcoes = res.result;
					} else {
						this._operacaoOpcoes[0] = 'Erro ao carregar';
					}
				},
				err => err);

		this.onChangeOperacao();
	}

	buscar(tipo){
		if(tipo == 'localizacao'){
			window.location.href= this.global.Portal_Url + '/imoveis/' + this.operacao + '?pagina=1&tipo=' + this.tipo + '&localizacao=' + this._localizacao;
		}else{
			window.location.href= this.global.Portal_Url + '/imoveis?codigo=' + this.codigo;
		}
	}
/*
	onSubmit(e) {
		if(e == 'codigo'){
			this.router.navigate(['imoveis/' + '?codigo=' + this.codigo]);
		}else {
			this.router.navigate(['imoveis/' + this.operacao + '?tipo=' + this.tipo]);
		}
		return false;
		//e.target.submit();
	}
*/
	ngAfterViewInit () {
		observableFromEvent(this.localizacaoRef.nativeElement, 'keyup').pipe(
			map((evt: any) => evt.target.value),
			debounceTime(200),
			distinctUntilChanged(),)
			.subscribe((text: string) => {
				this._localizacao = text;
				this.defineLocalizacao();
				this.exbir_localizacao = true;
			});
	}
	focus(){
		if(this.deviceService.isMobile()) {
			window.scroll(0, 130);
		}
		this.defineLocalizacao();
		if(this._localizacao.length >= 2 && (this._sugestoesLocalizacaoCidades || this._sugestoesLocalizacaoBairros)){
			this.exbir_localizacao = true;
		}else{
			this.exbir_localizacao = false;
		}
	}
	timeout(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	focusOut() {
		let timestampDiffs = [1];
		(async () => {
			for (let item of timestampDiffs) {
				await this.timeout(item * 1000);
				this.exbir_localizacao = false;
			}
		})();
	}

	onChangeOperacao() {
		this.request.get('/Buscas/Tipo?operacao=' + this.operacao)
			.then(res => {
					if (res.status == 1) {
						this._tipoOpcoes = res.result;
					} else {
						this._tipoOpcoes[0] = 'Erro ao carregar';
					}
				},
				err => err);
		this.defineLocalizacao();
	}

	onChangeTipo() {
		this.defineLocalizacao();
	}

	defineLocalizacao(){
		this.request.get('/Buscas/localizacao?operacao=' + this.operacao + '&tipo=' + this.tipo + '&localizacao=' + this._localizacao)
			.then(res => {
					this._sugestoesLocalizacaoCidades = res.result.cidades;
					this._sugestoesLocalizacaoBairros = res.result.bairros;
				},
				err => err);
	}

	buscarLocalizacao(){
		this.buscaCodigo = false;
		this.buscaLocalizacao = true;
	}
	buscarCodigo(){
		this.buscaCodigo = true;
		this.buscaLocalizacao = false;
	}
}

<section>
	<div class="destaque-imobiliarias">
		<div class="titulo">
			<div class="card ">
				<div class="header">
					<h2>Imobiliárias em destaque</h2>
					<p>Conheça algumas imobiliárias que participam do portal</p>
				</div>
			</div>
		</div>
		<ul>
			<li *ngFor="let imob of imobiliarias">
				<div class="card">
					<div class="content">
						<a href="/anunciante/{{imob.slug}}" title="{{imob.nome}}" title="Veja os imóveis da {{imob.nome}} de {{imob.cidade}} {{imob.estado}}">
							<img src="{{global.Api_Imagem + 'logo/' + imob.id}}.jpg" alt="{{imob.nome}}">
							<div>
								<h4>{{imob.nome}}</h4>
								<h6>CRECI: {{imob.creci}}</h6>
							</div>
						</a>
					</div>
					<div class="footer">
						<a href="/anunciante/{{imob.slug}}"
						   title="Veja os imóveis da {{imob.nome}} de {{imob.cidade}} {{imob.estado}}"
						   class="btn azul">
							Ver Imóveis
						</a>
					</div>
				</div>
			</li>
		</ul>
	</div>
</section>

import {Component} from '@angular/core';
import {Globals} from "../../app.global";
import {RequestService} from "../../shared/services/request.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
	selector: 'app-destaque-imobiliarias',
	templateUrl: './destaque-imobiliarias.component.html',
	styleUrls: ['./destaque-imobiliarias.component.scss']
})
export class DestaqueImobiliariasComponent {
	imobiliarias: string;
	constructor(public global: Globals, public request: RequestService, public deviceService: DeviceDetectorService) {
		//CARREGAS OS PATROCINADOS
		this.request.get('/Contas/?patrocinados=true')
			.then(res => {
					this.imobiliarias = res.result;
				},
				err => err);
	}
}

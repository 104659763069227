import {Injectable} from '@angular/core';

@Injectable()
export class Globals {
    Titulo_Portal: string = 'Portal Imobiliário Tráfego Imóveis';
    Portal_Url: string = 'https://trafegoimoveis.com.br';
    Api_Url: string = 'https://api.trafegointegrada.com.br';
    /*Api_Url: string = 'http://localhost/api.trafegointegrada.com.br/index.php';*/
    Api_Chave: string = '5d507b39713e574c643d3e2f786e5920266d304c733f6b716d472f2541';
    //Api_Imagem: string = 'https://api.trafegointegrada.com.br/Imagens';
    Api_Imagem: string = 'https://simob.trafegoimoveis.com.br/public/';
}

<app-header></app-header>
<main>
  <section *ngIf="!imovel && !erro">
    <div class="loading-conteudo">
      <img
        src="{{ global.Api_Imagem + 'portal/loading.gif' }}"
        class="text-center"
        alt="Carregando informações"
      />
    </div>
  </section>

  <section class="header" *ngIf="imovel && !erro">
    <h1 *ngIf="imovel">
      {{ imovel.titulo }}
      <small>Código {{ imovel.codigo }}</small>
    </h1>
    <nav class="compartilhar" *ngIf="imovel && !erro">
      <button class="btn laranja" (click)="abrirCompatilhe(i)">
        <i class="fas fa-share-alt"></i>
      </button>
      <ul *ngIf="opcao_compartilhar[i]">
        <li>
          <a
            title="Compartilhar {{ imovel.titulo }} no Facebook"
            href="https://www.facebook.com/sharer/sharer.php?u={{
              this.global.Portal_Url
            }}/imovel/{{ imovel.id }}/{{ imovel.url }}"
            target="_blank"
          >
            <i class="fab fa-facebook-f"></i>
            <span>Facebook</span>
          </a>
        </li>
        <li>
          <a
            title="Compartilhar {{ imovel.titulo }} no Twitter"
            href="https://twitter.com/home?status={{
              this.global.Portal_Url
            }}/imovel/{{ imovel.id }}/{{ imovel.url }}"
            target="_blank"
          >
            <i class="fab fa-twitter"></i>
            <span>Twitter</span>
          </a>
        </li>
        <li>
          <a
            title="Compartilhar {{ imovel.titulo }} no Whatsapp"
            [href]="
              sanitize(
                'whatsapp://send?text=' +
                  this.global.Portal_Url +
                  '/imovel/' +
                  imovel.id +
                  '/' +
                  imovel.url
              )
            "
            target="_blank"
          >
            <i class="fab fa-whatsapp"></i>
            <span>Whatsapp</span>
          </a>
        </li>
      </ul>
    </nav>
  </section>

  <section class="informacoes" *ngIf="imovel && !erro">
    <div class="anunciante">
      <app-dados-anunciante
        [slug]="imovel.imobiliaria.slug"
        [local]="dadosAnuncianteTipo"
      ></app-dados-anunciante>
    </div>
    <div class="imovel">
      <div class="detalhes">
        <div class="bloco1">
          <div class="imagens">
            <ui-carousel
              *ngIf="imovel.imagem && imovel.imagem[0]"
              #uiCarousel
              [infinite]="false"
              [fade]="false"
              [arrows]="true"
              [autoPlay]="false"
              [speed]="200"
              [autoPlaySpeed]="7000"
              [dots]="false"
            >
              <ui-carousel-item *ngFor="let imagem of imovel.imagem">
                <img
                  [src]="
                    this.global.Api_Imagem +
                    'imoveis/' +
                    imovel.imobiliaria.id +
                    '/' +
                    imagem.name
                  "
                />
              </ui-carousel-item>
            </ui-carousel>
            <img
              *ngIf="imovel.imagem && !imovel.imagem[0]"
              [src]="
                this.global.Api_Imagem +
                'imoveis/' +
                imovel.imobiliaria.id +
                '/' +
                imovel.imagem[0].name
              "
            />
            <img
              *ngIf="!imovel.imagem"
              [src]="this.global.Api_Imagem + 'imoveis/imovel-sem-foto.jpg'"
            />
          </div>
          <div class="formulario">
            <div class="card">
              <div class="valores">
                <div *ngIf="imovel.valores">
                  <h4>
                    <small *ngIf="imovel.operacao == 'Alugar'"
                      >Valor do aluguel</small
                    >
                    <small *ngIf="imovel.operacao == 'Comprar'"
                      >Valor de venda</small
                    >
                    <br />
                    {{ imovel.valores.valor }}
                  </h4>
                </div>
                <div
                  class="outros"
                  *ngIf="
                    imovel.valores.valor_entrada ||
                    imovel.valores.valor_condominio ||
                    imovel.valores.valor_m2
                  "
                >
                  <div class="item" *ngIf="imovel.valores.valor_entrada">
                    <span></span>
                    <i class="fas fa-arrow-right"></i>
                    <div>
                      <small>Valor da entrada</small>
                      <h6>{{ imovel.valores.valor_entrada }}</h6>
                    </div>
                  </div>
                  <div class="item" *ngIf="imovel.valores.valor_condominio">
                    <span></span>
                    <i class="fas fa-arrow-right"></i>
                    <div>
                      <small>Valor do condomínio</small>
                      <h6>{{ imovel.valores.valor_condominio }}</h6>
                    </div>
                  </div>
                  <div class="item" *ngIf="imovel.valores.valor_m2">
                    <span></span>
                    <i class="fas fa-arrow-right"></i>
                    <div>
                      <small>Valor do m²</small>
                      <h6>{{ imovel.valores.valor_m2 }}</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form">
                <form
                  *ngIf="ExibirFormulario"
                  (ngSubmit)="onSubmit()"
                  #testForm="ngForm"
                  ngNativeValidate
                >
                  <h4>
                    <i class="fas fa-envelope-open-text"></i>
                    Enviar mensagem!
                  </h4>

                  <label for="cmp-anuncie-nome">Seu nome</label>
                  <div class="campo">
                    <input
                      type="text"
                      id="cmp-anuncie-nome"
                      class="form-control"
                      placeholder="Seu nome"
                      required
                      [(ngModel)]="form.nome"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>

                  <label for="cmp-anuncie-telefone">Seu telefone</label>
                  <div class="campo">
                    <input
                      type="text"
                      placeholder="Telefone"
                      [brmasker]="{ phone: true }"
                      #telefoneRef
                      id="cmp-anuncie-telefone"
                      required
                      #telefoneref="ngModel"
                      [(ngModel)]="_telefone"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                  <div
                    *ngIf="
                      telefoneref.errors &&
                      (telefoneref.touched || telefoneref.dirty)
                    "
                  >
                    <p [hidden]="!telefoneref.errors?.pattern">
                      Informe um telefone válido
                    </p>
                  </div>

                  <label for="cmp-anuncie-email">Seu e-mail</label>
                  <div class="campo">
                    <input
                      type="text"
                      placeholder="E-mail"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                      required
                      id="cmp-anuncie-email"
                      name="email"
                      [(ngModel)]="form.email"
                      [ngModelOptions]="{ standalone: true }"
                      #emailref="ngModel"
                    />
                  </div>
                  <div
                    *ngIf="
                      emailref.errors && (emailref.touched || emailref.dirty)
                    "
                  >
                    <p [hidden]="!emailref.errors?.pattern">
                      Informe um e-mail válido
                    </p>
                  </div>

                  <label for="cmp-anuncie-telefone">Sua mensagem</label>
                  <div class="campo">
                    <textarea
                      required
                      [(ngModel)]="form.mensagem"
                      [ngModelOptions]="{ standalone: true }"
                      id="cmp-opniao-mensagem"
                      name="mensagem"
                      placeholder="Deixe aqui sua mensagem, é muito importante para nós"
                      rows="4"
                    ></textarea>
                  </div>

                  <div class="aviso" *ngIf="aviso">
                    <strong>Preencha os campos!</strong> Informe seu
                    <strong>NOME</strong> e informe tambem um
                    <strong>TELEFONE</strong> ou <strong>E-MAIL</strong> para
                    contato
                  </div>

                  <a
                    class="flutuante-whatsapp"
                    (click)="
                      contatoWhatsapp(imovel.id, imovel.imobiliaria.slug)
                    "
                    href="https://api.whatsapp.com/send?phone={{
                      imovel.whatsapp
                    }}&text={{ form.mensagem }} {{ currentRoute }}"
                    target="_blank"
                    *ngIf="imovel.whatsapp"
                  >
                    <span class="conteudo">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 32 32"
                        class="wh-messenger-svg-whatsapp wh-svg-icon"
                      >
                        <path
                          d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                      <p class="texto">
                        Falar com <strong>{{ imovel.imobiliaria.nome }}</strong>
                      </p>
                    </span>
                  </a>

                  <button
                    type="submit"
                    [disabled]="!testForm.form.valid"
                    class="btn laranja"
                  >
                    <i class="fab fa-telegram-plane"></i> Enviar formulário
                  </button>
                </form>
                <div
                  *ngIf="!ExibirFormulario"
                  class="loading-conteudo text-center"
                >
                  <img
                    *ngIf="ExibirFormularioEnviando"
                    src="{{ global.Api_Imagem + 'portal/loading.gif' }}"
                    class="text-center"
                    alt=""
                  />
                  <br />
                  <h3 *ngIf="ExibirFormularioEnviando">
                    Estamos enviando seu interesse para
                    <strong>{{ imovel.imobiliaria.nome }}</strong> nesse
                    momento!
                  </h3>
                  <h3 *ngIf="!ExibirFormularioEnviando" class="teal p-2">
                    <i class="ft-thumbs-up font-large-3 teal"></i>
                    <br />
                    Seus dados foram enviados com sucesso!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul
          class="bloco2"
          *ngIf="
            imovel.operacao == 'Comprar' &&
            (imovel.aceita_financiamento ||
              imovel.aceita_fgts ||
              imovel.aceita_permuta)
          "
        >
          <li class="item" *ngIf="imovel.aceita_financiamento">
            <i class="far fa-calendar-alt"></i>
            <h3>
              Aceita financiamento
              <br />
              <small>Este imóvel aceita financiamento</small>
            </h3>
          </li>
          <li class="item" *ngIf="imovel.aceita_fgts">
            <i class="fas fa-hand-holding-usd"></i>
            <h3>
              Aceita FGTS
              <br />
              <small>Este imóvel aceita entrada com FGTS</small>
            </h3>
          </li>
          <li class="item" *ngIf="imovel.aceita_permuta">
            <i class="fas fa-exchange-alt"></i>
            <h3>
              Aceita troca
              <br />
              <small>Este imóvel aceita permuta</small>
            </h3>
          </li>
        </ul>
        <div class="bloco3">
          <div class="card">
            <ul>
              <li
                *ngIf="
                  imovel.caracteristicas &&
                  ((imovel.caracteristicas.quarto &&
                    imovel.caracteristicas.quarto > 0) ||
                    (imovel.caracteristicas.banheiro &&
                      imovel.caracteristicas.banheiro > 0) ||
                    (imovel.caracteristicas.vaga &&
                      imovel.caracteristicas.vaga > 0) ||
                    (imovel.caracteristicas.area_util &&
                      imovel.caracteristicas.area_total > 0) ||
                    (imovel.caracteristicas.area_util &&
                      imovel.caracteristicas.area_util > 0))
                "
                class="caracteristicas"
              >
                <h3>Característica do imóvel</h3>
                <ul>
                  <li
                    *ngIf="
                      imovel.caracteristicas.quarto &&
                      imovel.caracteristicas.quarto > 0
                    "
                  >
                    <img
                      src="{{ global.Api_Imagem + 'portal/ico-quarto.png' }}"
                      width="20"
                      alt="Ícone de ilustração para os quartos do imóvel"
                    />
                    {{ imovel.caracteristicas.quarto }} Quarto(s)
                  </li>
                  <li
                    *ngIf="
                      imovel.caracteristicas.banheiro &&
                      imovel.caracteristicas.banheiro > 0
                    "
                  >
                    <img
                      src="{{ global.Api_Imagem + 'portal/ico-banheiro.png' }}"
                      width="20"
                      alt="Ícone de ilustração para os banheiros do imóvel"
                    />
                    {{ imovel.caracteristicas.banheiro }} Banheiro(s)
                  </li>
                  <li
                    *ngIf="
                      imovel.caracteristicas.vaga &&
                      imovel.caracteristicas.vaga > 0
                    "
                  >
                    <img
                      src="{{ global.Api_Imagem + 'portal/ico-garagem.png' }}"
                      width="20"
                      alt="Ícone de ilustração para as vagas de garagem do imóvel"
                    />
                    {{ imovel.caracteristicas.vaga }} Vaga(s)
                  </li>
                  <li
                    *ngIf="
                      imovel.caracteristicas.area_util &&
                      imovel.caracteristicas.area_total > 0
                    "
                  >
                    <img
                      src="{{ global.Api_Imagem + 'portal/ico-area.png' }}"
                      width="20"
                      alt="Ícone de ilustração para as área total do imóvel"
                    />
                    {{ imovel.caracteristicas.area_total }}m² Área total
                  </li>
                  <li
                    *ngIf="
                      imovel.caracteristicas.area_util &&
                      imovel.caracteristicas.area_util > 0
                    "
                  >
                    <img
                      src="{{ global.Api_Imagem + 'portal/ico-area.png' }}"
                      width="20"
                      alt="Ícone de ilustração para as área útil do imóvel"
                    />
                    {{ imovel.caracteristicas.area_util }}m² Área útil
                  </li>
                </ul>
              </li>
              <li *ngIf="imovel.descricao">
                <h3>Descrição do imóvel</h3>
                <p>{{ imovel.descricao }}</p>
              </li>
              <li *ngIf="imovel_video">
                <h3>Vídeo do imóvel</h3>
                <p [innerHtml]="imovel_video"></p>
              </li>
              <li *ngIf="imovel.maps && !mobile">
                <h3>Localização aproximada do imóvel</h3>
                <div id="map" class="google-map"></div>
              </li>
              <li *ngIf="link_map && mobile">
                <a
                  [href]="link_map"
                  target="_blank"
                  class="btn azul"
                  title="Ver a localização aproximada do {{ imovel.titulo }}"
                  >Ver a localização aproximada</a
                >
              </li>
              <li *ngIf="imovel.planta">
                <h3>Planta do imóvel</h3>
                <img
                  *ngFor="let planta of imovel.planta; let i = index"
                  src="{{
                    this.global.Api_Imagem +
                      'imovel/' +
                      imovel.imobiliaria.id +
                      '/' +
                      planta.name
                  }}"
                  alt=""
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="sugestoes" *ngIf="imovel && imovel.sugestoes && !erro">
    <h4>Imóveis semelhantes</h4>
    <p>Encontramos outros imóveis que podem lhe interessar.</p>
    <ul *ngIf="imovel && imovel.sugestoes">
      <li *ngFor="let sugestao of imovel.sugestoes; let i = index">
        <div class="card">
          <div class="imagens">
            <ui-carousel
              #uiCarousel
              [infinite]="false"
              [autoPlay]="false"
              [arrows]="true"
              [fade]="false"
              [speed]="200"
              width="100%"
              [dots]="false"
            >
              <ui-carousel-item *ngFor="let img_sugestao of sugestao.imagem">
                <a
                  href="/imovel/{{ sugestao.id }}/{{ sugestao.url }}"
                  title="Mais informações {{ sugestao.titulo }}"
                >
                  <img
                    *ngIf="img_sugestao"
                    [ui-lazy-load]="
                      this.global.Api_Imagem +
                      'imoveis/' +
                      sugestao.imobiliaria.id +
                      '/' +
                      img_sugestao.name
                    "
                  />
                </a>
              </ui-carousel-item>
            </ui-carousel>
          </div>
          <div class="detalhes">
            <a
              href="/imovel/{{ sugestao.id }}/{{ sugestao.url }}"
              title="Mais informações {{ sugestao.titulo }}"
            >
              <h2>
                {{ sugestao.titulo }}
                <br />
                <small>{{ sugestao.subtitulo }}</small>
              </h2>
            </a>
          </div>
          <div class="footer">
            <a
              href="/imovel/{{ sugestao.id }}/{{ sugestao.url }}"
              title="Mais informações {{ imovel.titulo }}"
              class="btn destaque"
            >
              <i class="fas fa-plus"></i> Informações
            </a>
          </div>
        </div>
      </li>
    </ul>
  </section>

  <section class="outros" *ngIf="imovel && imovel.outros_bairro && !erro">
    <h4>
      Outros imóveis no bairro
      <strong
        >{{ imovel.endereco.bairro }} em {{ imovel.endereco.cidade }}</strong
      >
    </h4>
    <div>
      <a
        *ngFor="let outros_bairro of imovel.outros_bairro; let i = index"
        href="/imovel/{{ outros_bairro.id }}/{{ outros_bairro.url }}"
        title="Mais informações {{ outros_bairro.titulo }}"
        >{{ outros_bairro.titulo }}</a
      >
    </div>
  </section>

  <section class="outros tipo" *ngIf="imovel && imovel.outros_tipo && !erro">
    <h4>
      Outros tipo de imóvel em <strong>{{ imovel.endereco.cidade }}</strong>
    </h4>
    <div>
      <a
        *ngFor="let outros_tipo of imovel.outros_tipo; let i = index"
        href="/imovel/{{ outros_tipo.id }}/{{ outros_tipo.url }}"
        title="Mais informações {{ outros_tipo.titulo }}"
      >
        {{ outros_tipo.titulo }}, {{ outros_tipo.subtitulo }}
      </a>
    </div>
  </section>

  <section class="aviso" *ngIf="imovel && !erro">
    <div class="card">
      <p>
        IMPORTANTE: As informações exibidas no anúncio
        <i
          ><strong>{{ imovel.titulo }}, código {{ imovel.codigo }}</strong></i
        >, são fornecidas, mantidas e de total responsabilidade de
        <i
          ><strong
            >{{ imovel.imobiliaria.nome }}, CRECI
            {{ imovel.imobiliaria.creci }}</strong
          ></i
        >. O
        <i
          ><strong>{{ this.global.Titulo_Portal }}</strong></i
        >
        não possui nenhum controle sobre o conteúdo do anúncio. Por esse motivo,
        todos os preços e informações deverão ser confirmados diretamente com
        <i
          ><strong
            >{{ imovel.imobiliaria.nome }}, CRECI
            {{ imovel.imobiliaria.creci }}</strong
          ></i
        >.
      </p>

      <button (click)="relatarProblema(imovel.id)" class="btn alerta">
        INFORMAR UM PROBLEMA COM O ANÚNCIO
      </button>

      <modal-problema [imovel_id]="imovel_id" [modal]="modal"></modal-problema>
    </div>
  </section>

  <section class="erro" *ngIf="erro">
    <app-chamada-erro></app-chamada-erro>
    <a href="" class="btn azul">Buscar Imóveis</a>
  </section>
</main>
<app-footer></app-footer>

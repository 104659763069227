import { fromEvent as observableFromEvent } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { AfterViewInit, ElementRef, } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { Globals } from "../../app.global";
import { RequestService } from "../../shared/services/request.service";
var AnunciarComponent = /** @class */ (function () {
    function AnunciarComponent(title, meta, global, request) {
        this.title = title;
        this.meta = meta;
        this.global = global;
        this.request = request;
        this.form = [];
        this.ExibirFormularioEnviando = false;
        this.ExibirFormulario = true;
        this.ExibirFormularioEnviado = false;
        this.Proprietario = false;
        this.telefone = "";
        title.setTitle("Corretores e Imobiliárias" + " - " + this.global.Titulo_Portal);
        meta.addTag({
            name: "description",
            content: "Anuncie no " +
                this.global.Titulo_Portal +
                " e ganhe um site personalizado que possui: Consultoria na compra do domínio(endereço eletrônico); Hospedagem; Pacote com 5 contas de e-mails (com limite de 5GB para cada conta); Manutenção do site; ",
        });
        meta.addTag({
            name: "keywords",
            content: "anunciar imóvel, como anunciar meu imóvel, anunciar casa, anunciar apartamento, como anunciar meu apartamento, vender imóvel, vender apartamento, alugar imovel, alugar casa, vender casa, alugar apartamento, anunciar imóvel direto proprietário",
        });
        meta.addTag({
            name: "author",
            content: "Tráfego Integrada",
        });
    }
    Object.defineProperty(AnunciarComponent.prototype, "telefone", {
        set: function (telefone) {
            this.form._telefone = telefone;
        },
        enumerable: true,
        configurable: true
    });
    AnunciarComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.form.nome = "";
        this.form._telefone = "";
        this.form.email = "";
        this.form.cidade = "";
        this.form.tipo = "";
        this.form.conheceu = "";
        //CARREGAS OS NUMEROS
        this.request.get("/Numeros/portal").then(function (res) {
            _this.numeros = res.result;
        }, function (err) { return err; });
        observableFromEvent(this.telefoneRef.nativeElement, "keyup")
            .pipe(map(function (evt) { return evt.target.value; }), distinctUntilChanged())
            .subscribe(function (text) {
            console.log("teste");
            _this.form._telefone = text;
        });
    };
    AnunciarComponent.prototype.onOption = function (value) {
        this.Proprietario = value;
    };
    AnunciarComponent.prototype.onSubmit = function () {
        var _this = this;
        this.ExibirFormularioEnviando = true;
        this.ExibirFormulario = false;
        this.ExibirFormularioEnviado = false;
        this.request
            .get("/Envio/enviosPortal?referencia=anunciar&assunto=Anunciar&nome=" +
            this.form.nome +
            "&email=" +
            this.form.email +
            "&telefone=" +
            this.form._telefone +
            "&cidade=" +
            this.form.cidade +
            "&tipo=" +
            this.form.tipo +
            "&conheceu=" +
            this.form.conheceu)
            .then(function (res) {
            _this.ExibirFormularioEnviando = false;
            _this.ExibirFormulario = false;
            _this.ExibirFormularioEnviado = true;
            _this.form.nome = "";
            _this.form._telefone = "";
            _this.form.email = "";
            _this.form.cidade = "";
            _this.form.tipo = "";
            _this.form.conheceu = "";
        }, function (err) { return err; });
    };
    return AnunciarComponent;
}());
export { AnunciarComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chamada-erro.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./chamada-erro.component";
import * as i3 from "../../app.global";
var styles_ChamadaErroComponent = [i0.styles];
var RenderType_ChamadaErroComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChamadaErroComponent, data: {} });
export { RenderType_ChamadaErroComponent as RenderType_ChamadaErroComponent };
export function View_ChamadaErroComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "erro"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "far fa-sad-tear"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Um erro ocorreu a carregar as informa\u00E7\u00F5es!"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pedimos desculpas pelo ocorrido, ja estamos tomando as devidas provid\u00EAncias. "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tente novamente mais tarde."]))], null, null); }
export function View_ChamadaErroComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chamada-erro", [], null, null, null, View_ChamadaErroComponent_0, RenderType_ChamadaErroComponent)), i1.ɵdid(1, 49152, null, 0, i2.ChamadaErroComponent, [i3.Globals], null, null)], null, null); }
var ChamadaErroComponentNgFactory = i1.ɵccf("app-chamada-erro", i2.ChamadaErroComponent, View_ChamadaErroComponent_Host_0, {}, {}, []);
export { ChamadaErroComponentNgFactory as ChamadaErroComponentNgFactory };

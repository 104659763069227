/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chamada-anuncie.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./chamada-anuncie.component";
import * as i3 from "../../app.global";
var styles_ChamadaAnuncieComponent = [i0.styles];
var RenderType_ChamadaAnuncieComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChamadaAnuncieComponent, data: {} });
export { RenderType_ChamadaAnuncieComponent as RenderType_ChamadaAnuncieComponent };
export function View_ChamadaAnuncieComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "chamada"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "anuncie"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Quer anunciar seus im\u00F3veis?"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vamos juntos, certeza de excelente neg\u00F3cio!"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["class", "btn azul"], ["href", "/anunciar-imoveis"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Clique aqui para anunciar "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "Quero an\u00FAnciar no ", _co.global.Titulo_Portal, ""); _ck(_v, 10, 0, currVal_0); }); }
export function View_ChamadaAnuncieComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chamada-anuncie", [], null, null, null, View_ChamadaAnuncieComponent_0, RenderType_ChamadaAnuncieComponent)), i1.ɵdid(1, 49152, null, 0, i2.ChamadaAnuncieComponent, [i3.Globals], null, null)], null, null); }
var ChamadaAnuncieComponentNgFactory = i1.ɵccf("app-chamada-anuncie", i2.ChamadaAnuncieComponent, View_ChamadaAnuncieComponent_Host_0, {}, {}, []);
export { ChamadaAnuncieComponentNgFactory as ChamadaAnuncieComponentNgFactory };

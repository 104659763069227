<div class="numeros" [ngClass]="tipo">
	<div class="card">
		<div class="header laranja">
			<h2>Nossos números</h2>
		</div>
		<div class="content">
			<div *ngIf="!numeros" class="loading-conteudo">
				<img src="{{global.Api_Imagem + 'portal/loading.gif'}}" class="text-center" alt="">
			</div>
			<ul *ngIf="numeros">
				<li>
					<i class="fas fa-gift"></i>
					<div>
						<h3>+{{numeros[0].idade}}</h3>
						<span>Anos</span>
					</div>
				</li>
				<li>
					<i class="fas fa-users"></i>
					<div>
						<h3>+{{numeros[0].clientes}}</h3>
						<span>Clientes</span>
					</div>
				</li>
				<li>
					<i class="fas fa-home"></i>
					<div>
						<h3>+{{numeros[0].imoveis}}</h3>
						<span>Imóveis</span>
					</div>
				</li>
				<li>
					<i class="fas fa-map"></i>
					<div>
						<h3>+{{numeros[0].cidades}}</h3>
						<span>Cidades</span>
					</div>
				</li>
			</ul>
		</div>
		<div class="footer">
			<h4>Quer anunciar seus imóveis?</h4>
			<a href="/anunciar-imoveis" title="Conheça nossos planos de anúncio"
			   class="btn destaque"
			   style="margin-bottom: 0px !important;">
				Clique aqui!
			</a>
		</div>
	</div>
</div>

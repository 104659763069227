import { isPlatformBrowser, isPlatformServer } from '@angular/common';
var PlatformService = /** @class */ (function () {
    function PlatformService(platformId) {
        this.platformId = platformId;
    }
    Object.defineProperty(PlatformService.prototype, "isBrowser", {
        get: function () {
            return isPlatformBrowser(this.platformId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformService.prototype, "isServer", {
        get: function () {
            return isPlatformServer(this.platformId);
        },
        enumerable: true,
        configurable: true
    });
    return PlatformService;
}());
export { PlatformService };

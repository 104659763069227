import {Component} from '@angular/core';
import {Globals} from "../../app.global";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

	public exibir_menu: boolean = true;

	constructor(public global: Globals, public deviceService: DeviceDetectorService) {
		if (this.deviceService.isMobile()) {
			this.exibir_menu = false;
		}
	}
	abrirMenu(){
		if(this.exibir_menu == false){
			this.exibir_menu = true;
		}else{
			this.exibir_menu = false;
		}
	}
}

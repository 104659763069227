<section>
	<div class="chamada">
		<div class="anuncie">
			<div class="card">
				<div class="content">
					<div>
						<h2>Quer anunciar seus imóveis?</h2>
						<p>Vamos juntos, certeza de excelente negócio!</p>
					</div>
					<a href="/anunciar-imoveis" class="btn azul" title="Quero anúnciar no {{global.Titulo_Portal}}">
						Clique aqui para anunciar
					</a>
				</div>
			</div>
		</div>
	</div>
</section>

import {fromEvent as observableFromEvent, Observable} from 'rxjs';

import {distinctUntilChanged, map} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title, Meta} from '@angular/platform-browser';
import {Globals} from '../../app.global';
import {RequestService} from '../../shared/services/request.service';
import {EmbedVideoService} from 'ngx-embed-video';
import {DomSanitizer} from '@angular/platform-browser';
import {DeviceDetectorService} from 'ngx-device-detector';

declare const google: any;

@Component({
    templateUrl: './imovel.component.html',
    styleUrls: ['./imovel.component.scss']
})
export class ImovelComponent implements AfterViewInit {
    id: number;
    slug: any;
    dadosAnuncianteTipo: 'imovel';
    imobiliaria: any;
    imovel: any;
    imovel_id: any = false;
    currentRoute: any;
    _telefone: any = '';
    imovel_video: any = false;
    mobile: boolean = false;
    erro: boolean = false;
    opcao_compartilhar: object = [];

    @Input() ExibirDados: boolean = false;
    aviso: any;
    ExibirFormulario: boolean = true;
    ExibirFormularioEnviando: boolean = true;
    public link_map: any = '';
    public lat: number = 0;
    public lon: number = 0;
    form: any = [];

    @ViewChild('telefoneRef') telefoneRef: ElementRef;

    @Input()
    set telefone(telefone: string) {
        this._telefone = telefone;
    }

    public modal: any = 1;

    constructor(private route: ActivatedRoute, private title: Title, private meta: Meta, public global: Globals,
                public request: RequestService, private embedService: EmbedVideoService, private sanitizer: DomSanitizer,
                public deviceService: DeviceDetectorService) {
        this.dadosAnuncianteTipo = 'imovel';

        if (this.deviceService.isMobile()) {
            this.mobile = true;
        }

    }

    abrirCompatilhe(i) {
        if (this.opcao_compartilhar[i]) {
            this.opcao_compartilhar[i] = false;
        } else {
            this.opcao_compartilhar[i] = true;
        }
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    ngAfterViewInit() {
        setTimeout(() => {

            observableFromEvent(this.telefoneRef.nativeElement, 'keyup').pipe(
                map((evt: any) => evt.target.value),
                distinctUntilChanged(),)
                .subscribe((text: string) => {
                    this._telefone = text;
                });

            this.loadMapa();

        }, 1500);


        this.loadImovel();

    }

    /*
        load() {
            //VERIFICA OS PARAMETROS DA URL
            this.route.params.subscribe(params => {
                if (params['id']) {
                    this.id = params['id'];
                }
            });

            //CARREGA O IMOVEL
            if (this.id) {
                this.loadImovel();
                this.currentRoute =  window.location.href;
            }else{
                this.erro = true;
            }
        }
    */

    loadImovel() {

        /*VERIFICA OS PARAMETROS DA URL*/
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.id = params['id'];
                this.request.get('/imoveis/detalhe?id=' + this.id)
                    .then(res => {
                            if (res.status == 1) {
                                this.imovel = res.result;

                                this.request.estatistica('imovel', 'acesso', this.imovel.imobiliaria.slug, this.imovel.id);
                                if (this.imovel.video) {
                                    this.imovel_video = this.embedService.embed_youtube(this.imovel.video, {
                                        attr: {
                                            width: '100%',
                                            height: '450'
                                        }
                                    });
                                }

                                this.loadFormulario();
                                this.seo();
                            } else {
                                this.erro = true;
                            }
                        },
                        err => err);
                this.currentRoute = window.location.href;
            } else {
                this.erro = true;
            }
        });

    }

    loadFormulario() {
        this.aviso = false;
        this.form.aviseme = true;
        this.form.nome = '';
        this.form.telefone = '';
        this.form.email = '';
        this.form.mensagem = '';

        this.ExibirFormulario = true;
        this.ExibirFormularioEnviando = true;

        /*this.request.estatistica('conta', 'acesso', this.imovel.imobiliaria.slug);*/
        this.form.mensagem = 'Olá, tenho interesse no imóvel código ' + this.imovel.codigo + ', ' + this.imovel.titulo +
            ', encontrei ele no ' + this.global.Titulo_Portal + '. Aguardo o contato. Obrigado.';
        this.ExibirDados = true;
    }

    loadMapa() {
        if (this.imovel.maps) {
            let map;
            let circle;

            this.lat = this.imovel.maps.latitude + 0.0080031;
            this.lon = this.imovel.maps.longitude + 0.0060031;
            this.link_map = 'https://www.google.com/maps/place/@' + this.lat + ',' + this.lon;

            const marcar = {lat: this.lat, lng: this.lon};

            map = new google.maps.Map(document.getElementById('map'), {
                center: marcar,
                zoom: 14
            });

            circle = new google.maps.Circle({
                strokeColor: '#0a75ca',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#0092ff',
                fillOpacity: 0.35,
                map: map,
                center: new google.maps.LatLng(marcar),
                radius: 1000
            });
        }
    }

    onSubmit() {
        let aviseme = 0;
        let nome = 0;
        let email = 0;
        let telefone = 0;
        let mensagem = 0;
        let origem = 'portal';
        let url = '/imovel/' + this.imovel.id + '/' + this.imovel.url;
        let imagem = this.global.Api_Imagem + 'imoveis/imovel-sem-foto.jpg';
        if (this.imovel.imagem) {
            imagem = this.global.Api_Imagem + 'imoveis/' + this.imovel.imobiliaria.id + '/' + this.imovel.imagem[0].name;
        }
        if (this.form.aviseme) {
            aviseme = this.form.aviseme;
        }
        if (this.form.nome) {
            nome = this.form.nome;
        }
        if (this._telefone) {
            telefone = this._telefone;
        }
        if (this.form.email) {
            email = this.form.email;
        }
        if (this.form.mensagem) {
            mensagem = this.form.mensagem;
        }
        if (this.form.aviseme) {
            aviseme = this.form.aviseme;
        }

        this.ExibirFormulario = false;
        this.ExibirFormularioEnviando = true;

        this.request.get('/Envio/enviar?origem=' + origem + '&imagem=' + imagem + '&imovel=' + this.imovel.id + '&imobiliaria=' +
            this.imovel.imobiliaria.id + '&url=' + url + '&nome=' + nome + '&telefone=' + telefone + '&email=' + email + '&mensagem=' +
            mensagem + '&editar-aviseme=' + aviseme)
            .then(res => {
                    if (res && res.status) {
                        this.ExibirFormularioEnviando = false;
                    } else {
                        return false;
                    }
                },
                err => err);
    }

    relatarProblema(imovel_id) {
        this.modal = this.modal + 1;
        this.imovel_id = imovel_id;
    }

    contatoWhatsapp(imovel_id, slug) {
        console.log(imovel_id);
        console.log(slug);
        this.request.estatistica('whatsapp', 'acesso', slug, imovel_id);
    }

    seo() {
        /*CARREGA AS TAGS PARA A PAGINA*/
        this.title.setTitle(this.imovel.titulo + ', ' + this.imovel.imobiliaria['nome'] + ' - ' + this.global.Titulo_Portal);
        this.meta.addTag({
            name: 'description',
            content: this.imovel.titulo + ' da imobiliária ' + this.imovel.imobiliaria['nome'] + ', ' + this.imovel.description +
                ' - ' + this.global.Titulo_Portal
        });
        this.meta.addTag({
            name: 'keywords',
            content: this.imovel.keywords + ', ' + this.global.Titulo_Portal
        });
        this.meta.addTag({
            name: 'author',
            content: this.global.Titulo_Portal
        });
    }
}

<header>
	<div class="logo">
		<a href="/" title="Voltar para página inicial"></a>
	</div>
	<nav class="menu">
		<ul class="imoveis">
			<li>
				<a title="Imóveis para venda" href="/imoveis/Venda">
					Imóveis para venda
				</a>
			</li>
			<li>
				<a title="Imóveis para alugar" href="/imoveis/Aluguel">
					Imóveis para alugar
				</a>
			</li>
		</ul>
		<ul class="institucional" [ngStyle]="{'display':exibir_menu ? 'flex' : 'none' }">
			<li>
				<a title="Anunciar imóveis" href="/anunciar-imoveis">
					Anunciar Imóveis
				</a>
			</li>
			<li>
				<a title="Saiba mais sobre o Portal Imobiliário Tráfego Imóveis" href="/sobre-o-portal-imobiliario-trafego-imoveis">
					Institucional
				</a>
			</li>
		</ul>
		<div class="mobile">
			<a (click)="abrirMenu()">
				<i class="fas fa-bars"></i>
			</a>
		</div>
	</nav>
</header>

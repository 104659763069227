<footer>
  <p>
    Portal Imobiliário Tráfego Imóveis, desde 2006 &copy; Todos os direitos
    reservados.
    <br />
    <a
      href="/sobre-o-portal-imobiliario-trafego-imoveis"
      title="Saiba mais sobre o {{ global.Titulo_Portal }}"
      >Institucional</a
    >
    |
    <a href="/fale-conosco" title="Fale com {{ global.Titulo_Portal }}"
      >Fale conosco</a
    >
    |
    <a href="/avise-me" title="Alerta de Imóveis {{ global.Titulo_Portal }}"
      >Alerta de imóveis</a
    >
    |
    <a href="/termos-de-uso" title="Termos de Uso {{ global.Titulo_Portal }}"
      >Termos de uso</a
    >
    |
    <a href="/privacidade" title="Privacidade {{ global.Titulo_Portal }}"
      >Privacidade</a
    >
    |
    <a href="https://trafegoimoveis.com.br/simob" target="_blank">Sistema</a>
  </p>
</footer>

<app-header></app-header>

<main>
  <h1>Declaração de Privacidade e Cookies | {{ global.Titulo_Portal }}</h1>
  <section>
    <div class="card">
      <div class="sobre">
        <img
          src="{{ global.Api_Imagem + 'portal/img-termos-de-uso.jpg' }}"
          alt="Imagem ilustrativa Termos de Uso do Portal Imobiliario Tráfego Imóveis"
        />
        <div class="conteudo">
          <h1>Declaração de Privacidade e de Cookies</h1>
          <p>
            A Declaração de Privacidade e de Cookies dos nossos sites,
            aplicativos, serviços e sistemas poderão ser atualizados com
            regularidade, sem aviso prévio e, sempre que realizarmos alterações,
            estas serão publicadas em nossos sites. Você entende e aceita que
            deverá rever estas Políticas de Privacidade periodicamente, e que ao
            usar e acessar a Tráfego Integrada Você está aceitando os termos que
            estiverem em vigor.
          </p>
          <p><b>Última atualização:</b> 22/07/2021</p>
          <p>
            Nesta declaração explicamos como coletamos, usamos, compartilhamos e
            protegemos as suas Informações Pessoais, quando você utiliza os
            nossos produtos, serviços e os nossos sites.
          </p>
          <h2>Definições</h2>
          <p>
            Para os fins desta Política de Privacidade, as seguintes definições
            serão adotadas:
          </p>
          <p>
            <b>Anunciantes:</b> são todas as pessoas que se cadastram na Tráfego
            Integrada e que concluem o cadastro de anúncio de um imóvel.
          </p>
          <p>
            <b>Política de Privacidade:</b> são os termos contidos neste
            documento que tratam sobre a privacidade.
          </p>
          <p>
            <b>Usuários:</b> são todas as pessoas que acessam ou utilizam os
            sites, aplicativos, serviços e sistemas da Tráfego Integrada,
            independentemente de se cadastrarem e receberem uma identificação
            individual e exclusiva, ou não.
          </p>
          <p>
            <b>Produto/Serviços:</b> todos os recursos oferecidos pela Tráfego
            Integrada como sites, aplicativos, sistemas. Canal de Comunicação:
            são os meios utilizados (chat, formulários, e-mails, WhatsApp,
            ligações telefônicas, entre outros) para intermediar o contato entre
            os Anunciante(s), a Tráfego Integrada e Você.
          </p>
          <p>
            <b>Conteúdo:</b> toda informação, texto, mensagens, imagens, fotos,
            ilustrações, design, ícones, clipes de vídeo, marcas, software,
            propaganda, arquitetura funcional e outro conteúdo ou materiais
          </p>
          <p>
            <b>Cadastrar/Transmitir:</b> todo Conteúdo encontrado na Tráfego
            Integrada, sendo este disponibilizado pela Tráfego Integrada,
            Usuários, terceiros, entre outros.
          </p>
          <p>
            <b>Tráfego Integrada:</b> é a empresa proprietária e responsável
            pelos sites, aplicativos, serviços e sistemas das marcas descritas
            na seção “Nosso grupo”.
          </p>
          <p>
            <b>Você:</b> são os usuários, clientes ou anunciantes, dependendo do
            contexto em que esta definição é utilizada.
          </p>
          <p>
            Outras definições encontradas ao longo deste documento estarão
            devidamente identificadas.
          </p>
          <h2>Nosso grupo</h2>
          <p>
            <b>Ativix</b><br />
            Sistema de gerenciamento de atividades, equipe, clientes,
            recebimentos e pagamentos.<br />
            https://sistema.ativix.com.br/
          </p>
          <p>
            <b>Gerenciador de Imobiliárias</b><br />
            Painel de gerenciamento de 2ª via de boletos e extratos,
            desenvolvido em parceria com a empresa JBI Sistemas e Consultoria
            Ltda.<br />
            https://gerenciadordeimobiliarias.com.br/
          </p>
          <p>
            <b>Tráfego Imóveis | Portal Imobiliário</b><br />
            Portal de anúncios de imóveis.<br />
            https://trafegoimoveis.com.br/
          </p>
          <p>
            <b>Tráfego Soluções Integradas</b><br />
            Agência de soluções para marketing digital e desenvolvimento de
            sistemas.<br />
            https://trafegointegrada.com.br/
          </p>
          <h2>Quais Informações Pessoais nós coletamos</h2>
          <p>
            As informações que coletamos sobre Você e o modo como as coletamos
            podem variar de acordo com os Produtos e Serviços que você utiliza e
            contrata, ou a forma como foram utilizados e como interagiu com a
            Tráfego Integrada, mesmo não sendo Cliente. As informações podem ser
            obtidas através de um terceiro que tenha a sua permissão para
            compartilhar suas informações conosco. As informações que Você
            poderá compartilhar conosco, mas não se limitando são seu nome
            completo, o seu número de identidade, seu número de CPF, seu número
            de CNPJ, endereço de e-mail, número de telefone, endereço físico,
            para determinados Produtos e Serviços, ou qualquer outra informação
            que permita individualizá-lo, e para determinadas transações
            comerciais eletrônicas, informações do seu cartão de crédito. Como
            coletamos suas Informações Pessoais A Tráfego Integrada coleta
            informações pessoais fornecidas por Você, e que sejam capazes de
            identificá-lo. Estas informações coletadas podem variar de acordo
            com o Produto ou Serviço da Tráfego Integrada, assim como com o tipo
            de informação que você opta por nos fornecer. Entretanto, para
            acessar determinados Conteúdos oferecidos, poderemos solicitar de
            Você algumas informações pessoais que Você nos fornece online ou por
            qualquer meio de comunicação oficial da Tráfego Integrada e que
            permitam individualizá-lo incluindo, mas não se limitando a, seu
            nome completo, endereço de e-mail, número de telefone.
          </p>
          <p>
            A Tráfego Integrada sempre avisará Você sobre os dados que estão
            sendo coletados, sendo seu exclusivo critério fornecê-los ou não,
            estando ciente de que, suas informações pessoais são imprescindíveis
            para acesso e execução de alguns serviços e produtos
            disponibilizados.
          </p>
          <p>
            Você aceita, tem ciência, autoriza e presta seu consentimento livre
            e expresso que suas informações pessoais sejam utilizadas, mas não
            se limitando, com as finalidades de tratamento, armazenamento,
            recopilamento nos bancos de dados de propriedade da Tráfego
            Integrada.
          </p>
          <p>
            Para oferecer um serviço cada vez mais eficiente e direcionado às
            pessoas que o utilizam, a Tráfego Integrada rastreia determinadas
            informações sobre Você, à medida em que Você o visita e utiliza
            nossos Produtos, Serviços e recursos. Estas informações podem
            incluir o “IP” (endereço de protocolo de Internet de seu computador,
            as URLs, páginas, telas e o número de vezes que Você os visitou cada
            página da Tráfego Integrada, quais downloads e/ou pesquisas você
            fez, quanto tempo você passou em determinadas seções dos nossos
            sites, aplicativos, sistemas, bem como seu tipo de navegador. Estas
            informações são colhidas automaticamente através de “cookies”, os
            quais são descritos detalhadamente em uma seção específica sobre
            eles.
          </p>
          <h2>Como tratamos e utilizamos as suas Informações Pessoais</h2>
          <p>
            As Informações Pessoais que Você nos fornece quando Você se torna um
            Usuário ou Anunciante registrado na Tráfego Integrada são usadas
            primeiramente para aproximar Você do(s) Anunciante(s), com os quais
            você optou por compartilhar suas informações, através do interesse
            em algum anúncio veiculado na Tráfego Integrada, estes poderão
            utilizar seus dados pessoais para outras finalidades não
            especificadas nesta Política, desde que este tratamento de dados se
            enquadre em uma das hipóteses do artigo 7º da Lei 13.709/18 (LGPD),
            além de possibilitar o envio a Você de Anúncio(s) de seu interesse.
          </p>
          <p>
            Estas informações pessoais também poderão ser utilizadas para nos
            ajudar a personalizar sua experiência online com os Produtos e
            Serviços da Tráfego Integrada.
          </p>
          <p>
            Suas Informações Pessoais também serão utilizadas para a
            formalização e execução do seu contrato, cumprimento de funções
            contabilísticas e fiscais, e para dar seguimento aos seus pedidos.
            Assim como para o cumprimento de obrigação legal a que a Tráfego
            Integrada esteja ligada.
          </p>
          <p>
            A Tráfego Integrada não armazena informações sobre origem racial ou
            étnica, convicção religiosa, opinião política, filiação a sindicato
            ou organização de caráter religioso, filosófico ou político, dado
            referente à saúde ou vida sexual, dado genético ou biométrico a seu
            respeito.
          </p>
          <h2>Como compartilhamos suas Informações Pessoais</h2>
          <p>Quando for aplicável, compartilharemos as suas informações com:</p>
          <ul>
            <li>Empresas do grupo Tráfego Integrada;</li>
            <li>
              Usuários, Anunciantes, Parceiros, ou agentes envolvidos na
              disponibilização de produtos e serviços que tenha contratado;
            </li>
            <li>
              Autoridades policiais, órgãos governamentais, autoridades
              reguladoras, tribunais ou outras autoridades públicas, quando a
              tal formos obrigados ou autorizados, nos termos da legislação
              nacional vigente.
            </li>
          </ul>

          <h2>Sobre Cookies</h2>
          <p>
            “Cookies” são pequenos arquivos eletrônicos que são armazenados no
            seu navegador de Internet para que nós possamos reconhecê-lo na
            próxima vez que você visitar algum site, aplicativo, serviço ou
            produto da Tráfego Integrada. Estes cookies não coletam seus dados
            pessoais, e são utilizados somente para auxiliar em sua
            identificação.
          </p>
          <p>
            Caso Você não deseje compartilhar seus cookies, Você está livre para
            recusar quaisquer cookies que nós utilizamos, se seu navegador assim
            permitir. Alguns de nosso(s) Anunciante(s) e provedores de serviços
            também podem utilizar-se dos seus próprios cookies.
          </p>
          <h2>Tempo de armazenamento de suas Informações Pessoais</h2>
          <p>
            Armazenaremos as suas informações pelo período que forem necessárias
            ao cumprimento de cada finalidade de tratamento.
          </p>
          <p>
            Os dados de cobrança são conservados durante a execução do contrato
            e, após a sua suspensão, poderão ainda ser conservados durante um
            período necessário para o cumprimento pela Tráfego Integrada das
            suas obrigações legais.
          </p>
          <p>
            Você poderá solicitar à Tráfego Integrada, por e-mail através do
            endereço contato@trafegointegrada.com.br, no horário comercial, as
            seguintes informações de seus dados pessoais:
          </p>
          <ul>
            <li>
              Acesso e correção de seus dados pessoais armazenados na Tráfego
              Integrada;
            </li>
            <li>
              A anonimização, bloqueio e eliminação de dados desnecessários ou
              excessivos, exceto quando o tratamento é legal (previsto na
              legislação brasileira), mesmo sem o seu consentimento;
            </li>
            <li>
              A portabilidade dos seus dados a outro veiculador de anúncios,
              mediante sua requisição expressa;
            </li>
            <li>
              Confirmação se existe um ou mais tratamento de dados sendo
              realizado;
            </li>
          </ul>
          <h2>Como protegemos as suas Informações Pessoais</h2>
          <p>
            As Informações Pessoais armazenadas na Tráfego Integrada são
            mantidas em servidores protegidos contra acesso ou uso não
            autorizado a terceiros. Toda a transferência de informações entre
            Você e a Tráfego Integrada é realizada por conexões seguras,
            utilizando sistemas criptográficos SSL (“Secure Sockets Layer”),
            principal tecnologia disponível atualmente para segurança online e
            chaves de segurança eletrônicas. Apesar da utilização de meios de
            segurança, Você reconhece que os meios técnicos existentes que
            proporcionam segurança não são infalíveis e que mesmo assim é
            possível sofrer manipulações, destruição, ou perda de informação.
            Caso se apresentem esses casos, a Tráfego Integrada procederá
            conforme for requerido pelas leis de cada país em matéria de
            privacidade e proteção de dados.
          </p>
          <p>
            Os colaboradores da Tráfego Integrada são treinados para compreender
            e cumprir os princípios de proteção de dados pessoais e segurança da
            informação. Assumindo estritos compromissos de confidencialidade
            quanto à Informação Pessoal que processam no exercício de suas
            funções.
          </p>
          <h2>Contato</h2>
          <p>
            Para solicitações ou dúvidas sobre estas Políticas de Privacidade e
            de Cookies, envie um e-mail para contato@trafegointegrada.com.br.
          </p>
        </div>
      </div>
    </div>
  </section>
</main>

<app-footer></app-footer>

<div class="modal" id="modal-aviseme" *ngIf="abrir_modal">

  <div class="conteudo">
    <div class="fechar">
      <button class="btn alerta" (click)="fechar()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="geral">
      <div class="dados">
        <div class="formulario">
          <div class="form">
            <form *ngIf="ExibirFormulario" (ngSubmit)="onSubmit()" #testForm="ngForm" ngNativeValidate>

              <div class="info">
                <div class="aviso">
                  <h4>
                    <i class="fas fa-exclamation-triangle"></i>
                    Salva alerta!
                  </h4>

                  <img src="{{global.Api_Imagem + 'portal/img-aviseme.png'}}"
                       alt="Imagem ilustrativa Alerta de Imóveis por e-mail">

                  <p>
                    Iremos notificar em seu e-mail, os novos imóveis de seu interesse. Caso queira
                    alterar
                    os interesses, refaça sua busca e clique novamente na opção <strong>"Criar Alerta"</strong></p>
                  <small>A qualquer momento você poderá cancelar os recebimentos do alerta criado!</small>
                </div>
                <div class="box-preenchimento">

                  <div class="espaco">
                    <label for="cmp-aviseme-nome">Seu nome</label>
                    <div class="campo">
                      <input type="text" id="cmp-aviseme-nome" class="form-control" placeholder="Seu nome"
                             required [(ngModel)]="form.nome" [ngModelOptions]="{standalone: true}">
                    </div>

                    <label for="cmp-aviseme-email">Seu e-mail</label>
                    <div class="campo">
                      <input type="text" placeholder="E-mail" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                             #emailref="ngModel" required id="cmp-aviseme-email" name="email" [(ngModel)]="form.email"
                             [ngModelOptions]="{standalone: true}">
                    </div>
                    <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)">
                      <p [hidden]="!emailref.errors?.pattern">Informe um e-mail válido</p>
                    </div>

                    <div class="interesses">
                      <h2>Seus interesses</h2>
                      <ul>
                        <li *ngIf="filtros['operacao']">
                          <strong>Operação:</strong> {{filtros['operacao']}}
                        </li>
                        <li *ngIf="filtros['localizacao'] && filtros['localizacao'] !== ''">
                          <strong>Localização:</strong>
                          <ul>
                            <li *ngFor="let local of filtros['localizacao']">{{local}}</li>
                          </ul>
                        </li>
                        <li *ngIf="filtros['tipo']">
                          <strong>Tipo do Imóvel:</strong>
                          <ul>
                            <li *ngFor="let tipo of filtros['tipo']">{{tipo}}</li>
                          </ul>
                        </li>
                        <li *ngIf="_valor_min">
                          <strong>Valor mínimo:</strong> {{_valor_min}}
                        </li>
                        <li *ngIf="_valor_max">
                          <strong>Valor máximo:</strong> {{_valor_max}}
                        </li>
                      </ul>
                    </div>

                    <button type="submit" [disabled]="!testForm.form.valid" class="btn laranja">
                      <i class="fab fa-telegram-plane"></i> Salvar
                    </button>
                  </div>

                </div>
              </div>
            </form>
            <div *ngIf="!ExibirFormulario" class="enviando">
              <div class="loading-conteudo" *ngIf="ExibirFormularioEnviando">
                <img src="{{global.Api_Imagem + 'portal/loading.gif'}}" class="text-center" alt="">
              </div>
              <br>
              <h3 *ngIf="ExibirFormularioEnviando">
                Estamos salvando suas preferências.
                Iremos enviar os imóveis conforme seu perfil!
              </h3>
              <h3 *ngIf="!ExibirFormularioEnviando">
                <i class="far fa-thumbs-up"></i>
                <br>
                Seus dados foram salvos com sucesso!
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

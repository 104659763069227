import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {RequestService} from "../../shared/services/request.service";
import {Globals} from "../../app.global";

@Component({
	selector: 'modal-problema',
	templateUrl: './problemaImovel.component.html',
	styleUrls: ['./problemaImovel.component.scss']
})

export class ProblemaImovelComponent implements OnChanges {
	@Input() imovel_id: '';
	@Input() modal: '';
	public abrir_modal: boolean = false;
	ExibirFormulario: boolean = true;
	ExibirFormularioEnviando: boolean = true;

	form: any = [];

	constructor(public request: RequestService, public global: Globals) {
	}

	ngOnChanges(changes: SimpleChanges) {

		this.form.nome = '';
		this.form.email = '';
		this.form.mensagem = '';
		this.form.aviseme = true;

		this.ExibirFormulario = true;
		this.ExibirFormularioEnviando = true;
		if (this.modal && this.imovel_id) {
			this.abrir_modal = true;
		}
	}

	onSubmit() {

		let nome = 0;
		let email = 0;
		let telefone = 0;
		let mensagem = 0;
		let imovel_id = this.imovel_id;
		let origem = 'portal';

		if (this.form.nome) {
			nome = this.form.nome;
		}
		if (this.form.telefone) {
			telefone = this.form.telefone;
		}
		if (this.form.email) {
			email = this.form.email;
		}
		if (this.form.mensagem) {
			mensagem = this.form.mensagem;
		}

		this.ExibirFormulario = false;
		this.ExibirFormularioEnviando = true;

		this.request.get('/Envio/enviosPortal?referencia=problema-imovel&assunto=Imóvel com Problema&imovel=' + imovel_id + '&nome='+nome+'&email='+email+'&telefone='+telefone+'&mensagem=' + mensagem)
			.then(res => {
					this.ExibirFormularioEnviando = false;
					this.ExibirFormulario = false;

					this.form.nome = '';
					this.form.telefone = '';
					this.form.email = '';
					this.form.mensagem = '';
				},
				err => err);
	}

	fechar() {
		this.abrir_modal = false;
	}
}

/*
** import core dependencies for ngModule
** @return Object {}
*/
import {BrowserModule, makeStateKey} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {EmbedVideo} from 'ngx-embed-video';
import {UICarouselModule} from 'ui-carousel';
import {BrMasker4Module} from 'brmasker4';
import {NgxCurrencyModule} from 'ngx-currency';
import {DeviceDetectorModule} from 'ngx-device-detector';

/*
** import required ngModule list modules
** @return Array()
*/
import {
  DeclarationList,
  ProviderList,
  RouterList,
  AppBootstrap
} from './app.config.module';

import { AgmCoreModule } from '@agm/core';

// create request key to state transfer from server to client and vice versa
export const REQ_KEY = makeStateKey<string>('req');

// declare angular modules
@NgModule({
  declarations: [
    DeclarationList
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'my-app'}),
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    EmbedVideo.forRoot(),
    RouterModule.forRoot(RouterList),
    UICarouselModule,
    BrMasker4Module,
    NgxCurrencyModule,
    DeviceDetectorModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBL5WtGjVVQfEFYZ5hlkH_WvdspmTdWBPE'
    })
  ],
  providers: ProviderList,
  bootstrap: AppBootstrap
})
export class AppModule {
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fromEvent as observableFromEvent } from 'rxjs';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { Globals } from '../../app.global';
import { RequestService } from '../../shared/services/request.service';
import { PaginationService } from '../../shared/services/pagination.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Location } from '@angular/common';
var ImoveisComponent = /** @class */ (function () {
    function ImoveisComponent(route, title, meta, global, router, request, pagination, deviceService, location) {
        var _this = this;
        this.route = route;
        this.title = title;
        this.meta = meta;
        this.global = global;
        this.router = router;
        this.request = request;
        this.pagination = pagination;
        this.deviceService = deviceService;
        this.location = location;
        this.filtros = {
            codigo: '',
            operacao: '',
            tipo: '',
            localizacao: '',
            pagina: '1',
            valor_min: '',
            valor_max: '',
            area_min: '',
            area_max: '',
            financiamento: '',
            permulta: '',
            publicado: '',
            ordenar: ''
        };
        this.exibirAviseme = 0;
        this.total_imoveis = 0;
        this.total_paginas = 0;
        this.tipoFiltros = [];
        this.localizacaoExibir = false;
        this.localizacaoFiltros = [];
        this.route.queryParams.subscribe(function (params) {
            _this.filtros.pagina = (params.pagina ? params.pagina : '1');
            _this.filtros.codigo = (params.codigo ? params.codigo : '');
            _this.filtros.operacao = (params.operacao ? params.operacao : '');
            _this.filtros.tipo = (params.tipo ? params.tipo : '');
            _this.filtros.localizacao = (params.localizacao ? params.localizacao : '');
            _this.filtros.valor_min = (params.valor_min ? params.valor_min : '');
            _this.filtros.valor_max = (params.valor_max ? params.valor_max : '');
            _this._valor_min = parseFloat(_this.filtros.valor_min.replace(/[^0-9-,]/gi, '', ''));
            _this._valor_max = parseFloat(_this.filtros.valor_max.replace(/[^0-9-,]/gi, '', ''));
            _this.filtros.area_min = (params.area_min ? params.area_min : '');
            _this.filtros.area_max = (params.area_max ? params.area_max : '');
            _this._area_min = parseFloat(_this.filtros.area_min.replace(/[^0-9-,]/g, '', ''));
            _this._area_max = parseFloat(_this.filtros.area_max.replace(/[^0-9-,]/g, '', ''));
            _this.filtros.vaga = (params.vaga ? params.vaga : '');
            _this.filtros.banheiro = (params.banheiro ? params.banheiro : '');
            _this.filtros.quarto = (params.quarto ? params.quarto : '');
            _this.filtros.ordenar = (params.ordenar ? params.ordenar : '');
            _this.filtros.publicado = (params.publicado ? params.publicado : '');
            _this.filtros.financiamento = (params.financiamento ? params.financiamento : '');
            _this.filtros.permuta = (params.permuta ? params.permuta : '');
        });
        this.route.params.subscribe(function (res) {
            if (_this.filtros.operacao === '') {
                _this.filtros.operacao = (!res.operacao ? 'Venda-Aluguel-Temporada' : res.operacao);
            }
            if (_this.filtros.tipo === '') {
                _this.filtros.tipo = (!res.tipo ? 'Todos-Imoveis' : res.tipo);
            }
            if (_this.filtros.localizacao === '') {
                var estado = '';
                if (res.estado) {
                    estado = '/' + res.estado;
                }
                _this.filtros.localizacao = (!res.localizacao ? 'Em-qualquer-localizacao' : res.localizacao + estado);
            }
        });
        this.setTipo();
        this.mobile = this.deviceService.isMobile();
        if (this.mobile) {
            this.exibirBusca = false;
        }
    }
    Object.defineProperty(ImoveisComponent.prototype, "_localizacao", {
        set: function (localizacao) {
            this.localizacao = localizacao;
        },
        enumerable: true,
        configurable: true
    });
    ImoveisComponent.prototype.timeout = function (ms) {
        return new Promise(function (resolve) { return setTimeout(resolve, ms); });
    };
    ImoveisComponent.prototype.abrirAviseme = function () {
        this.exibirAviseme++;
    };
    ImoveisComponent.prototype.abrirBusca = function () {
        if (this.exibirBusca) {
            this.exibirBusca = false;
        }
        else {
            this.exibirBusca = true;
        }
    };
    ImoveisComponent.prototype.setBusca = function (opcao, valor) {
        this.filtros.pagina = 1;
        this.filtros.codigo = '';
        if (opcao == 'vaga' || opcao == 'quarto' || opcao == 'banheiro') {
            if (this.filtros[opcao] == valor) {
                this.filtros[opcao] = '';
            }
            else {
                this.filtros[opcao] = valor;
            }
        }
        else {
            this.filtros[opcao] = valor;
        }
        var url = '/imoveis';
        var x_operacao = '';
        var x_tipo = '';
        var x_localizacao = '';
        if (opcao == 'operacao' || opcao == 'tipo' || opcao == 'localizacao') {
            x_operacao = (!this.filtros.operacao ? 'Venda-Aluguel-Temporada' : this.filtros.operacao);
            if (opcao == 'operacao') {
                this.valor_minRef.nativeElement.value = '';
                this.valor_maxRef.nativeElement.value = '';
                this.setBusca('valor_min', '0');
                this.setBusca('valor_max', '0');
                x_operacao = valor;
            }
            x_tipo = (!this.filtros.tipo ? 'Todos-Imoveis' : this.filtros.tipo);
            if (opcao == 'tipo') {
                x_tipo = valor;
            }
            x_localizacao = (!this.filtros.localizacao ? 'Em-qualquer-localizacao' : this.filtros.localizacao);
            if (opcao == 'localizacao') {
                x_localizacao = valor;
            }
        }
        else {
            this.route.params.subscribe(function (res) {
                x_operacao = (!res.operacao ? 'Venda-Aluguel-Temporada' : res.operacao);
                x_tipo = (!res.tipo ? 'Todos-Imoveis' : res.tipo);
                x_localizacao = (!res.localizacao ? 'Em-qualquer-localizacao' : res.localizacao);
            });
        }
        this.setTipo();
        url += '/' + x_operacao + '/' + x_tipo;
        url += '?pagina=' + this.filtros.pagina;
        url += '&operacao=' + this.filtros.operacao;
        url += '&tipo=' + this.filtros.tipo;
        url += '&localizacao=' + this.filtros.localizacao;
        url += '&valor_min=' + this.filtros.valor_min;
        url += '&valor_max=' + this.filtros.valor_max;
        url += '&area_min=' + this.filtros.area_min;
        url += '&area_max=' + this.filtros.area_max;
        url += '&quarto=' + this.filtros.quarto;
        url += '&banheiro=' + this.filtros.banheiro;
        url += '&vaga=' + this.filtros.vaga;
        url += '&publicado=' + this.filtros.publicado;
        url += '&ordenar=' + this.filtros.ordenar;
        url += '&codigo=' + this.filtros.codigo;
        if (opcao == 'publicado' || opcao == 'ordenar') {
            this.listaPublicado = false;
            this.listaOrdem = false;
        }
        this.location.go(url);
        this.loadImoveis();
    };
    ImoveisComponent.prototype.setOpcoes = function () {
        this._localizacao = '';
        this.tipo = '';
        var dados = [];
        var novaLista = [];
        dados = this.filtros.localizacao.split(',');
        dados.forEach(function (value) {
            if (value != '' && value != 'Em-qualquer-localizacao') {
                novaLista.push(value);
            }
        });
        this.localizacaoFiltros = novaLista;
        dados = this.filtros.tipo.split(',');
        novaLista = [];
        dados.forEach(function (value) {
            if (value != '' && value != 'Todos-Imoveis') {
                novaLista.push(value);
            }
        });
        this.tipoFiltros = novaLista;
    };
    ImoveisComponent.prototype.setTipo = function () {
        var _this = this;
        this.request.get('/Buscas/Tipo?operacao=' + this.filtros.operacao + '&localizacao=' + this.filtros.operacao)
            .then(function (res) {
            if (res.status == 1) {
                _this.tipoOpcoes = res.result;
            }
            else {
                _this.tipoOpcoes[0] = 'Erro ao carregar';
            }
        }, function (err) { return err; });
    };
    ImoveisComponent.prototype.onChangeTipo = function () {
        var tipo = this.tipo;
        var novoTipo = tipo;
        var dados = this.filtros.tipo.split(',');
        dados.forEach(function (value) {
            if (value != tipo && value != '' && value != 'Todos-Imoveis') {
                novoTipo += ',' + value;
            }
        });
        this.setBusca('tipo', novoTipo);
    };
    ImoveisComponent.prototype.removeTipo = function (item) {
        this.filtros.pagina = 1;
        var dados = this.filtros.tipo.split(',');
        var novoTipo = '';
        dados.forEach(function (value) {
            if (value != item) {
                if (novoTipo != '') {
                    novoTipo += ',' + value;
                }
                else {
                    novoTipo = value;
                }
            }
        });
        this.setBusca('tipo', novoTipo);
    };
    ImoveisComponent.prototype.onChangeLocalizacao = function (index, tipo) {
        this.filtros.codigo = '';
        this.filtros.pagina = 1;
        if (tipo == 'b') {
            var local = this.localizacaoOpcoesBairros[index].bairro + '-' + this.localizacaoOpcoesBairros[index].cidade + '/' + this.localizacaoOpcoesBairros[index].estado;
        }
        else {
            var local = this.localizacaoOpcoesCidades[index].cidade + '/' + this.localizacaoOpcoesCidades[index].estado;
        }
        var novaLocalizacao = local;
        var dados = this.filtros.localizacao.split(',');
        dados.forEach(function (value) {
            if (value != local && value != '' && value != 'Em-qualquer-localizacao') {
                novaLocalizacao += ',' + value;
            }
        });
        this.setBusca('localizacao', novaLocalizacao);
        this._localizacao = '';
    };
    ImoveisComponent.prototype.removeLocalizacao = function (item) {
        this.filtros.pagina = 1;
        var dados = this.filtros.localizacao.split(',');
        var novoLocalizacao = '';
        dados.forEach(function (value) {
            if (value != item) {
                if (novoLocalizacao != '') {
                    novoLocalizacao += ',' + value;
                }
                else {
                    novoLocalizacao = value;
                }
            }
        });
        this.setBusca('localizacao', novoLocalizacao);
    };
    ImoveisComponent.prototype.focus = function () {
        this.localizacaoExibir = true;
    };
    ImoveisComponent.prototype.focusOut = function () {
        var _this = this;
        var timestampDiffs = [1];
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var _i, timestampDiffs_1, item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _i = 0, timestampDiffs_1 = timestampDiffs;
                        _a.label = 1;
                    case 1:
                        if (!(_i < timestampDiffs_1.length)) return [3 /*break*/, 4];
                        item = timestampDiffs_1[_i];
                        return [4 /*yield*/, this.timeout(item * 1000)];
                    case 2:
                        _a.sent();
                        this.localizacaoExibir = false;
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        }); })();
    };
    ImoveisComponent.prototype.loadImoveis = function () {
        var _this = this;
        this._localizacao = '';
        this.setOpcoes();
        window.scroll(0, 0);
        this.imoveis = false;
        this.request.get('/imoveis/?pagina=' + this.filtros.pagina + '&operacao=' + this.filtros.operacao + '&tipo=' + this.filtros.tipo + '&localizacao=' + this.filtros.localizacao + '&valor_min=' + this.filtros.valor_min + '&valor_max=' + this.filtros.valor_max + '&quarto=' + this.filtros.quarto + '&banheiro=' + this.filtros.banheiro + '&vaga=' + this.filtros.vaga + '&publicado=' + this.filtros.publicado + '&ordenar=' + this.filtros.ordenar + '&codigo=' + this.filtros.codigo + '&area_min=' + this.filtros.area_min + '&area_max=' + this.filtros.area_max)
            .then(function (res) {
            if (res) {
                _this.titulo = res.title;
                _this.imoveis = res.result;
                _this.total_imoveis = res.sum;
                _this.total_paginas = res.paginas;
                _this.allItems = res.sum;
                _this.setPage(_this.filtros.pagina);
                _this.seo(res);
            }
            else {
                _this.erro = true;
            }
        }, function (err) { return err; });
    };
    ImoveisComponent.prototype.setPage = function (page) {
        this.pager = this.pagination.getPager(this.allItems, page);
    };
    ImoveisComponent.prototype.abrirOrdem = function () {
        if (this.listaOrdem) {
            this.listaOrdem = false;
        }
        else {
            this.listaOrdem = true;
        }
    };
    ImoveisComponent.prototype.abrirPublicado = function () {
        if (this.listaPublicado) {
            this.listaPublicado = false;
        }
        else {
            this.listaPublicado = true;
        }
    };
    ImoveisComponent.prototype.ngOnInit = function () {
        var _this = this;
        observableFromEvent(this.localizacaoRef.nativeElement, 'keyup').pipe(map(function (evt) { return evt.target.value; }), debounceTime(400), distinctUntilChanged())
            .subscribe(function (text) {
            _this._localizacao = text;
            _this.request.get('/Buscas/localizacao?operacao=' + _this.filtros.operacao + '&tipo=' + _this.filtros.tipo + '&localizacao=' + _this.localizacao)
                .then(function (res) {
                _this.localizacaoOpcoesCidades = res.result.cidades;
                _this.localizacaoOpcoesBairros = res.result.bairros;
            }, function (err) { return err; });
            _this.localizacaoExibir = true;
        });
        observableFromEvent(this.valor_minRef.nativeElement, 'keyup').pipe(map(function (evt) { return evt.target.value; }), debounceTime(600), distinctUntilChanged())
            .subscribe(function (text) {
            _this.setBusca('valor_min', text);
        });
        observableFromEvent(this.valor_maxRef.nativeElement, 'keyup').pipe(map(function (evt) { return evt.target.value; }), debounceTime(600), distinctUntilChanged())
            .subscribe(function (text) {
            _this.setBusca('valor_max', text);
        });
        observableFromEvent(this.area_minRef.nativeElement, 'keyup').pipe(map(function (evt) { return evt.target.value; }), debounceTime(600), distinctUntilChanged())
            .subscribe(function (text) {
            _this.setBusca('area_min', text);
        });
        observableFromEvent(this.area_maxRef.nativeElement, 'keyup').pipe(map(function (evt) { return evt.target.value; }), debounceTime(600), distinctUntilChanged())
            .subscribe(function (text) {
            _this.setBusca('area_max', text);
        });
        this.loadImoveis();
    };
    ImoveisComponent.prototype.seo = function (res) {
        //CARREGA AS TAGS PARA A PAGINA
        this.title.setTitle(res.title + ' - ' + this.global.Titulo_Portal);
        this.meta.addTag({
            name: 'description',
            content: res.description
        });
        this.meta.addTag({
            name: 'keywords',
            content: res.keywords
        });
        this.meta.addTag({
            name: 'author',
            content: 'Tráfego Integrada'
        });
    };
    return ImoveisComponent;
}());
export { ImoveisComponent };

<app-header></app-header>
<main>
	<section class="header">
		<h1 *ngIf="imobiliaria">
			{{imobiliaria[0].nome}}
			<br>
			<small>Veja abaixo todos os imóveis do anunciante</small>
		</h1>
		<a href="/anunciantes" class="btn destaque" title="Conheça mais anunciantes">
			<i class="fas fa-plus"></i> ANUNCIANTES
		</a>
	</section>
	<section>
		<div class="anunciante">
			<app-dados-anunciante [slug]="slug" [local]="dadosAnuncianteTipo"></app-dados-anunciante>
		</div>
		<div class="imoveis">
			<div *ngIf="!imoveis && !erro" class="col-md-12 loading-conteudo text-center">
				<img src="{{global.Api_Imagem + 'portal/loading.gif'}}" class="text-center" alt="">
			</div>
			<div *ngIf="imoveis && !erro" class="card total_imoveis">
				<h2>Foram encontrados <strong>{{total_imoveis | numeroPipe:locale}}</strong> imóveis.</h2>
			</div>
			<div *ngIf="imoveis && !erro" class="lista_imoveis">
				<app-lista-imoveis [imoveis]="imoveis" [imobiliaria_id]="imobiliaria_id"></app-lista-imoveis>
			</div>
			<div *ngIf="imoveis && !erro">
				<nav class="paginacao" aria-label="Paginação dos Imóveis">
					<!-- pager -->
					<ul *ngIf="pager.pages && pager.pages.length"
						class="pagination pagination-lg justify-content-center ">
						<li *ngIf="pager.currentPage>1" class="page-item">
							<a class="page-link"
							   href="/anunciante/{{imobiliaria[0].slug}}/1">Primeira</a>
						</li>
						<li *ngIf="pager.currentPage>1" class="page-item btn-anterior"
							[ngClass]="{disabled:pager.currentPage == 1}">
							<a class="page-link"
							   href="/anunciante/{{imobiliaria[0].slug}}/{{pager.currentPage - 1}}">Anterior</a>
						</li>
						<li class="page-item" *ngFor="let page of pager.pages"
							[ngClass]="{active:pager.currentPage === page}">
							<a class="page-link"
							   href="/anunciante/{{imobiliaria[0].slug}}/{{page}}">{{page}}</a>
						</li>
						<li *ngIf="pager.currentPage < pager.totalPages" class="page-item btn-proximo">
							<a class="page-link"
							   href="/anunciante/{{imobiliaria[0].slug}}/{{pager.currentPage + 1}}">Próxima</a>
						</li>
						<li *ngIf="pager.currentPage < pager.totalPages" class="page-item">
							<a class="page-link"
							   href="/anunciante/{{imobiliaria[0].slug}}/{{pager.totalPages}}">Última</a>
						</li>
					</ul>
				</nav>
			</div>
			<app-chamada-erro *ngIf="erro"></app-chamada-erro>
		</div>
	</section>
</main>
<app-footer></app-footer>

<div class="modal" id="modal-contato-imovel" *ngIf="abrir_modal">

    <div class="loading-conteudo" *ngIf="!imovel">
        <img src="{{global.Api_Imagem + 'portal/loading.gif'}}" class="text-center" alt="">
    </div>

    <div class="conteudo">
        <div class="fechar">
            <button class="btn alerta" (click)="fechar()">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="geral" *ngIf="imovel">
            <div class="anunciante">
                <app-dados-anunciante [slug]="imovel.imobiliaria.slug"
                                      [local]="dadosAnuncianteTipo"></app-dados-anunciante>
            </div>
            <div class="dados">
                <div class="imovel">
                    <div class="imagem" *ngIf="imovel.imagem"
                         [ngStyle]="{'background-image': 'url(' + global.Api_Imagem + 'imoveis/' + imovel.imobiliaria.id + '/' + imovel.imagem[0].name + ')'}"></div>
                    <div class="imagem" *ngIf="!imovel.imagem"
                         [ngStyle]="{'background-image': 'url(' + global.Api_Imagem + 'imoveis/imovel-sem-foto.jpg)'}"></div>
                    <div class="informacoes">
                        <h2>
                            <small>Código: {{imovel.codigo}}</small>
                            {{imovel.titulo}}
                        </h2>
                        <div class="valores">
                            <h4 *ngIf="imovel.valores">{{imovel.valores.valor}}</h4>
                            <div class="outros"
                                 *ngIf="imovel.valores.valor_entrada || imovel.valores.valor_condominio || imovel.valores.valor_m2">
                                <span></span>
                                <i class="fas fa-arrow-right"></i>
                                <div class="item" *ngIf="imovel.valores.valor_entrada">
                                    <small>Valor da entrada</small>
                                    <h6>{{imovel.valores.valor_entrada}}</h6>
                                </div>
                                <div class="item" *ngIf="imovel.valores.valor_condominio">
                                    <small>Valor do condomínio</small>
                                    <h6>{{imovel.valores.valor_condominio}}</h6>
                                </div>
                                <div class="item" *ngIf="imovel.valores.valor_m2">
                                    <small>Valor do m²</small>
                                    <h6>{{imovel.valores.valor_m2}}</h6>
                                </div>
                            </div>
                        </div>
                        <a href="/imovel/{{imovel.id}}/{{imovel.url}}" title="Mais informações {{imovel.titulo}}"
                           class="btn azul">
                            <i class="fas fa-plus"></i> Informações do Imóvel
                        </a>
                    </div>
                </div>
                <div class="formulario">
                    <div class="form">
                        <form *ngIf="ExibirFormulario" (ngSubmit)="onSubmit()" #testForm="ngForm" ngNativeValidate>
                            <h4>
                                <i class="fas fa-envelope-open-text"></i>
                                Enviar mensagem!
                            </h4>
                            <div class="grupo">
                                <div>
                                    <label for="cmp-anuncie-nome">Seu nome</label>
                                    <div class="campo">
                                        <input type="text" id="cmp-anuncie-nome" class="form-control"
                                               placeholder="Seu nome" required [(ngModel)]="form.nome"
                                               [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="telefone">
                                    <label for="cmp-anuncie-telefone">Seu telefone</label>
                                    <div class="campo">
                                        <input type="text" placeholder="Telefone"
                                               id="cmp-anuncie-telefone" required [(ngModel)]="form.telefone"
                                               [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                            </div>
                            <label for="cmp-anuncie-email">Seu e-mail</label>
                            <div class="campo">
                                <input type="text" placeholder="E-mail"
                                       pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required
                                       id="cmp-anuncie-email" name="email" [(ngModel)]="form.email"
                                       [ngModelOptions]="{standalone: true}"
                                       #emailref="ngModel">
                            </div>
                            <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)">
                                <p [hidden]="!emailref.errors?.pattern">Informe um e-mail válido</p>
                            </div>

                            <label for="cmp-anuncie-telefone">Sua mensagem</label>
                            <div class="campo">
                                <textarea required [(ngModel)]="form.mensagem" name="mensagem" rows="4"
                                          [ngModelOptions]="{standalone: true}" id="cmp-opniao-mensagem"
                                          placeholder="Deixe aqui sua mensagem, é muito importante para nós"></textarea>
                            </div>

                            <div class="aviso" *ngIf="aviso">
                                <strong>Preencha os campos!</strong> Informe seu <strong>NOME</strong> e informe tambem
                                um <strong>TELEFONE</strong> ou <strong>E-MAIL</strong> para contato
                            </div>

                            <button type="submit" [disabled]="!testForm.form.valid" class="btn laranja">
                                <i class="fab fa-telegram-plane"></i> Enviar formulário
                            </button>
                        </form>
                        <div *ngIf="!ExibirFormulario" class="loading-conteudo text-center">
                            <img *ngIf="ExibirFormularioEnviando"
                                 src="{{global.Api_Imagem + '/portal/loading.gif'}}"
                                 class="text-center"
                                 alt="">
                            <br>
                            <h3 *ngIf="ExibirFormularioEnviando">
                                Estamos enviando seu interesse para
                                <strong>{{imovel.imobiliaria.nome}}</strong> nesse
                                momento!
                            </h3>
                            <h3 *ngIf="!ExibirFormularioEnviando">
                                <i class="ft-thumbs-up font-large-3 teal"></i>
                                <br>
                                Seus dados foram enviados com sucesso!
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="modal-problema-imovel" *ngIf="abrir_modal">

	<div class="loading-conteudo" *ngIf="!imovel_id">
		<img src="{{global.Api_Imagem + 'portal/loading.gif'}}" class="text-center" alt="">
	</div>

	<div class="conteudo">
		<div class="fechar">
			<button class="btn alerta" (click)="fechar()">
				<i class="fas fa-times"></i>
			</button>
		</div>
		<div class="geral">
			<div class="dados">
				<div class="formulario">
					<div class="form">
						<form *ngIf="ExibirFormulario" (ngSubmit)="onSubmit()" #testForm="ngForm" ngNativeValidate>
							<h4>
								<i class="fas fa-exclamation-triangle"></i>
								Relatar problema!
							</h4>

							<label for="cmp-anuncie-nome">Seu nome</label>
							<div class="campo">
								<input type="text" id="cmp-anuncie-nome" class="form-control" placeholder="Seu nome" required [(ngModel)]="form.nome" [ngModelOptions]="{standalone: true}">
							</div>

							<label for="cmp-anuncie-telefone">Seu telefone</label>
							<div class="campo">
								<input type="text" placeholder="Telefone"
									   id="cmp-anuncie-telefone" required [(ngModel)]="form.telefone"
									   [ngModelOptions]="{standalone: true}">
							</div>

							<label for="cmp-anuncie-email">Seu e-mail</label>
							<div class="campo">
								<input type="text" placeholder="E-mail" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
									   id="cmp-anuncie-email" name="email" [(ngModel)]="form.email" [ngModelOptions]="{standalone: true}"
									   #emailref="ngModel">
							</div>
							<div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)">
								<p [hidden]="!emailref.errors?.pattern">Informe um e-mail válido</p>
							</div>

							<label for="cmp-anuncie-telefone">Sua mensagem</label>
							<div class="campo">
								<textarea [(ngModel)]="form.mensagem" [ngModelOptions]="{standalone: true}" id="cmp-opniao-mensagem" name="mensagem"
										  placeholder="Deixe aqui sua mensagem, é muito importante para nós" rows="4"></textarea>
							</div>

							<button type="submit" [disabled]="!testForm.form.valid" class="btn laranja">
								<i class="fab fa-telegram-plane"></i> Enviar formulário
							</button>
						</form>
						<div *ngIf="!ExibirFormulario" class="enviando">

							<img *ngIf="ExibirFormularioEnviando"
								 src="{{global.Api_Imagem + 'portal/loading.gif'}}"
								 width="50"
								 alt="Enviando a mensagem">
							<br>
							<h3 *ngIf="ExibirFormularioEnviando">
								Estamos encaminhando seu relato para nossa equipe.
								Iremos analizar com carinho seu contato!
							</h3>
							<h3 *ngIf="!ExibirFormularioEnviando">
								<i class="ft-thumbs-up font-large-3 teal"></i>
								<br>
								Seus dados foram enviados com sucesso!
							</h3>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="busca-inicial" [ngStyle]="{background: 'url(' + global.Api_Imagem + 'portal/background-01.jpg)'}">
	<div class="background" [ngStyle]="{background: 'url(' + global.Api_Imagem + 'portal/pontos.png)'}">
		<div class="itens">
			<h1>Encontre imóveis aqui!</h1>
			<div class="busca-localizacao" *ngIf="buscaLocalizacao">
				<form autocomplete="off">
					<div class="card">
						<div class="card-content">
							<div class="campo operacao">
								<select class="form-control" id="cmp-busca-operacao"
										required
										name="operacao"
										(change)="onChangeOperacao()"
										[(ngModel)]="operacao"
										[ngModelOptions]="{standalone: true}">
									<option *ngFor="let valor of _operacaoOpcoes"
											[value]="valor.titulo">
										{{ valor.titulo }}
									</option>
								</select>
							</div>
							<div class="campo tipo">
								<select class="form-control" id="cmp-busca-tipo"
										required
										name="tipo"
										(change)="onChangeTipo()"
										[(ngModel)]="tipo"
										[ngModelOptions]="{standalone: true}">
									<option *ngFor="let valor of _tipoOpcoes"
											[value]="valor.titulo">
										{{ valor.titulo }}
									</option>
								</select>
							</div>
							<div class="campo localizacao">
								<input style="display:none" type="text" name="localizacao"/>
								<input type="text" class="form-control" placeholder="Bairro ou Cidade"
									   id="cmp-busca-localizacao" name="localizacao"  autocomplete="new-password" required
									   [(ngModel)]="localizacao"
									   #localizacaoRef
									   (focusout)="focusOut()"
									   (focus)="focus()"
									   [ngModelOptions]="{standalone: true}">
								<div class="sugestoes" *ngIf="exbir_localizacao">
									<ul>
										<h2 *ngIf="_sugestoesLocalizacaoCidades">Cidades</h2>
										<li *ngFor="let item of _sugestoesLocalizacaoCidades">
											<a href="/imoveis/{{operacao}}/{{tipo}}/{{item.cidade}}/{{item.estado}}?operacao={{operacao}}&tipo={{tipo}}&localizacao={{item.cidade}}/{{item.estado}}">
												{{item.cidade}}/{{item.estado}}
											</a>
										</li>

										<h2 *ngIf="_sugestoesLocalizacaoBairros">Bairros</h2>
										<li *ngFor="let item of _sugestoesLocalizacaoBairros">
											<a href="/imoveis/{{operacao}}/{{tipo}}/{{item.bairro}}-{{item.cidade}}/{{item.estado}}?operacao={{operacao}}&tipo={{tipo}}&localizacao={{item.bairro}}-{{item.cidade}}/{{item.estado}}">
												{{item.bairro}}, {{item.cidade}}/{{item.estado}}
											</a>
										</li>
									</ul>
								</div>
							</div>
							<div class="campo botao">
								<button type="button" (click)="buscar('localizacao')"
										class="btn azul">
									Buscar
								</button>
							</div>
						</div>
					</div>
					<div class="card botao">
						<div>
							<button type="button"
									(click)="buscarCodigo()"
									class="btn azul">
								Buscar por código
							</button>
						</div>
					</div>
				</form>
			</div>

			<div class="busca-codigo" *ngIf="buscaCodigo">
				<form >
					<div class="card">
						<div class="card-content">
							<div class="campo codigo">
								<input type="text" class="form-control" placeholder="Informe o código"
									   id="cmp-busca-codigo" [(ngModel)]="codigo" name="codigo" autocomplete="off" required>
							</div>
							<div class="campo botao text-center">
								<button type="button" (click)="buscar('codigo')"
										class="btn azul	">
									Buscar
								</button>
							</div>
						</div>
					</div>
					<div class="card botao">
						<div>
							<button type="button"
									(click)="buscarLocalizacao()"
									class="btn azul">
								<i class="fas fa-long-arrow-alt-left"></i> Buscar por localização
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!imobiliaria" class="loading-conteudo">
  <img src="{{ global.Api_Imagem + 'portal/loading.gif' }}" />
</div>
<div
  class="card"
  [ngClass]="localclass"
  *ngIf="imobiliaria"
  style="min-width: 200px"
>
  <img
    *ngIf="imobiliaria[0].proprietario == 0"
    src="{{ global.Api_Imagem + 'logo/' + imobiliaria[0].id }}.jpg"
    alt="{{ imobiliaria[0].nome }}"
  />

  <address>
    <h2>{{ imobiliaria[0].nome }}</h2>
    <h3 *ngIf="imobiliaria[0].proprietario == 0">
      CRECI: {{ imobiliaria[0].creci }}
    </h3>
    <div
      *ngIf="imobiliaria[0].proprietario == 1"
      style="
        background-color: #2196f3;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        border-radius: 10px;
        font-size: 0.675rem;
      "
    >
      Perfil Particular
    </div>

    <h4 *ngIf="!mobile && imobiliaria[0].proprietario == 0" class="titulo">
      <i class="far fa-map"></i>
      Endereço
      <small>
        <a
          href="https://www.google.com.br/maps/place/{{
            imobiliaria[0].endereco + ', ' + imobiliaria[0].numero
          }} - {{
            imobiliaria[0].bairro +
              ', ' +
              imobiliaria[0].cidade +
              '-' +
              imobiliaria[0].estado +
              ', ' +
              imobiliaria[0].cep
          }}/data=!3m1!4b1!4m5!3m4!1s0x94c89bcd66363f7f:0x97c4d28da5e6cc8a!8m2?shorturl=1"
          target="_blank"
          class="blue-grey darken-4"
          title="Ver a localização da {{ imobiliaria[0].nome }} no Mapa"
        >
          Ver no Mapa
        </a>
      </small>
    </h4>
    <p *ngIf="!mobile && imobiliaria[0].proprietario == 0">
      {{ imobiliaria[0].endereco + ", " + imobiliaria[0].numero }}
      <br />
      {{
        imobiliaria[0].bairro +
          " - " +
          imobiliaria[0].cidade +
          "/" +
          imobiliaria[0].estado
      }}
      <br />
      CEP: {{ imobiliaria[0].cep }}
    </p>

    <h4 *ngIf="!mobile" class="titulo">
      <i class="fas fa-phone"></i>
      Telefone
    </h4>
    <p *ngIf="!mobile && phone">
      <a
        href="tel:+55{{ imobiliaria[0].telefone }}"
        title="Ligue para {{ imobiliaria[0].nome }}"
      >
        {{ imobiliaria[0].telefone_formatado }}
      </a>
    </p>
    <div *ngIf="!mobile && !phone">
      <p>{{ imobiliaria[0].telefone_formatado.slice(0, 5) }}...</p>
      <button class="btn destaque" (click)="verTelefone()">Ver telefone</button>
    </div>

    <h4 *ngIf="!mobile" class="titulo">
      <i class="far fa-envelope-open"></i>
      E-mail
    </h4>
    <div *ngIf="!mobile && !mail">
      <p>{{ imobiliaria[0].email.slice(0, 5) }}...</p>
      <button class="btn destaque" (click)="verEmail()">Ver e-mail</button>
    </div>
    <p *ngIf="!mobile && mail">
      <a
        href="mailto:{{ imobiliaria[0].email }}"
        title="Envie um e-mail para {{ imobiliaria[0].nome }}"
      >
        {{ imobiliaria[0].email }}
      </a>
    </p>
    <a
      href="https://api.whatsapp.com/send?phone={{
        imobiliaria[0]['whatsapp']
      }}&text='Olá, tenho interesse no imóvel código {{ imovel['codigo'] }}, {{
        imovel['titulo']
      }}', encontrei ele no {{
        global.Titulo_Portal
      }}. Aguardo o contato. Obrigado. {{ this.global.Portal_Url }}/imovel/{{
        imovel['id']
      }}/{{ imovel['url'] }}';"
      class="btn whatsapp"
      target="_blank"
      title="Falar no whatsapp com a {{
        imovel['imobiliaria']['nome']
      }} sobre {{ imovel['titulo'] }}"
      *ngIf="imobiliaria[0]['whatsapp']"
      (click)="contatoWhatsapp(imovel['id'], imovel['imobiliaria']['slug'])"
    >
      Conversar no <i class="fab fa-whatsapp"></i>
    </a>
  </address>

  <div class="footer" *ngIf="localclass != 'lista'">
    <a
      href="/anunciante/{{ imobiliaria[0].slug }}"
      class="btn destaque"
      title="Veja todos os imóveis {{ imobiliaria[0].nome }}"
      >Todos os imóveis {{ imobiliaria[0].nome }}</a
    >
  </div>
  <div class="footer" *ngIf="mobile">
    <a
      (click)="exibirDados()"
      class="btn destaque"
      title="Exibir dados do anunciante {{ imobiliaria[0].nome }}"
      >Exibir dados</a
    >
  </div>
</div>

import { fromEvent as observableFromEvent } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { Globals } from '../../app.global';
import { RequestService } from '../../shared/services/request.service';
import { EmbedVideoService } from 'ngx-embed-video';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
var ImovelComponent = /** @class */ (function () {
    function ImovelComponent(route, title, meta, global, request, embedService, sanitizer, deviceService) {
        this.route = route;
        this.title = title;
        this.meta = meta;
        this.global = global;
        this.request = request;
        this.embedService = embedService;
        this.sanitizer = sanitizer;
        this.deviceService = deviceService;
        this.imovel_id = false;
        this._telefone = '';
        this.imovel_video = false;
        this.mobile = false;
        this.erro = false;
        this.opcao_compartilhar = [];
        this.ExibirDados = false;
        this.ExibirFormulario = true;
        this.ExibirFormularioEnviando = true;
        this.link_map = '';
        this.lat = 0;
        this.lon = 0;
        this.form = [];
        this.modal = 1;
        this.dadosAnuncianteTipo = 'imovel';
        if (this.deviceService.isMobile()) {
            this.mobile = true;
        }
    }
    Object.defineProperty(ImovelComponent.prototype, "telefone", {
        set: function (telefone) {
            this._telefone = telefone;
        },
        enumerable: true,
        configurable: true
    });
    ImovelComponent.prototype.abrirCompatilhe = function (i) {
        if (this.opcao_compartilhar[i]) {
            this.opcao_compartilhar[i] = false;
        }
        else {
            this.opcao_compartilhar[i] = true;
        }
    };
    ImovelComponent.prototype.sanitize = function (url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    };
    ImovelComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            observableFromEvent(_this.telefoneRef.nativeElement, 'keyup').pipe(map(function (evt) { return evt.target.value; }), distinctUntilChanged())
                .subscribe(function (text) {
                _this._telefone = text;
            });
            _this.loadMapa();
        }, 1500);
        this.loadImovel();
    };
    /*
        load() {
            //VERIFICA OS PARAMETROS DA URL
            this.route.params.subscribe(params => {
                if (params['id']) {
                    this.id = params['id'];
                }
            });

            //CARREGA O IMOVEL
            if (this.id) {
                this.loadImovel();
                this.currentRoute =  window.location.href;
            }else{
                this.erro = true;
            }
        }
    */
    ImovelComponent.prototype.loadImovel = function () {
        var _this = this;
        /*VERIFICA OS PARAMETROS DA URL*/
        this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.id = params['id'];
                _this.request.get('/imoveis/detalhe?id=' + _this.id)
                    .then(function (res) {
                    if (res.status == 1) {
                        _this.imovel = res.result;
                        _this.request.estatistica('imovel', 'acesso', _this.imovel.imobiliaria.slug, _this.imovel.id);
                        if (_this.imovel.video) {
                            _this.imovel_video = _this.embedService.embed_youtube(_this.imovel.video, {
                                attr: {
                                    width: '100%',
                                    height: '450'
                                }
                            });
                        }
                        _this.loadFormulario();
                        _this.seo();
                    }
                    else {
                        _this.erro = true;
                    }
                }, function (err) { return err; });
                _this.currentRoute = window.location.href;
            }
            else {
                _this.erro = true;
            }
        });
    };
    ImovelComponent.prototype.loadFormulario = function () {
        this.aviso = false;
        this.form.aviseme = true;
        this.form.nome = '';
        this.form.telefone = '';
        this.form.email = '';
        this.form.mensagem = '';
        this.ExibirFormulario = true;
        this.ExibirFormularioEnviando = true;
        /*this.request.estatistica('conta', 'acesso', this.imovel.imobiliaria.slug);*/
        this.form.mensagem = 'Olá, tenho interesse no imóvel código ' + this.imovel.codigo + ', ' + this.imovel.titulo +
            ', encontrei ele no ' + this.global.Titulo_Portal + '. Aguardo o contato. Obrigado.';
        this.ExibirDados = true;
    };
    ImovelComponent.prototype.loadMapa = function () {
        if (this.imovel.maps) {
            var map_1;
            var circle = void 0;
            this.lat = this.imovel.maps.latitude + 0.0080031;
            this.lon = this.imovel.maps.longitude + 0.0060031;
            this.link_map = 'https://www.google.com/maps/place/@' + this.lat + ',' + this.lon;
            var marcar = { lat: this.lat, lng: this.lon };
            map_1 = new google.maps.Map(document.getElementById('map'), {
                center: marcar,
                zoom: 14
            });
            circle = new google.maps.Circle({
                strokeColor: '#0a75ca',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#0092ff',
                fillOpacity: 0.35,
                map: map_1,
                center: new google.maps.LatLng(marcar),
                radius: 1000
            });
        }
    };
    ImovelComponent.prototype.onSubmit = function () {
        var _this = this;
        var aviseme = 0;
        var nome = 0;
        var email = 0;
        var telefone = 0;
        var mensagem = 0;
        var origem = 'portal';
        var url = '/imovel/' + this.imovel.id + '/' + this.imovel.url;
        var imagem = this.global.Api_Imagem + 'imoveis/imovel-sem-foto.jpg';
        if (this.imovel.imagem) {
            imagem = this.global.Api_Imagem + 'imoveis/' + this.imovel.imobiliaria.id + '/' + this.imovel.imagem[0].name;
        }
        if (this.form.aviseme) {
            aviseme = this.form.aviseme;
        }
        if (this.form.nome) {
            nome = this.form.nome;
        }
        if (this._telefone) {
            telefone = this._telefone;
        }
        if (this.form.email) {
            email = this.form.email;
        }
        if (this.form.mensagem) {
            mensagem = this.form.mensagem;
        }
        if (this.form.aviseme) {
            aviseme = this.form.aviseme;
        }
        this.ExibirFormulario = false;
        this.ExibirFormularioEnviando = true;
        this.request.get('/Envio/enviar?origem=' + origem + '&imagem=' + imagem + '&imovel=' + this.imovel.id + '&imobiliaria=' +
            this.imovel.imobiliaria.id + '&url=' + url + '&nome=' + nome + '&telefone=' + telefone + '&email=' + email + '&mensagem=' +
            mensagem + '&editar-aviseme=' + aviseme)
            .then(function (res) {
            if (res && res.status) {
                _this.ExibirFormularioEnviando = false;
            }
            else {
                return false;
            }
        }, function (err) { return err; });
    };
    ImovelComponent.prototype.relatarProblema = function (imovel_id) {
        this.modal = this.modal + 1;
        this.imovel_id = imovel_id;
    };
    ImovelComponent.prototype.contatoWhatsapp = function (imovel_id, slug) {
        console.log(imovel_id);
        console.log(slug);
        this.request.estatistica('whatsapp', 'acesso', slug, imovel_id);
    };
    ImovelComponent.prototype.seo = function () {
        /*CARREGA AS TAGS PARA A PAGINA*/
        this.title.setTitle(this.imovel.titulo + ', ' + this.imovel.imobiliaria['nome'] + ' - ' + this.global.Titulo_Portal);
        this.meta.addTag({
            name: 'description',
            content: this.imovel.titulo + ' da imobiliária ' + this.imovel.imobiliaria['nome'] + ', ' + this.imovel.description +
                ' - ' + this.global.Titulo_Portal
        });
        this.meta.addTag({
            name: 'keywords',
            content: this.imovel.keywords + ', ' + this.global.Titulo_Portal
        });
        this.meta.addTag({
            name: 'author',
            content: this.global.Titulo_Portal
        });
    };
    return ImovelComponent;
}());
export { ImovelComponent };

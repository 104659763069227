import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title, Meta} from '@angular/platform-browser';
import {Globals} from "../../app.global";
import {RequestService} from "../../shared/services/request.service";
import {PaginationService} from "../../shared/services/pagination.service";

@Component({
	templateUrl: './anunciante.component.html',
	styleUrls: ['./anunciante.component.scss']
})
export class AnuncianteComponent {
	pagina: number = 1;
	total_paginas: number;
	total_imoveis: number;
	slug: any;
	imovel_id: any;
	imobiliaria_id: any;
	imobiliaria: any;
	imoveis: any;
	titulo_modal: any;
	ExibirDados: boolean = true;
	erro: boolean = false;
	public dadosAnuncianteTipo: string = 'imovel';

	// array of all items to be paged
	private allItems: number;

	// pager object
	pager: any = {};

	constructor(private route: ActivatedRoute, private title: Title, private meta: Meta, public global: Globals,
				public request: RequestService, public pagination: PaginationService) {
		this.load();
		this.dadosAnuncianteTipo = 'lista';
	}

	load() {
		//VERIFICA PRIMEIRO SE EXISTE ALGUM PARAMETRO CIDADE E ESTADO
		this.route.params.subscribe(params => {
			if (params['slug']) {
				this.slug = params['slug'];
			}
			if (params['pagina']) {
				this.pagina = params['pagina'];
			}
		});

		//CARREGAS AS IMOBILIARIAS
		this.request.get('/Contas?slug=' + this.slug)
			.then(res => {
					if (res) {
						this.imobiliaria = res.result;
						//	this.request.estatistica('conta', 'acesso', this.slug);
						this.loadImoveis();
						this.seo();
					} else {
						this.erro = true;
					}
				},
				err => err);
	}

	loadImoveis() {
		this.imoveis = false;
		this.request.get('/imoveis?imobiliaria=' + this.imobiliaria[0].id + '&pagina=' + this.pagina)
			.then(res => {
					if (res) {
						this.imoveis = res.result;
						this.total_imoveis = res.sum;
						this.total_paginas = res.paginas;

						// set items to json response
						this.allItems = res.sum;
						// initialize to page 1
						this.setPage(this.pagina);
					} else {
						this.erro = true;
					}
				},
				err => err);
	}

	setPage(page: number) {
		this.pagina = page;
		// get pager object from service
		this.pager = this.pagination.getPager(this.allItems, page);
		// get current page of items
		//this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
	}

	seo() {
		//CARREGA AS TAGS PARA A PAGINA
		this.title.setTitle(this.imobiliaria[0]['nome'] + ' - ' + this.global.Titulo_Portal);
		this.meta.addTag({
			name: 'description',
			content: 'Informações da ' + this.imobiliaria[0]['nome'] + ', telefone, e-mail, site, redes sociais, e seus imóveis. A Tráfego Imóveis não é uma imobiliária, é um portal imobiliário que encontra para você milhares de oportunidades em imóveis, seja para comprar ou alugar.'
		});
		this.meta.addTag({
			name: 'keywords',
			content: this.imobiliaria[0]['nome'] + ', Imóveis à venda e Imóveis para alugar. Na Tráfego Imóveis você encontra casas e apartamentos novos e usados para compra, venda ou aluguel em  Americana, Santa Bárbara d\'Oeste, Sumaré, Nova Odessa. Anuncie seu imóvel!'
		});
		this.meta.addTag({
			name: 'author',
			content: 'Tráfego Integrada'
		});
	}

}

<app-header></app-header>

<main>
    <h1>Fale com o {{global.Titulo_Portal}}</h1>

    <section>
        <div class="card">
            <div class="contato">
                <div class="dados">
                    <h2>FALE CONOSCO</h2>
                    <p>
                        <a href="tel:+551933877547" title="Ligue agora para o {{global.Titulo_Portal}}">
                            (019) 3387-7547
                        </a>
                        <br>
                        Av. Nove de Julho, 508, Sala 07
                        <br>
                        São Domingos - Americana/SP
                        <br>
                        CEP 13471-140
                        <br>
                        <a href="mailto:falecom@trafegoimoveis.com.br"
                           title="Envie um e-mail para o {{global.Titulo_Portal}}">
                            falecom@trafegoimoveis.com.br
                        </a>
                    </p>

                    <p>
                        Razão Social: Trafego Acesso A Internet EIRELI
                        <br>
                        CNPJ: 08.334.297/0001-33
                    </p>

                    <div #map id="map" class="google-map"></div>

                </div>
                <div class="formulario">
                    <h2>Preencha o formulário abaixo e nossa equipe entrará em contato!</h2>
                    <form (ngSubmit)="onSubmit()" #testForm="ngForm" ngNativeValidate>
                        <div *ngIf="ExibirFormulario">

                            <label for="cmp-anuncie-nome">Seu nome</label>
                            <div class="campo">
                                <input type="text" id="cmp-anuncie-nome" placeholder="Seu nome" required
                                       [(ngModel)]="nome" [ngModelOptions]="{standalone: true}">
                            </div>

                            <label for="cmp-anuncie-email">Seu e-mail</label>
                            <div class="campo">
                                <input type="text" placeholder="E-mail"
                                       pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required
                                       id="cmp-anuncie-email" name="email" [(ngModel)]="email"
                                       [ngModelOptions]="{standalone: true}"
                                       #emailref="ngModel">
                            </div>
                            <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)">
                                <p [hidden]="!emailref.errors?.pattern">Informe um e-mail válido</p>
                            </div>

                            <label for="cmp-anuncie-telefone">Seu telefone</label>
                            <div class="campo">
                                <input type="text" placeholder="Telefone" [brmasker]="{phone: true}" #telefoneRef
                                       id="cmp-anuncie-telefone" required #telefoneref="ngModel" [(ngModel)]="_telefone"
                                       [ngModelOptions]="{standalone: true}">
                            </div>
                            <div *ngIf="telefoneref.errors &&(telefoneref.touched || telefoneref.dirty)">
                                <p [hidden]="!telefoneref.errors?.pattern">Informe um telefone válido</p>
                            </div>

                            <label for="cmp-anuncie-telefone">Sua mensagem</label>
                            <div class="campo">
								<textarea required [(ngModel)]="mensagem" [ngModelOptions]="{standalone: true}"
                                          id="cmp-opniao-mensagem" name="mensagem"
                                          placeholder="Deixe aqui sua mensagem, é muito importante para nós"
                                          rows="6"></textarea>
                            </div>

                            <button type="submit" class="btn azul" [disabled]="!testForm.form.valid">Enviar mensagem!
                            </button>
                        </div>
                    </form>

                    <div *ngIf="ExibirFormularioEnviando" class="loading-conteudo" style="color: #fff !important;">
                        <img src="{{global.Api_Imagem + 'portal/loading.gif'}}"
                             class="text-center"
                             alt="">
                        <br>
                        <h3 style="color: #fff !important;">
                            Estamos enviando sua mensagem nesse momento!
                        </h3>
                    </div>
                    <div *ngIf="ExibirFormularioEnviado" class="loading-conteudo">

                        <h3 class="teal p-2" style="color: #fff !important;">
                            <i class="ft-thumbs-up font-large-3 teal" style="color: #fff !important;"></i>
                            <br>
                            Obrigado, iremos avaliar sua mensagem e entraremos em contato o mais breve possível!
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <app-chamada-anuncie></app-chamada-anuncie>
</main>

<app-footer></app-footer>

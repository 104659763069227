import {Component} from '@angular/core';
import {Globals} from "../../app.global";

@Component({
	selector: 'app-chamada-anuncie',
	templateUrl: './chamada-anuncie.component.html',
	styleUrls: ['./chamada-anuncie.component.scss']
})
export class ChamadaAnuncieComponent {

	constructor(public global: Globals) {
	}
}

import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Globals} from '../../app.global';
import {RequestService} from '../../shared/services/request.service';


@Component({
  selector: 'app-modal-aviseme',
  templateUrl: './aviseme.component.html',
  styleUrls: ['./aviseme.component.scss']
})
export class AvisemeComponent implements OnChanges {
  @Input() abrir;
  @Input() tipo;
  @Input() localidade;
  @Input() valor_max;
  @Input() valor_min;
  @Input() area_min;
  @Input() area_max;

  public filtros: any = {
    operacao: '',
    tipo: '',
    localizacao: '',
    valor_min: '',
    valor_max: '',
    area_max: '',
    area_min: ''
  };
  imobiliaria: any;
  imovel: any;
  aviso: any;
  ExibirFormulario = true;
  ExibirFormularioEnviando = false;
  ExibirFormularioEnviado = false;

  public _valor_min: any = this.valor_min;
  public _valor_max: any = this.valor_max;
  public _area_max: any = this.area_max;
  public _area_min: any = this.area_min;
  public abrir_modal = false;
  public abrir_cont = 0;

  form: any = [];

  @ViewChild('localizacaoRef') localizacaoRef: ElementRef;

  @Input()
  set localizacao(localizacao: string) {
    this.filtros.localizacao = localizacao;
  }

  @Input()
  set operacao(operacao: string) {
    this.filtros.operacao = operacao;
  }

  constructor(public request: RequestService, public global: Globals) {
    this.aviso = false;
    this.form.nome = '';
    this.form.email = '';

    this.form.tipo = '';
    this.form.localizacao = '';
    this.form.valor_max = '';
    this.form.valor_min = '';
    this.form.area_max = '';
    this.form.area_min = '';

    this.ExibirFormulario = true;
    this.ExibirFormularioEnviando = false;
    this.ExibirFormularioEnviado = false;

  }

  ngOnChanges(changes: SimpleChanges) {
    this.ExibirFormularioEnviado = false;
    this.ExibirFormulario = true;
    this.ExibirFormularioEnviando = false;

    this._valor_min = this.valor_min;
    this._valor_max = this.valor_max;
    this._area_max = this.area_max;
    this._area_min = this.area_min;

    this.filtros.localizacao = this.localidade;
    this.filtros.tipo = this.tipo;

    if (this.abrir && this.abrir_cont !== this.abrir) {
      this.abrir_cont = this.abrir;
      this.abrir_modal = true;
    }
  }

  fechar() {
    this.abrir_modal = false;
  }

  onSubmit() {

    let nome = '';
    let email = '';
    let operacao = '';
    let tipo = '';
    let localidade = '';
    let valor_max = '';
    let valor_min = '';
    let area_max = '';
    let area_min = '';

    if (this.form.nome) {
      nome = this.form.nome;
    }
    if (this.form.email) {
      email = this.form.email;
    }
    if (this.form.operacao_venda) {
      if (operacao != '') {
        operacao += ',';
      }
      operacao += 'Venda';
    }
    if (this.form.operacao_locacao) {
      if (operacao != '') {
        operacao += ',';
      }
      operacao += 'Locação';
    }
    if (this.form.operacao_temporada) {
      if (operacao != '') {
        operacao += ',';
      }
      operacao += 'Temporada';
    }
    if (this.filtros.tipo) {
      tipo = this.filtros.tipo;
    }
    if (this.filtros.localizacao) {
      localidade = this.filtros.localizacao;
    }
    if (this._valor_max) {
      valor_max = this._valor_max;
    }
    if (this._valor_min) {
      valor_min = this._valor_min;
    }
    if (this._area_max) {
      area_max = this._area_max;
    }
    if (this._area_min) {
      area_min = this._area_min;
    }
    console.log(nome);
    console.log(email);
    console.log(operacao);
    console.log(tipo);
    console.log(localidade);

    if (nome && email && this.filtros.operacao && this.filtros.tipo && this.filtros.localizacao) {

      this.ExibirFormularioEnviado = false;
      this.ExibirFormulario = false;
      this.ExibirFormularioEnviando = true;

      this.request.get('/Aviseme/criar?operacao=' + this.filtros.operacao + '&tipo=' + this.filtros.tipo + '&localidade=' + this.filtros.localizacao + '&valor_max=' + valor_max +
        '&valor_min=' + valor_min + '&area_max=' + area_max + '&area_min=' + area_min + '&nome=' + nome + '&email=' + email)
        .then(res => {
            if (res && res.status) {
              this.ExibirFormularioEnviando = false;
              this.ExibirFormularioEnviado = true;
            } else {
              return false;
            }
          },
          err => err);
    } else {
      this.aviso = true;
    }
  }

  ngOnInit() {


    /*
            this.getTipo();
            this.setFiltos();
            */
  }

  /*
          ngAfterViewInit() {
              Observable.fromEvent(this.localizacaoRef.nativeElement, 'keyup')
                  .map((evt: any) => evt.target.value)
                  .debounceTime(400)
                  .distinctUntilChanged()
                  .subscribe((text: string) => {
                      this._localizacao = text;
                      this.defineLocalizacao();
                      this.exbir_localizacao = true;
                  });
          }

          focus() {
              this.exbir_localizacao = true;
          }

          timeout(ms) {
              return new Promise(resolve => setTimeout(resolve, ms));
          }

          focusOut() {
              let timestampDiffs = [1];
              (async () => {
                  for (let item of timestampDiffs) {
                      await this.timeout(item * 1000);
                      this.exbir_localizacao = false;
                  }
              })();
          }

          onChangeLocalizacao(index, tipo) {
              if (tipo == 'b') {
                  var local = this._sugestoesLocalizacaoBairros[index].bairro + '-' + this._sugestoesLocalizacaoBairros[index].cidade + '/' + this._sugestoesLocalizacaoBairros[index].estado;
              } else {
                  var local = this._sugestoesLocalizacaoCidades[index].cidade + '/' + this._sugestoesLocalizacaoCidades[index].estado;
              }
              var novaLocalizacao = local;
              var dados = this.filtros.localizacao.split(',');
              dados.forEach(function (value) {
                  if (value != local && value != '') {
                      novaLocalizacao += ',' + value;
                  }
              });
              this.filtros.localizacao = novaLocalizacao;
              this.defineLocalizacao();
              this.setFiltos();
          }

          removeLocalizacao(item) {
              var dados = this.filtros.localizacao.split(',');
              var novoTipo = '';
              dados.forEach(function (value) {
                  console.log(value);
                  if (value != item) {
                      if (novoTipo != '') {
                          novoTipo += ',' + value;
                      } else {
                          novoTipo = value;
                      }
                  }
              });
              this.filtros.localizacao = novoTipo;
              this.defineLocalizacao();
              this.setFiltos();
          }

          defineLocalizacao() {
              this.request.get('/Buscas/localizacao?localizacao=' + this._localizacao)
                  .then(res => {
                          this._sugestoesLocalizacaoCidades = res.result.cidades;
                          this._sugestoesLocalizacaoBairros = res.result.bairros;
                      },
                      err => err);
          }

          setFiltos() {

              //Define os filtros do tipo
              var separa = this.filtros.tipo.split(',');
              var novaLista = [];
              separa.forEach(function (value) {
                  if (value != ',' && value != '') {
                      novaLista.push(value);
                  }
              });
              this.filtrosTipo = novaLista


              //Define os filtros da localizacao
              var separa = this.filtros.localizacao.split(',');
              var novaLista = [];
              separa.forEach(function (value) {
                  if (value != ',' && value != '') {
                      novaLista.push(value);
                  }
              });
              this.filtrosLocalizacao = novaLista
          }

          getTipo() {
              this.request.get('/Buscas/Tipo?operacao=' + this.filtros.operacao)
                  .then(res => {
                          if (res.status == 1) {
                              this._tipoOpcoes = res.result;
                          } else {
                              this._tipoOpcoes[0] = 'Erro ao carregar';
                          }
                      },
                      err => err);
          }

          onChangeTipo() {
              var tipo = this.tipo;
              var novoTipo = tipo;
              var dados = this.filtros.tipo.split(',');
              dados.forEach(function (value) {
                  if (value != tipo) {
                      novoTipo += ',' + value;
                  }
              });
              this.filtros.tipo = novoTipo;
              this.tipo = '';
              this.setFiltos();
              this.getTipo();
          }

          removeTipo(item) {
              var dados = this.filtros.tipo.split(',');
              var novoTipo = '';
              dados.forEach(function (value) {
                  if (value != item) {
                      if (novoTipo != '') {
                          novoTipo += ',' + value;
                      } else {
                          novoTipo = value;
                      }
                  }
              });
              this.filtros.tipo = novoTipo;
              this.setFiltos();
              this.defineLocalizacao();
          }

          */
}

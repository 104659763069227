import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title, Meta} from '@angular/platform-browser';
import {Globals} from '../../app.global';
import {RequestService} from '../../shared/services/request.service';

@Component({
  templateUrl: './gerenciar-aviseme.component.html',
  styleUrls: ['./gerenciar-aviseme.component.scss']
})
export class GerenciarAvisemeComponent {

  public chave: any = '';
  public token: any = '';

  public email: any = '';
  public token_selecionado: any = '';
  public modal: any = 1;

  constructor(private route: ActivatedRoute, private title: Title, private meta: Meta, public global: Globals,
              public request: RequestService) {

    title.setTitle('Excluir alerta de imóveis' + ' - ' + this.global.Titulo_Portal);
    meta.addTag({
      name: 'description',
      content: 'Não encontrou o que procura? Cadastre seus interesses que encontraremos pra você! Crie um alerta no ' + this.global.Titulo_Portal
    });
    meta.addTag({
      name: 'keywords',
      content: 'criar alerta, cadastre seus interesses, encontraremos seu imóvel, avise-me, cadastrar'
    });
    meta.addTag({
      name: 'author',
      content: 'Tráfego Integrada'
    });

    this.listaAlerta();

  }

  listaAlerta() {

    /*VERIFICA OS PARAMETROS DA URL*/
    this.route.params.subscribe(params => {
      if (params['chave']) {
        this.request.get('/aviseme/listarAlerta?id=' + params['chave'])
          .then(res => {
              if (res.status == 1) {
                this.token = res.result;
              } else {
                this.token = [];
              }
            },
            err => err);
      }
    });
  }

  inativarAlerta(token, email) {
    this.modal = this.modal + 1;
    this.token_selecionado = token;
    this.email = email;
  }

  ativarAlerta(token, email) {

    this.request.get('/Aviseme/ativar?token=' + token + '&email=' + email)
      .then(res => {
        },
        err => err);

    this.listaAlerta();
  }

}

import {Component, Input} from '@angular/core';
import {RequestService} from '../../shared/services/request.service';
import {Globals} from '../../app.global';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-lista-imoveis',
    templateUrl: './listaImoveis.component.html',
    styleUrls: ['./listaImoveis.component.scss']
})
export class ListaImoveisComponent {
    @Input() imoveis: false;
    @Input() imobiliaria_id: false;
    imovel_id: false;
    opcao_compartilhar = [];
    imovel_vizualizado = [];
    public modal: any = 1;

    constructor(public request: RequestService, public global: Globals, private sanitizer: DomSanitizer) {

    }

    gerarEstatistica(imovel_id, slug) {
        if (!this.imovel_vizualizado[imovel_id]) {
            this.request.estatistica('imovel', 'acesso', slug, imovel_id);
            this.imovel_vizualizado[imovel_id] = true;
        }
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    abrirCompatilhe(i) {
        if (this.opcao_compartilhar[i]) {
            this.opcao_compartilhar[i] = false;
        } else {
            this.opcao_compartilhar[i] = true;
        }
    }

    entrarContato(imovel_id) {
        this.modal = this.modal + 1;
        this.imovel_id = imovel_id;
    }

    contatoWhatsapp(imovel_id, slug) {
        this.request.estatistica('whatsapp', 'acesso', slug, imovel_id);
    }
}

<app-header></app-header>

<main>
  <h1>Termos de Uso do {{ global.Titulo_Portal }}</h1>
  <section>
    <div class="card">
      <div class="sobre">
        <img
          src="{{ global.Api_Imagem + 'portal/img-termos-de-uso.jpg' }}"
          alt="Imagem ilustrativa Termos de Uso do Portal Imobiliario Tráfego Imóveis"
        />
        <div class="conteudo">
          <h1>Declaração dos Termos de Uso</h1>
          <p>
            Os Termos de Uso dos nossos sites, aplicativos, serviços e sistemas
            poderão ser atualizados com regularidade, sem aviso prévio e, sempre
            que realizarmos alterações, estas serão publicadas em nossos sites.
            Você entende e aceita que deverá rever estes Termos de Uso
            periodicamente, e que ao usar e acessar a Tráfego Integrada Você
            está aceitando os termos que estiverem em vigor.
          </p>
          <p><b>Última atualização:</b> 20/07/2021.</p>

          <p>
            Nesta declaração explicamos as responsabilidades, deveres e
            obrigações que Você assume ao acessar ou utilizar os sites,
            aplicativos e serviços de <b>Tráfego Integrada</b>.
          </p>

          <p>
            Caso não esteja de acordo com os Termos de Uso, por favor, abandone
            o site, aplicativo, serviço ou sistema e abstenha-se de utilizá-los.
            A utilização, pelo usuário, será entendida como aceitação plena e
            sem reservas dos Termos de Uso aqui estabelecidos.
          </p>

          Definições
          <p>
            Para os fins desta Política de Privacidade, as seguintes definições
            serão adotadas:
          </p>

          <p>
            <b>Anunciantes:</b> são todas as pessoas que se cadastram na Tráfego
            Integrada e que concluem o cadastro de anúncio de um imóvel.
          </p>

          <p>
            <b>Política de Privacidade:</b> são os termos contidos neste
            documento que tratam sobre a privacidade.
          </p>

          <p>
            <b>Usuários:</b> são todas as pessoas que acessam ou utilizam os
            sites, aplicativos, serviços e sistemas da Tráfego Integrada,
            independentemente de se cadastrarem e receberem uma identificação
            individual e exclusiva, ou não.
          </p>

          <p>
            Produto/Serviços: todos os recursos oferecidos pela Tráfego
            Integrada como sites, aplicativos, sistemas.
          </p>

          <p>
            Canal de Comunicação: são os meios utilizados (chat, formulários,
            e-mails, WhatsApp, ligações telefônicas, entre outros) para
            intermediar o contato entre os Anunciante(s), a Tráfego Integrada e
            Você.
          </p>

          <p>
            <b>Conteúdo:</b> toda informação, texto, mensagens, imagens, fotos,
            ilustrações, design, ícones, clipes de vídeo, marcas, software,
            propaganda, arquitetura funcional e outro conteúdo ou materiais
          </p>

          <p>
            <b>Cadastrar/Transmitir:</b> todo Conteúdo encontrado na Tráfego
            Integrada, sendo este disponibilizado pela Tráfego Integrada,
            Usuários, terceiros, entre outros.
          </p>

          <p>
            <b>Tráfego Integrada:</b> é a empresa proprietária e responsável
            pelos sites, aplicativos, serviços e sistemas das marcas descritas
            na seção “Nosso grupo”.
          </p>

          <p>
            <b>Você:</b> são os usuários, clientes ou anunciantes, dependendo do
            contexto em que esta definição é utilizada.
          </p>

          <p>
            Outras definições encontradas ao longo deste documento estarão
            devidamente identificadas.
          </p>

          <h2>Nosso grupo</h2>

          <p>
            <b>Ativix</b> <br />
            Sistema de gerenciamento de atividades, equipe, clientes,
            recebimentos e pagamentos.<br />
            https://sistema.ativix.com.br/
          </p>

          <p>
            <b>Gerenciador de Imobiliárias</b><br />
            Painel de gerenciamento de 2ª via de boletos e extratos,
            desenvolvido em parceria com a empresa JBI Sistemas e Consultoria
            Ltda.<br />

            https://gerenciadordeimobiliarias.com.br/
          </p>

          <p>
            <b>Tráfego Imóveis | Portal Imobiliário</b><br />
            Portal de anúncios de imóveis.<br />
            https://trafegoimoveis.com.br/
          </p>

          <p>
            <b>Tráfego Soluções Integradas</b><br />
            Agência de soluções para marketing digital e desenvolvimento de
            sistemas.<br />
            https://trafegointegrada.com.br/
          </p>

          <h2>Acesso e Utilização</h2>
          <p>
            A Tráfego Integrada possibilita que Você utilize e tenha acesso aos
            anúncios de diversos Anunciantes, informações e recursos de nossos
            sites, aplicativos, serviços e sistemas, tornando Você responsável
            civil e criminalmente pelas informações e dados fornecidos.
          </p>
          <p>
            Você compromete-se a utilizar nossos sites, aplicativos, serviços e
            sistemas em conformidade com estes Termos de Uso, e com a moral e os
            bons costumes.
          </p>
          <p>
            A Tráfego Integrada fornece a seu(s) Anunciante(s) um espaço para
            veiculação de anúncios, sendo assim um mero veículo de exposição
            destes anúncios, não atuando como prestador de serviços, de
            consultoria, intermediação comercial, agenciamento, ou participante
            direto ou indireto de quaisquer negócios ou transações eventualmente
            efetuadas entre Você e o(s) Anunciante(s).
          </p>
          <p>
            A veracidade dos anúncios que são veiculados na Tráfego Integrada é
            de inteira responsabilidade do(s) Anunciante(s). Desta forma Você
            está ciente que a Tráfego Integrada não está fazendo uma oferta,
            prometendo vender ou alugar um produto ou serviço específico por um
            preço específico, ou garantindo que qualquer Anunciante venda ou
            alugue seu produto ou serviço sob quaisquer termos específicos.
          </p>
          <p>
            É de extrema importância que Você verifique e confirme a veracidade
            e confiabilidade destes serviços ou oferta de terceiros, a Tráfego
            Integrada não dá qualquer garantia a respeito dos serviços de
            terceiros.
          </p>
          <p>
            É sua responsabilidade sempre realizar transações assistidas por um
            corretor imobiliário, verificar se este corretor possui um registro
            ativo no CRECI; conferir a situação do imóvel perante o Registro de
            Imóveis se está regular, sua existência e estado, assim como
            verificar a veracidade e procedência dos anúncios e Anunciantes.
          </p>
          <p>
            Você está ciente de que as informações contidas na Tráfego Integrada
            poderão conter erros tipográficos ou erros de outras natureza. A
            verificação e ajustes de tais informações são realizados
            periodicamente.
          </p>
          <h2>Conteúdo</h2>
          <p>
            Todo o Conteúdo da Tráfego Integrada incluindo, mas não se limitando
            a, toda informação, texto, mensagens, imagens, fotos, ilustrações,
            design, ícones, clipes de vídeo, marcas, software, propaganda,
            arquitetura funcional e outro conteúdo ou materiais (doravante,
            “Conteúdo”) é de propriedade e/ou posse da Tráfego Integrada ou seus
            usuários, salvo indicação em contrário. Incluindo, mas sem
            limitação, as leis sobre direitos autorais, marcas, patentes, entre
            outros Conteúdos que estejam protegidos pelas leis vigentes
            aplicáveis.
          </p>
          <p>
            Ao Transmitir Conteúdo a Tráfego Integrada, Você está ciente e
            afirma que tem o direito de ceder e transferir a Tráfego Integrada,
            uma licença irrevogável, perpétua, não exclusiva, gratuita e mundial
            para que a Tráfego Integrada possa usar, copiar, exibir e distribuir
            o Conteúdo, assim como desenvolver trabalhos derivados, ou
            incorporar em outros trabalhos ao Conteúdo.
          </p>
          <p>
            Você está ciente de que a Tráfego Integrada possui o direito e de
            acordo com seus próprios critérios, recusar, modificar ou apagar
            qualquer Conteúdo da Tráfego Integrada, por qualquer razão.
          </p>
          <p>
            A partir do momento em que Você Cadastra ou Transmite um Conteúdo na
            Tráfego Integrada, Você está ciente de que será de sua exclusiva
            responsabilidade fornecer informações verdadeiras, precisas e
            completas.
          </p>
          <p>
            Ao disponibilizar Conteúdo na Tráfego Integrada, Você afirma e
            garante que possui todos os direitos, ou autorização por escrito dos
            seus respectivos proprietários, sobre as informações, materiais e
            dados que Transmitir para a Tráfego Integrada.
          </p>
          <h2>Canal(is) de Comunicação</h2>
          <p>
            Ao utilizar um Canal de Comunicação Você consente, de forma livre e
            inequívoca, com a captura, armazenamento, tratamento e utilização de
            seus dados pessoais e dados sensíveis pelas empresas da Tráfego
            Integrada e Anunciantes, a fim de desenvolver nossa base de dados;
            para aprimorar a experiência de nossos clientes e usuários; visando
            a melhoria de nossos produtos e/ou serviços ou, ainda; para criação
            de novos produtos desenvolvidos a partir de tais dados.
          </p>
          <p>
            O conteúdo fornecido por Você por meio dos Canais de Comunicação é
            de sua única e exclusiva responsabilidade. A Tráfego Integrada não
            garante a veracidade dos dados pessoais e/ou conteúdos fornecidos,
            efetuados e/ou publicados nos Canais de Comunicação por Você. Você
            aceita voluntariamente que o acesso e/ou o uso dos Canais de
            Comunicação ocorre, em qualquer caso, sob sua única e exclusiva
            responsabilidade.
          </p>
          <p>
            A Tráfego Integrada não é, em nenhum caso, responsável pela
            destruição, alteração ou eliminação do conteúdo ou informação que
            Você incluir nos Canais de Comunicação.
          </p>
          <p>
            Você é único e exclusivo responsável por suas manifestações,
            menções, opiniões e qualquer ato que realizar por meio dos Canais de
            Comunicação.
          </p>
          <h2>Limitações e Uso Adequado</h2>
          <p>
            Ao acessar e/ou utilizar os produtos e serviços da Tráfego
            Integrada, Você concorda expressamente que apenas os utilizará para
            propósitos que são permitidos por estes Termos de Uso e pela
            legislação vigente, e também com a moral e bons costumes. Entre
            outros fatores, Você concorda que não poderá:
          </p>
          <p>
            Efetuar cadastro utilizando-se de informações falsas ou de
            propriedade de terceiros; assim como enviar ou transmitir
            informações de terceiros, ou de qualquer forma alterar, remover ou
            corromper tais informações;
          </p>
          <p>
            Copiar, alterar, licenciar, distribuir, exibir, reproduzir,
            publicar, criar trabalhos derivados, utilizar o conteúdo da Tráfego
            Integrada em outro website ou webpage, transferir ou vender qualquer
            informação ou dados da Tráfego Integrada; exceto com autorização
            expressa por escrito da Tráfego Integrada,
          </p>
          <p>
            Enviar ou transmitir quaisquer informações que possam induzir outros
            a erro; incitem a violência ou intolerância de qualquer natureza;
            ofensivas ou que contrariem a lei e/ou a ordem pública;
          </p>
          <p>
            Tentar impedir ou utilizar qualquer mecanismo que interfira na
            funcionalidade dos produtos ou serviços da Tráfego Integrada, assim
            como enviar ou transmitir códigos maliciosos, vírus eletrônicos que
            venham a expor informações da Tráfego Integrada, de terceiros ou
            causem qualquer tipo de dano aos equipamentos do destinatário;
          </p>
          <p>
            Acessar ou tentar acessar, senhas protegidas, áreas restritas, áreas
            seguras ou não públicas da Tráfego Integrada, exceto com a permissão
            prévia e escrita da Tráfego Integrada;
          </p>
          <p>
            Revelar ou compartilhar a sua senha com terceiros, ou usar sua senha
            para propósitos não autorizados.
          </p>
          <p>
            Realizar qualquer atividade na Tráfego Integrada que viole direitos
            de propriedade intelectual;
          </p>
          <p>
            Tentar decifrar, decompilar ou obter o código fonte dos nossos
            sites, aplicativos, serviços e sistemas; ou qualquer material ou
            informação de qualquer forma ou natureza que não sejam
            disponibilizados prontamente pela Tráfego Integrada.
          </p>
          <h2>Referências a Tráfego Integrada</h2>
          <p>
            Você poderá criar um link direto para qualquer website ou webpage da
            Tráfego Integrada, desde que esteja claramente identificado que o
            link está conectado a um website ou webpage da Tráfego Integrada.
          </p>
          <p>
            Ao criar um link para a Tráfego Integrada, Você concorda
            expressamente que:
          </p>
          <p>
            Não deslocará ou utilizará nenhuma tecnologia que cause o
            deslocamento do conteúdo da Tráfego Integrada para quaisquer
            mecanismos de exibição que mudem a aparência da Tráfego Integrada;
          </p>
          <p>
            O site que possui um link direto para a Tráfego Integrada não deverá
            exibir conteúdo ou link para outros web sites que contenham conteúdo
            ilegal, obsceno, indecente, depreciativo, discriminatório ou de
            outra forma ofensivo, nos termos da lei;
          </p>
          <p>
            Possui o domínio de seu site devidamente registrado, bem como todos
            os direitos necessários para usar o mesmo.
          </p>
          <p>
            A Tráfego Integrada se reserva o direito de suspender sua permissão
            de criar tal link a qualquer momento, e Você concorda em cessar
            imediatamente a utilização do link a qualquer momento que lhe seja
            solicitado pela Tráfego Integrada.
          </p>
          <p>
            Você concorda que a Tráfego Integrada pode, de acordo com seus
            próprios critérios, se Você violar estes Termos de Uso, e a qualquer
            momento, rescindir o seu acesso e uso da Tráfego Integrada, ou
            qualquer parte do mesmo, com ou sem advertência prévia. A Tráfego
            Integrada se reserva o direito de modificar ou descontinuar qualquer
            parte do mesmo, com ou sem advertência, e sem qualquer
            responsabilidade para com Você ou terceiros.
          </p>
          <h2>Responsabilidades e Exclusão de Garantia da Tráfego Integrada</h2>
          <p>
            A Tráfego Integrada será responsável apenas e tão somente pela
            funcionalidade dos sites, aplicativos, serviços e sistemas que
            disponibilizar diretamente a Você.
          </p>
          <p>
            A Tráfego Integrada possui recursos de segurança para a normal
            funcionalidade de seus sites, aplicativos, serviços e sistemas, no
            entanto, ainda assim, perante o dinamismo da tecnologia não consegue
            garantir a ausência integral de falhas e erros.
          </p>
          <p>
            Atento ao funcionamento da rede mundial de computadores, a Tráfego
            Integrada não pode confirmar que cada Usuário é quem declara ser.
          </p>
          <p>
            Neste sentido, Você declara estar plenamente ciente dos riscos a que
            está exposto pelo fato de estar conectado à rede mundial de
            computadores, tais como, mas não se limitando a, vírus, códigos
            maliciosos e invasões em seu sistema. Desta forma, todos os riscos e
            danos decorrentes da utilização da Tráfego Integrada são de
            exclusiva responsabilidade sua. Sendo assim, a Tráfego Integrada não
            terá qualquer responsabilidade sobre tal evento, dano ou prejuízo,
            direto ou indireto, inabilidade de uso deste, ou, ainda, por
            quaisquer informações, produtos ou serviços obtidos através dele ou
            em decorrência do seu uso.
          </p>
          <p>
            Nas hipóteses em que Você ou algum terceiro ajuíze qualquer tipo de
            reclamação ou ação legal contra o(s) Anunciante(s), todos os
            envolvidos nestas reclamações ou ações expressamente eximirão a
            Tráfego Integrada, seus sócios, empregados e contratados a qualquer
            título de toda e qualquer responsabilidade.
          </p>
          <p>
            Em nenhuma hipótese a Tráfego Integrada se responsabiliza pelo
            cumprimento devido por Você, por Anunciantes ou quaisquer terceiros,
            das respectivas obrigações civis e tributárias que venham a incidir,
            nos termos da lei vigente, sobre quaisquer atividades, operações ou
            negócios que tenham sua origem nos anúncios veiculados na Tráfego
            Integrada. Assim como a Tráfego Integrada não será responsável por
            qualquer dano, incluindo, mas sem limitação, danos diretos e/ou
            indiretos, lucros cessantes ou perda de chance que resultem do uso
            ou da impossibilidade de uso da Tráfego Integrada.
          </p>
          <p>
            A Tráfego Integrada disponibiliza funções interativas, assim como,
            mas não se limitando a formulários e links para simulações de
            financiamento que fornecem dados e informações customizadas baseadas
            em dados inseridos por Você ou pelo(s) Anunciante(s). Estas
            ferramentas não devem ser interpretadas como uma oferta para
            empréstimos, pois possuem apenas o propósito de demonstrativo e
            podem variar. Mediante a isso, Você está ciente que deverá consultar
            sempre uma instituição financeira de sua confiança.
          </p>
          <p>
            Você reconhece que a Tráfego Integrada não é um corretor de imóveis,
            corretor ou credor de hipoteca, e a Tráfego Integrada não auxilia ou
            fornece qualquer tipo de serviço ou assistência a mutuários.
          </p>
          <h2>Proteção de Dados e Privacidade</h2>
          <p>
            A Tráfego Integrada está comprometida em manter a privacidade e a
            segurança de todas as informações não publicáveis, entendidas assim,
            mas não se limitando a aquelas relacionadas aos seus dados e
            informações pessoais, que você fornecer a Tráfego Integrada.
          </p>
          <p>
            Por favor, consulte a Política de Privacidade da Tráfego Integrada
            para conhecer os detalhes relativos ao tratamento de seus Dados
            Pessoais.
          </p>
          <h2>Interpretação dos Termos de Uso</h2>
          <p>
            Se qualquer parte dos Termos de Uso for determinada inválida ou
            inexecutável de acordo com o direito aplicável, a mesma será
            considerada e substituída por uma provisão válida e executável que
            se aproxime o máximo possível da intenção da provisão original. Tal
            invalidade ou inexecução não afetará a validez das restantes, que
            manterão sua plena vigência e efeito.
          </p>
          <h2>Indenização</h2>
          <p>
            Você aceita e concorda em indenizar a Tráfego Integrada, seu(s)
            Anunciante(s), afiliados, diretores, empregados e contratados de
            qualquer espécie, e mantê-los indenes de quaisquer e todas as
            reclamações e demandas, incluindo, mas não se limitando a,
            honorários razoáveis de advogados, feitas por terceiros ou
            decorrentes de qualquer Conteúdo enviado, divulgado ou providenciado
            de qualquer outra forma por Você a Tráfego Integrada e/ou seus
            Anunciantes, e qualquer violação destes Termos de Uso cometida por
            Você ou, se houver, seus afiliados, diretores, empregados e
            contratados de qualquer espécie.
          </p>
          <p>
            Você reconhece que a Tráfego Integrada é um mero intermediário,
            alheio a qualquer relação que possa ser estabelecida entre si e
            seu(s) Anunciante(s), que publiquem avisos, anúncios e/ou propostas
            por meio da Tráfego Integrada. Neste sentido, Você entende que
            qualquer demanda originada de tais relações devem ser solucionadas
            entre as partes respectivas, não cabendo reclamações à Tráfego
            Integrada a respeito.
          </p>
          <h2>Duração e Rescisão</h2>
          <p>
            A prestação de serviços da Tráfego Integrada tem uma duração
            indeterminada. Sem prejuízo do anterior, a Tráfego Integrada está
            autorizada a dar por terminada ou suspender a prestação do Serviço
            da Tráfego Integrada e/ou de qualquer dos Conteúdos em qualquer
            momento.
          </p>
          <h2>Lei Aplicável</h2>
          <p>
            Estes Termos de Uso são governados pelas leis da República
            Federativa do Brasil. Fica eleito o foro da Comarca de Americana,
            Estado de São Paulo, para dirimir todos os litígios decorrentes ou
            relacionados ao acesso ou uso da Tráfego Integrada.
          </p>
          <p>
            Você concorda que nenhum empreendimento conjunto, parceria, emprego,
            relação de agência ou representação se estabeleceu entre Você e a
            Tráfego Integrada como resultado dos Termos de Uso ou acesso a
            Tráfego Integrada. Nada contido nos Termos de Uso impedirá o direito
            da Tráfego Integrada de cumprir com quaisquer pedidos de execução
            governamentais, de tribunais ou derivados de lei, ou requisitos
            relacionados ao seu acesso ou uso da Tráfego Integrada ou informação
            fornecida ou recolhida pela Tráfego Integrada a respeito de tal uso.
          </p>
          <h2>Contato</h2>
          <p>
            Para solicitações ou dúvidas sobre estes Termos de Uso, envie um
            e-mail para contato@trafegointegrada.com.br.
          </p>
        </div>
      </div>
    </div>
  </section>
</main>

<app-footer></app-footer>

import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {RequestService} from '../../shared/services/request.service';
import {Globals} from '../../app.global';

@Component({
    selector: 'app-modal-contato',
    templateUrl: './contatoImovel.component.html',
    styleUrls: ['./contatoImovel.component.scss']
})

export class ContatoImovelComponent implements OnChanges {
    @Input() imovel_id: '';
    @Input() ExibirDados = false;
    imobiliaria: any;
    public dadosAnuncianteTipo: any = 'lateral';
    @Input() modal: '';
    public abrir_modal = false;
    public _telefone: any = '';
    imovel: any = '';
    aviso: any;
    ExibirFormulario = true;
    ExibirFormularioEnviando = true;

    form: any = [];

    constructor(public request: RequestService, public global: Globals) {
    }

    ngOnChanges(changes: SimpleChanges) {

        this.dadosAnuncianteTipo = 'lateral';
        this.ExibirDados = false;
        this.aviso = false;
        this.form.nome = '';
        this.form.email = '';
        this.form.mensagem = '';
        this.form.aviseme = true;

        this.ExibirFormulario = true;
        this.ExibirFormularioEnviando = true;
        if (this.modal && this.imovel_id) {
            this.abrir_modal = true;
            this.request.get('/imoveis/detalhe?id=' + this.imovel_id)
                .then(res => {
                        if (res) {
                            this.imovel = res.result;
                            this.request.get('/Contas?id=' + this.imovel.imobiliaria.id)
                                .then(res => {
                                        if (res && res.result) {
                                            this.imobiliaria = res.result[0];
                                            console.log(this.imobiliaria);
                                            this.request.estatistica('imovel', 'acesso', this.imobiliaria.slug, this.imovel_id);
                                            this.form.mensagem = 'Olá, tenho interesse no imóvel código ' + this.imovel.codigo
                                                + ', ' + this.imovel.titulo + ', encontrei ele no ' + this.global.Titulo_Portal
                                                + '. Aguardo o contato. Obrigado.';
                                            this.ExibirDados = true;
                                        } else {
                                            return false;
                                        }
                                    },
                                    err => err);
                        } else {
                            return false;
                        }
                    },
                    err => err);
        }
    }

    onSubmit() {

        let aviseme = 0;
        let nome = 0;
        let email = 0;
        let telefone = 0;
        let mensagem = 0;
        let origem = 'portal';
        let url = '/imovel/' + this.imovel.id + '/' + this.imovel.url;
        let imagem = this.global.Api_Imagem + '/imoveis/imovel-sem-foto.jpg';
        if (this.imovel.imagem) {
            imagem = this.global.Api_Imagem + '/imoveis/' + this.imovel.imobiliaria.id + '/' + this.imovel.imagem[0].name;
        }
        if (this.form.aviseme) {
            aviseme = this.form.aviseme;
        }
        if (this.form.nome) {
            nome = this.form.nome;
        }
        if (this.form.telefone) {
            telefone = this.form.telefone;
        }
        if (this.form.email) {
            email = this.form.email;
        }
        if (this.form.mensagem) {
            mensagem = this.form.mensagem;
        }

        if (nome && (telefone || email)) {
            this.ExibirFormulario = false;
            this.ExibirFormularioEnviando = true;

            this.request.get('/Envio/enviar?origem=' + origem + '&imagem=' + imagem + '&imovel=' + this.imovel.id + '&imobiliaria='
                + this.imovel.imobiliaria.id + '&url=' + url + '&nome=' + nome + '&telefone=' + telefone + '&email=' + email + '&mensagem='
                + mensagem + '&editar-aviseme=' + aviseme)
                .then(res => {
                        if (res && res.status) {
                            this.ExibirFormularioEnviando = false;
                        } else {
                            return false;
                        }
                    },
                    err => err);

        } else {
            this.aviso = true;
        }
    }

    fechar() {
        this.abrir_modal = false;
        this.imovel = '';
    }
}

import {Component} from '@angular/core';
import {Globals} from "../../app.global";

@Component({
	selector: 'app-chamada-erro',
	templateUrl: './chamada-erro.component.html',
	styleUrls: ['./chamada-erro.component.scss']
})
export class ChamadaErroComponent {

	constructor(public global: Globals) {
	}
}

import { ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { Globals } from '../../app.global';
import { RequestService } from '../../shared/services/request.service';
var FaleConoscoComponent = /** @class */ (function () {
    function FaleConoscoComponent(route, title, meta, global, request) {
        this.route = route;
        this.title = title;
        this.meta = meta;
        this.global = global;
        this.request = request;
        this.form = [];
        this.latitude = -22.73387;
        this.longitude = -47.335762;
        this.nome = '';
        this._telefone = '';
        this.email = '';
        this.mensagem = '';
        this.ExibirFormularioEnviando = false;
        this.ExibirFormulario = true;
        this.ExibirFormularioEnviado = false;
        title.setTitle('Fale conosco' + ' - ' + this.global.Titulo_Portal);
        meta.addTag({
            name: 'description',
            content: 'Fale com o  ' + this.global.Titulo_Portal + ', somos de Americana/SP estamos sempre pronto para tirar suas dúvidas.'
        });
        meta.addTag({
            name: 'keywords',
            content: 'fale conosco, anunciar imóvel, como anunciar meu imóvel, anunciar casa, anunciar apartamento, ' +
                'como anunciar meu apartamento, vender imóvel, vender apartamento, alugar imovel, alugar casa, vender casa, ' +
                'alugar apartamento, anunciar imóvel direto proprietário'
        });
        meta.addTag({
            name: 'author',
            content: 'Tráfego Integrada'
        });
    }
    Object.defineProperty(FaleConoscoComponent.prototype, "telefone", {
        set: function (telefone) {
            this._telefone = telefone;
        },
        enumerable: true,
        configurable: true
    });
    FaleConoscoComponent.prototype.ngAfterViewInit = function () {
        var marker;
        var contentString;
        var infowindow;
        var marcar = { lat: this.latitude, lng: this.longitude };
        var mapProperties = {
            center: new google.maps.LatLng(this.latitude, this.longitude),
            zoom: 14,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            title: this.global.Titulo_Portal
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
        contentString = '<div id="content">' +
            '<div id="siteNotice">' +
            '</div>' +
            '<h3 id="firstHeading" class="firstHeading" style="margin:0px 0px 10px 0px;  color:#404E67">' + this.global.Titulo_Portal +
            '</h3><div id="bodyContent">' +
            '<p style="margin: 0px; font-size: 14px; color:#404E67">' +
            '(019) 3387-7547' +
            '<br>' +
            'falecom@trafegoimoveis.com.br</p>' +
            '</div>' +
            '</div>';
        marker = new google.maps.Marker({
            position: marcar,
            map: this.map,
            title: this.global.Titulo_Portal
        });
        infowindow = new google.maps.InfoWindow({
            content: contentString
        });
        infowindow.open(this.map, marker);
        marker.setMap(this.map);
    };
    FaleConoscoComponent.prototype.onSubmit = function () {
        var _this = this;
        this.ExibirFormularioEnviando = true;
        this.ExibirFormulario = false;
        this.ExibirFormularioEnviado = false;
        this.request.get('/Envio/enviosPortal?referencia=fale-conosco&assunto=Fale Conosco&nome=' + this.nome + '&email=' + this.email +
            '&telefone=' + this._telefone + '&mensagem=' + this.mensagem)
            .then(function (res) {
            _this.ExibirFormularioEnviando = false;
            _this.ExibirFormulario = false;
            _this.ExibirFormularioEnviado = true;
            _this.nome = '';
            _this.telefone = '';
            _this._telefone = '';
            _this.email = '';
            _this.mensagem = '';
        }, function (err) { return err; });
    };
    return FaleConoscoComponent;
}());
export { FaleConoscoComponent };

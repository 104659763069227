import { Component } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { Globals } from "../../app.global";

@Component({
  templateUrl: "./privacidade.component.html",
  styleUrls: ["./privacidade.component.scss"],
})
export class PrivacidadeComponent {
  constructor(
    private title: Title,
    private meta: Meta,
    public global: Globals
  ) {
    title.setTitle(
      "Declaração de Privacidade e Cookies Portal Imobiliário Tráfego Imoveis"
    );
    meta.addTag({
      name: "description",
      content:
        "A Tráfego Imóveis não é uma imobiliária, é um portal imobiliário que encontra para você milhares de oportunidades em imóveis, seja para comprar ou alugar.",
    });
    meta.addTag({
      name: "keywords",
      content:
        "Imóveis à venda e Imóveis para alugar. Na Tráfego Imóveis você encontra casas e apartamentos novos e usados para compra, venda ou aluguel em  Americana, Santa Bárbara d'Oeste, Sumaré, Nova Odessa. Anuncie seu imóvel!",
    });
    meta.addTag({
      name: "author",
      content: "Tráfego Integrada",
    });
  }
}

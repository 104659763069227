import {Component} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import {Globals} from "../../app.global";
import {RequestService} from "../../shared/services/request.service";
import {ActivatedRoute} from "@angular/router";

@Component({
	templateUrl: './anunciantes.component.html',
	styleUrls: ['./anunciantes.component.scss']
})
export class AnunciantesComponent {
	titulo_complemento: any;
	cidades: any;
	imobiliarias: any;
	cidade: string = '';
	estado: string = '';

	constructor(private route: ActivatedRoute, private title: Title, private meta: Meta, public global: Globals, public request: RequestService) {
		this.load();
	}

	load() {

		//VERIFICA PRIMEIRO SE EXISTE ALGUM PARAMETRO CIDADE E ESTADO
		this.route.params.subscribe(params => {
			if (params['cidade'] || params['estado']) {
				this.cidade = params['cidade'];
				this.estado = params['estado'];
			}
		});

		//CARREGAS AS CIDADES
		this.request.get('/Contas/cidades')
			.then(res => {
					this.cidades = res.result;
				},
				err => err);

		//CARREGAS AS IMOBILIARIAS
		this.request.get('/Contas?cidade=' + this.cidade + '&estado=' + this.estado)
			.then(res => {

					if (this.cidade) {
						this.titulo_complemento = 'em ' + res.result[0].cidade + '/' + this.estado;
					}

					this.imobiliarias = res.result;

					this.seo();
				},
				err => err);



	}

	seo(){
		//CARREGA AS TAGS PARA A PAGINA
		this.title.setTitle('Imobiliárias e Corretores ' +this.titulo_complemento+ ' participantes do ' + this.global.Titulo_Portal);
		this.meta.addTag({
			name: 'description',
			content: 'Conheça as Imobiliárias e Corretores ' +this.titulo_complemento+ ' que fazem parte do ' + this.global.Titulo_Portal + '. Imóveis à venda e Imóveis para alugar. Na Tráfego Imóveis você encontra casas e apartamentos novos e usados para compra, venda ou aluguel. Anuncie seu imóvel!'
		});
		this.meta.addTag({
			name: 'keywords',
			content: this.cidade + ',' + this.estado + 'portal Imobiliário, casa, apartamento, venda, locação, compra, novo, usado, lançamento, americana, santa bárbara d\'oeste, nova odessa, sumaré, sbo, são paulo'
		});
		this.meta.addTag({
			name: 'author',
			content: 'Tráfego Integrada'
		});
	}

}

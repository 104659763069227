import { Component, Input, OnInit } from "@angular/core";
import { RequestService } from "../../shared/services/request.service";
import { Globals } from "../../app.global";
import { DeviceDetectorService } from "ngx-device-detector";
declare global {
  interface Window {
    dataLayer: any;
  }
}
@Component({
  selector: "app-dados-anunciante",
  templateUrl: "./dadosanunciante.component.html",
  styleUrls: ["./dadosanunciante.component.scss"],
})
export class DadosAnuncianteComponent implements OnInit {
  @Input() slug: false;
  @Input() local: any;
  public mobile: boolean;
  public erro: boolean;
  public imobiliaria: boolean;
  public localclass: any;
  public phone: boolean;
  public mail: boolean;

  constructor(
    public request: RequestService,
    public global: Globals,
    public deviceService: DeviceDetectorService
  ) {
    this.mobile = false;
    this.erro = false;
    this.imobiliaria = false;
    this.phone = false;
    this.mail = false;
  }

  exibirDados() {
    this.mobile = false;
  }

  verTelefone() {
    this.phone = true;
    window.dataLayer.push({
      event: "verTelefone",
    });
  }
  verEmail() {
    this.mail = true;
    window.dataLayer.push({
      event: "verEmail",
    });
  }

  ngOnInit() {
    this.localclass = this.local;
    if (this.deviceService.isMobile() && this.localclass !== "imovel") {
      this.mobile = true;
    }
    /*CARREGAS AS IMOBILIARIAS*/

    this.request.get("/Contas?slug=" + this.slug).then(
      (res) => {
        if (res) {
          this.imobiliaria = res.result;
          this.request.estatistica("conta", "acesso", this.slug);
        } else {
          this.erro = true;
        }
      },
      (err) => err
    );
  }
}

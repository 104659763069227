var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fromEvent as observableFromEvent } from 'rxjs';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { ElementRef, AfterViewInit } from '@angular/core';
import { RequestService } from "../../shared/services/request.service";
import { Globals } from "../../app.global";
import { Router } from "@angular/router";
import 'rxjs/Rx';
import { DeviceDetectorService } from 'ngx-device-detector';
var BuscaInicialComponent = /** @class */ (function () {
    function BuscaInicialComponent(router, request, global, deviceService) {
        var _this = this;
        this.router = router;
        this.request = request;
        this.global = global;
        this.deviceService = deviceService;
        this.buscaLocalizacao = true;
        this.buscaCodigo = false;
        //private _operacao: string = 'Venda';
        this.codigo = '';
        this.operacao = 'Venda';
        //private _tipo: string = null;
        this.tipo = 'Apartamento';
        this._localizacao = '';
        this.exbir_localizacao = false;
        this.request.get('/Buscas/operacao')
            .then(function (res) {
            if (res.status == 1) {
                _this._operacaoOpcoes = res.result;
            }
            else {
                _this._operacaoOpcoes[0] = 'Erro ao carregar';
            }
        }, function (err) { return err; });
        this.onChangeOperacao();
    }
    Object.defineProperty(BuscaInicialComponent.prototype, "localizacao", {
        set: function (localizacao) {
            this._localizacao = localizacao;
        },
        enumerable: true,
        configurable: true
    });
    BuscaInicialComponent.prototype.buscar = function (tipo) {
        if (tipo == 'localizacao') {
            window.location.href = this.global.Portal_Url + '/imoveis/' + this.operacao + '?pagina=1&tipo=' + this.tipo + '&localizacao=' + this._localizacao;
        }
        else {
            window.location.href = this.global.Portal_Url + '/imoveis?codigo=' + this.codigo;
        }
    };
    /*
        onSubmit(e) {
            if(e == 'codigo'){
                this.router.navigate(['imoveis/' + '?codigo=' + this.codigo]);
            }else {
                this.router.navigate(['imoveis/' + this.operacao + '?tipo=' + this.tipo]);
            }
            return false;
            //e.target.submit();
        }
    */
    BuscaInicialComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        observableFromEvent(this.localizacaoRef.nativeElement, 'keyup').pipe(map(function (evt) { return evt.target.value; }), debounceTime(200), distinctUntilChanged())
            .subscribe(function (text) {
            _this._localizacao = text;
            _this.defineLocalizacao();
            _this.exbir_localizacao = true;
        });
    };
    BuscaInicialComponent.prototype.focus = function () {
        if (this.deviceService.isMobile()) {
            window.scroll(0, 130);
        }
        this.defineLocalizacao();
        if (this._localizacao.length >= 2 && (this._sugestoesLocalizacaoCidades || this._sugestoesLocalizacaoBairros)) {
            this.exbir_localizacao = true;
        }
        else {
            this.exbir_localizacao = false;
        }
    };
    BuscaInicialComponent.prototype.timeout = function (ms) {
        return new Promise(function (resolve) { return setTimeout(resolve, ms); });
    };
    BuscaInicialComponent.prototype.focusOut = function () {
        var _this = this;
        var timestampDiffs = [1];
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var _i, timestampDiffs_1, item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _i = 0, timestampDiffs_1 = timestampDiffs;
                        _a.label = 1;
                    case 1:
                        if (!(_i < timestampDiffs_1.length)) return [3 /*break*/, 4];
                        item = timestampDiffs_1[_i];
                        return [4 /*yield*/, this.timeout(item * 1000)];
                    case 2:
                        _a.sent();
                        this.exbir_localizacao = false;
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        }); })();
    };
    BuscaInicialComponent.prototype.onChangeOperacao = function () {
        var _this = this;
        this.request.get('/Buscas/Tipo?operacao=' + this.operacao)
            .then(function (res) {
            if (res.status == 1) {
                _this._tipoOpcoes = res.result;
            }
            else {
                _this._tipoOpcoes[0] = 'Erro ao carregar';
            }
        }, function (err) { return err; });
        this.defineLocalizacao();
    };
    BuscaInicialComponent.prototype.onChangeTipo = function () {
        this.defineLocalizacao();
    };
    BuscaInicialComponent.prototype.defineLocalizacao = function () {
        var _this = this;
        this.request.get('/Buscas/localizacao?operacao=' + this.operacao + '&tipo=' + this.tipo + '&localizacao=' + this._localizacao)
            .then(function (res) {
            _this._sugestoesLocalizacaoCidades = res.result.cidades;
            _this._sugestoesLocalizacaoBairros = res.result.bairros;
        }, function (err) { return err; });
    };
    BuscaInicialComponent.prototype.buscarLocalizacao = function () {
        this.buscaCodigo = false;
        this.buscaLocalizacao = true;
    };
    BuscaInicialComponent.prototype.buscarCodigo = function () {
        this.buscaCodigo = true;
        this.buscaLocalizacao = false;
    };
    return BuscaInicialComponent;
}());
export { BuscaInicialComponent };

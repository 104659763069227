import { Component } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { Globals } from "../../app.global";
import { RequestService } from "../../shared/services/request.service";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  maispesquisadas: string;
  cidadesemdestaque: string;
  imobiliarias: string;
  post: string;
  public mobile: boolean = false;

  constructor(
    private title: Title,
    private meta: Meta,
    public global: Globals,
    public request: RequestService,
    public deviceService: DeviceDetectorService
  ) {
    title.setTitle(
      "Imóveis, Casas e Apartamentos para Compra e Aluguel - " +
        global.Titulo_Portal
    );
    meta.addTag({
      name: "description",
      content:
        "Na Tráfego Imóveis você encontra casas e apartamentos novos e usados para venda ou aluguel em  Americana, Santa Bárbara d'Oeste, Sumaré, Nova Odessa. Anuncie seu imóvel!",
    });
    meta.addTag({
      name: "keywords",
      content:
        "portal Imobiliário, casa, apartamento, venda, locação, compra, novo, usado, lançamento, americana, santa bárbara d'oeste, nova odessa, sumaré, sbo, são paulo Imóveis à venda e Imóveis para alugar",
    });
    meta.addTag({
      name: "author",
      content: "Tráfego Integrada",
    });

    this.mobile = this.deviceService.isMobile();

    this.load();
  }

  load() {
    //CARREGA AS MAIS PESQUISADAS
    this.request.get("/Buscas/maispesquisadas").then(
      (res) => {
        this.maispesquisadas = res.result;
      },
      (err) => err
    );

    if (!this.mobile) {
      //CARREGA AS CIDADES EM DESTAUQE
      this.request.get("/Buscas/cidadesemdestaque").then(
        (res) => {
          this.cidadesemdestaque = res.result;
        },
        (err) => err
      );

      //CARREGAS POST DO BLOG
      this.request.get("/Blog/post").then(
        (res) => {
          this.post = res.result;
        },
        (err) => err
      );
    }
  }

  somar(tipo, local, id, campo) {
    this.request.get("/Buscas/" + tipo + "/" + local + "/" + id + "/" + campo);
  }
}

import {Injectable} from '@angular/core'
import * as _ from 'underscore';

@Injectable()
export class PaginationService {
	getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
		// calculate total pages
		let totalPages = Math.ceil(totalItems / pageSize);
/*
		console.log('total de itens ' + totalItems);
		console.log('total de paginas ' + pageSize);
		console.log('total de paginas ' + totalPages);
*/
		let startPage: number, endPage: number;

		if (Number(totalPages) <= 5) {
			startPage = 1;
			endPage = Number(totalPages);
		} else {
			if (Number(currentPage) <= 3) {
				startPage = 1;
				endPage = 5;
			} else if (Number(currentPage) + 1 >= Number(totalPages)) {
				startPage = Number(totalPages) - 4;
				endPage = Number(totalPages);
			} else {
				startPage = Number(currentPage) - 2;
				endPage = Number(currentPage) + 2;
			}
		}

		// calculate start and end item indexes
		let startIndex = (currentPage - 1) * pageSize;
		let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

		// create an array of pages to ng-repeat in the pager control
		let pages = _.range(startPage, endPage + 1);

		// return object with all pager properties required by the view
		return {
			totalItems: Number( totalItems ),
			currentPage: Number( currentPage ),
			pageSize: Number( pageSize ),
			totalPages: Number( totalPages ),
			startPage: Number( startPage ),
			endPage: Number( endPage ),
			startIndex: Number( startIndex ),
			endIndex: Number( endIndex ),
			pages: pages
		};
	}
}

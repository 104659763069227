import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Globals} from '../../app.global';

@Injectable()
export class RequestService {
    constructor(public http: HttpClient, public global: Globals) {
    }

    get(modulo): Promise<any> {

        let url;
        url = this.global.Api_Url + modulo;

        return new Promise((resolve, reject) => {
            this.http.get(url, {
                params: {
                    chave: this.global.Api_Chave
                },
                observe: 'response'
            }).subscribe((data) => {
                if (data['status'] === 200) {
                    resolve(data['body']);
                } else {
                    reject('This user is not defined');
                }
            }, (err) => {
                reject(err);
            });
        });
    }

    estatistica(tipo, opcao, anunciante, imovel = null) {

        let url;
        url = this.global.Api_Url + '/estatistica';

        return new Promise((resolve, reject) => {
            this.http.get(url, {
                params: {
                    chave: this.global.Api_Chave,
                    tipo: tipo,
                    opcao: opcao,
                    anunciante: anunciante,
                    imovel: imovel
                },
                observe: 'response'
            }).subscribe((data) => {
                if (data['status'] === 200) {
                    resolve(data['body']['result']);
                } else {
                    console.log('teste');
                    reject('This user is not defined');
                }
            }, (err) => {
                reject(err);
            });
        });

    }


}

<app-header></app-header>

<main>
  <h1>Alerta de imóveis {{global.Titulo_Portal}}</h1>
  <section>
    <div class="card">
      <div class="sobre">
        <img src="{{global.Api_Imagem + 'portal/img-aviseme.png'}}" alt="Imagem ilustrativa Alerta de Imóveis">
        <div class="conteudo">
          <h2>
            Iremos notificar em seu e-mail, os novos imóveis de seu interesse.
          </h2>
          <p>
            O {{global.Titulo_Portal}}, é um portal imobiliário que encontra
            para você milhares de oportunidades em imóveis, seja para comprar ou alugar.
          </p>
          <p>
            Ao cadastrar um alerta, o {{global.Titulo_Portal}} se encarregara de te manter informado sobre os novos
            imóveis de seu interesse!
          </p>
          <p>
            Cruzaremos suas informações com as informações dos imóveis e enviaremos uma lista selecionada em seu
            e-mail.
          </p>
          <p>
            O que achou? Cadastre no formulário abaixo! Quando encontrar seu novo imóvel, com apenas um clique, você
            remove seu alerta!</p>
          <p>Você poderá criar quantos alertas precisar, fique a vontade!</p>

          <div class="acao">
            <a (click)="acaoClick('criar')" class="criar" title="Criar um novo alerta">CRIAR ALERTA</a>
            <a (click)="acaoClick('excluir')" class="excluir" title="Excluir um alerta">EXCLUIR ALERTA</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <br>
  <section>
    <div class="card">
      <div class="opcoes">
        <div class="excluir-conta" *ngIf="boxExcluir">

          <h2>Excluir alerta</h2>
          <p *ngIf="!confirmouEmailExcluir && !loadingEmailExcluir">Para excluir seu alerta precisamos confirmar o seu
            e-mail, para fazer isso de forma simples, informe seu
            e-mail no campo abaixo que enviaremos um e-mail com um link para poder excluir os alertas que estão
            vinculados ao seu e-mail.</p>

          <form (ngSubmit)="onSubmitExcluir()" #testForm="ngForm" ngNativeValidate
                *ngIf="!confirmouEmailExcluir && !loadingEmailExcluir">
            <label for="cmp-excluir-email">Seu e-mail</label>
            <div class="grupo">
              <div class="campo">
                <input type="text" placeholder="E-mail" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                       #emailref="ngModel" required id="cmp-excluir-email" name="email" [(ngModel)]="form_excluir.email"
                       [ngModelOptions]="{standalone: true}">
              </div>
              <button type="submit" [disabled]="!testForm.form.valid" class="btn laranja">
                <i class="fab fa-telegram-plane"></i> Solicitar exclusão
              </button>
            </div>
            <div class="erro" *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)">
              <p [hidden]="!emailref.errors?.pattern">Informe um e-mail válido</p>
            </div>
          </form>


          <div class="loading-conteudo" *ngIf="!confirmouEmailExcluir && loadingEmailExcluir">
            <img src="{{global.Api_Imagem + 'portal/loading.gif'}}" class="text-center" alt="">
          </div>

          <div *ngIf="confirmouEmailExcluir" class="confirma">
            <i class="far fa-thumbs-up"></i>
            Foi enviado para o seu e-mail um link para continuar o processo de exclusão dos alertas.
          </div>
          <div *ngIf="erroEmailExcluir" class="confirma erro">
            <i class="far fa-thumbs-down"></i>
            O e-mail informado não foi encontrado
          </div>

        </div>
        <div class="criar-conta" *ngIf="boxCriar">

          <h2>Criar alerta</h2>
          <p>Para criar um novo alerta de imóveis, basta fazer uma nova busca e clicar no item "Criar alerta"!</p>
          <p>
            Selecione uma das opções abaixo para iniciar o processo de criação do alerta.
          </p>

          <ul>
            <li><a href="/imoveis/Venda" title="Imóveis para venda">Imóveis para venda</a></li>
            <li><a href="/imoveis/Locação" title="Imóveis para locação">Imóveis para locação</a></li>
            <li><a href="/imoveis/Temporada" title="Imóveis para temporada">Imóveis para temporada</a></li>
          </ul>

        </div>
      </div>
    </div>
  </section>
</main>

<app-footer></app-footer>

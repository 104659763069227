import {fromEvent as observableFromEvent} from 'rxjs';

import {distinctUntilChanged, debounceTime, map} from 'rxjs/operators';
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Title, Meta} from '@angular/platform-browser';
import {Globals} from '../../app.global';
import {RequestService} from '../../shared/services/request.service';
import {PaginationService} from '../../shared/services/pagination.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Location} from '@angular/common';

@Component({
  templateUrl: './imoveis.component.html',
  styleUrls: ['./imoveis.component.scss']
})

export class ImoveisComponent implements OnInit {

  public filtros: any = {
    codigo: '',
    operacao: '',
    tipo: '',
    localizacao: '',
    pagina: '1',
    valor_min: '',
    valor_max: '',
    area_min: '',
    area_max: '',
    financiamento: '',
    permulta: '',
    publicado: '',
    ordenar: ''
  };

  public exibirAviseme = 0;
  public exibirBusca: boolean;
  public mobile: boolean;

  public listaOrdem: boolean;
  public listaPublicado: boolean;

  public titulo: any;
  public imoveis: any;
  public total_imoveis = 0;
  public total_paginas = 0;
  public allItems: any;
  public pager: any;

  public tipo: any;
  public tipoOpcoes: any;
  public tipoFiltros: object = [];

  public _valor_min: any;
  public _valor_max: any;

  public _area_min: any;
  public _area_max: any;

  public localizacao: any;
  public localizacaoExibir = false;
  public localizacaoOpcoesCidades: any;
  public localizacaoOpcoesBairros: any;
  public localizacaoFiltros: object = [];

  @ViewChild('localizacaoRef') localizacaoRef: ElementRef;

  @ViewChild('valor_minRef') valor_minRef: ElementRef;
  @ViewChild('valor_maxRef') valor_maxRef: ElementRef;

  @ViewChild('area_minRef') area_minRef: ElementRef;
  @ViewChild('area_maxRef') area_maxRef: ElementRef;

  @Input()
  set _localizacao(localizacao: string) {
    this.localizacao = localizacao;
  }

  public erro: boolean;

  name: string;

  constructor(private route: ActivatedRoute, private title: Title, private meta: Meta, public global: Globals, public router: Router,
              public request: RequestService, public pagination: PaginationService, public deviceService: DeviceDetectorService,
              private location: Location) {

    this.route.queryParams.subscribe(params => {



      this.filtros.pagina = (params.pagina ? params.pagina : '1');
      this.filtros.codigo = (params.codigo ? params.codigo : '');
      this.filtros.operacao = (params.operacao ? params.operacao : '');
      this.filtros.tipo = (params.tipo ? params.tipo : '');
      this.filtros.localizacao = (params.localizacao ? params.localizacao : '');

      this.filtros.valor_min = (params.valor_min ? params.valor_min : '');
      this.filtros.valor_max = (params.valor_max ? params.valor_max : '');

      this._valor_min = parseFloat( this.filtros.valor_min.replace(/[^0-9-,]/gi,'', '') );
      this._valor_max = parseFloat( this.filtros.valor_max.replace(/[^0-9-,]/gi,'', '') );

      this.filtros.area_min = (params.area_min ? params.area_min : '');
      this.filtros.area_max = (params.area_max ? params.area_max : '');

      this._area_min = parseFloat( this.filtros.area_min.replace(/[^0-9-,]/g,'', ''));
      this._area_max = parseFloat( this.filtros.area_max.replace(/[^0-9-,]/g,'', ''));

      this.filtros.vaga = (params.vaga ? params.vaga : '');
      this.filtros.banheiro = (params.banheiro ? params.banheiro : '');
      this.filtros.quarto = (params.quarto ? params.quarto : '');
      this.filtros.ordenar = (params.ordenar ? params.ordenar : '');
      this.filtros.publicado = (params.publicado ? params.publicado : '');
      this.filtros.financiamento = (params.financiamento ? params.financiamento : '');
      this.filtros.permuta = (params.permuta ? params.permuta : '');
    });
    this.route.params.subscribe(res => {
      if (this.filtros.operacao === '') {
        this.filtros.operacao = (!res.operacao ? 'Venda-Aluguel-Temporada' : res.operacao);
      }
      if (this.filtros.tipo === '') {
        this.filtros.tipo = (!res.tipo ? 'Todos-Imoveis' : res.tipo);
      }
      if (this.filtros.localizacao === '') {
        var estado = '';
        if (res.estado) {
          estado = '/' + res.estado;
        }
        this.filtros.localizacao = (!res.localizacao ? 'Em-qualquer-localizacao' : res.localizacao + estado);
      }
    });
    this.setTipo();
    this.mobile = this.deviceService.isMobile();
    if (this.mobile) {
      this.exibirBusca = false;
    }
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  abrirAviseme() {
    this.exibirAviseme++;
  }

  abrirBusca() {
    if (this.exibirBusca) {
      this.exibirBusca = false;
    } else {
      this.exibirBusca = true;
    }
  }

  setBusca(opcao, valor) {
    this.filtros.pagina = 1;
    this.filtros.codigo = '';

    if (opcao == 'vaga' || opcao == 'quarto' || opcao == 'banheiro') {
      if (this.filtros[opcao] == valor) {
        this.filtros[opcao] = '';
      } else {
        this.filtros[opcao] = valor;
      }
    } else {
      this.filtros[opcao] = valor;
    }

    var url = '/imoveis';

    var x_operacao = '';
    var x_tipo = '';
    var x_localizacao = '';
    if (opcao == 'operacao' || opcao == 'tipo' || opcao == 'localizacao') {
      x_operacao = (!this.filtros.operacao ? 'Venda-Aluguel-Temporada' : this.filtros.operacao);
      if (opcao == 'operacao') {
        this.valor_minRef.nativeElement.value = '';
        this.valor_maxRef.nativeElement.value = '';

        this.setBusca('valor_min', '0');
        this.setBusca('valor_max', '0');

        x_operacao = valor;
      }
      x_tipo = (!this.filtros.tipo ? 'Todos-Imoveis' : this.filtros.tipo);
      if (opcao == 'tipo') {
        x_tipo = valor;
      }
      x_localizacao = (!this.filtros.localizacao ? 'Em-qualquer-localizacao' : this.filtros.localizacao);
      if (opcao == 'localizacao') {
        x_localizacao = valor;
      }
    } else {
      this.route.params.subscribe(res => {
        x_operacao = (!res.operacao ? 'Venda-Aluguel-Temporada' : res.operacao);
        x_tipo = (!res.tipo ? 'Todos-Imoveis' : res.tipo);
        x_localizacao = (!res.localizacao ? 'Em-qualquer-localizacao' : res.localizacao);
      });
    }

    this.setTipo();

    url += '/' + x_operacao + '/' + x_tipo;
    url += '?pagina=' + this.filtros.pagina;
    url += '&operacao=' + this.filtros.operacao;
    url += '&tipo=' + this.filtros.tipo;
    url += '&localizacao=' + this.filtros.localizacao;
    url += '&valor_min=' + this.filtros.valor_min;
    url += '&valor_max=' + this.filtros.valor_max;
    url += '&area_min=' + this.filtros.area_min;
    url += '&area_max=' + this.filtros.area_max;
    url += '&quarto=' + this.filtros.quarto;
    url += '&banheiro=' + this.filtros.banheiro;
    url += '&vaga=' + this.filtros.vaga;
    url += '&publicado=' + this.filtros.publicado;
    url += '&ordenar=' + this.filtros.ordenar;
    url += '&codigo=' + this.filtros.codigo;

    if (opcao == 'publicado' || opcao == 'ordenar') {
      this.listaPublicado = false;
      this.listaOrdem = false;
    }

    this.location.go(url);

    this.loadImoveis();

  }

  setOpcoes() {
    this._localizacao = '';
    this.tipo = '';

    var dados = [];
    var novaLista = [];

    dados = this.filtros.localizacao.split(',');
    dados.forEach(function (value) {
      if (value != '' && value != 'Em-qualquer-localizacao') {
        novaLista.push(value);
      }
    });
    this.localizacaoFiltros = novaLista;

    dados = this.filtros.tipo.split(',');
    novaLista = [];
    dados.forEach(function (value) {
      if (value != '' && value != 'Todos-Imoveis') {
        novaLista.push(value);
      }
    });
    this.tipoFiltros = novaLista;
  }

  setTipo() {
    this.request.get('/Buscas/Tipo?operacao=' + this.filtros.operacao + '&localizacao=' + this.filtros.operacao)
      .then(res => {
          if (res.status == 1) {
            this.tipoOpcoes = res.result;
          } else {
            this.tipoOpcoes[0] = 'Erro ao carregar';
          }
        },
        err => err);
  }

  onChangeTipo() {
    var tipo = this.tipo;
    var novoTipo = tipo;
    var dados = this.filtros.tipo.split(',');
    dados.forEach(function (value) {
      if (value != tipo && value != '' && value != 'Todos-Imoveis') {
        novoTipo += ',' + value;
      }
    });
    this.setBusca('tipo', novoTipo);
  }

  removeTipo(item) {
    this.filtros.pagina = 1;
    var dados = this.filtros.tipo.split(',');
    var novoTipo = '';
    dados.forEach(function (value) {
      if (value != item) {
        if (novoTipo != '') {
          novoTipo += ',' + value;
        } else {
          novoTipo = value;
        }
      }
    });
    this.setBusca('tipo', novoTipo);
  }

  onChangeLocalizacao(index, tipo) {
    this.filtros.codigo = '';
    this.filtros.pagina = 1;

    if (tipo == 'b') {
      var local = this.localizacaoOpcoesBairros[index].bairro + '-' + this.localizacaoOpcoesBairros[index].cidade + '/' + this.localizacaoOpcoesBairros[index].estado;
    } else {
      var local = this.localizacaoOpcoesCidades[index].cidade + '/' + this.localizacaoOpcoesCidades[index].estado;
    }

    var novaLocalizacao = local;
    var dados = this.filtros.localizacao.split(',');
    dados.forEach(function (value) {
      if (value != local && value != '' && value != 'Em-qualquer-localizacao') {
        novaLocalizacao += ',' + value;
      }
    });
    this.setBusca('localizacao', novaLocalizacao);
    this._localizacao = '';
  }

  removeLocalizacao(item) {
    this.filtros.pagina = 1;
    var dados = this.filtros.localizacao.split(',');
    var novoLocalizacao = '';
    dados.forEach(function (value) {
      if (value != item) {
        if (novoLocalizacao != '') {
          novoLocalizacao += ',' + value;
        } else {
          novoLocalizacao = value;
        }
      }
    });
    this.setBusca('localizacao', novoLocalizacao);
  }

  focus() {
    this.localizacaoExibir = true;
  }

  focusOut() {
    let timestampDiffs = [1];
    (async () => {
      for (let item of timestampDiffs) {
        await this.timeout(item * 1000);
        this.localizacaoExibir = false;
      }
    })();
  }

  loadImoveis() {

    this._localizacao = '';
    this.setOpcoes();

    window.scroll(0, 0);
    this.imoveis = false;
    this.request.get('/imoveis/?pagina=' + this.filtros.pagina + '&operacao=' + this.filtros.operacao + '&tipo=' + this.filtros.tipo + '&localizacao=' + this.filtros.localizacao + '&valor_min=' + this.filtros.valor_min + '&valor_max=' + this.filtros.valor_max + '&quarto=' + this.filtros.quarto + '&banheiro=' + this.filtros.banheiro + '&vaga=' + this.filtros.vaga + '&publicado=' + this.filtros.publicado + '&ordenar=' + this.filtros.ordenar + '&codigo=' + this.filtros.codigo + '&area_min=' + this.filtros.area_min + '&area_max=' + this.filtros.area_max)
      .then(res => {
          if (res) {
            this.titulo = res.title;
            this.imoveis = res.result;
            this.total_imoveis = res.sum;
            this.total_paginas = res.paginas;
            this.allItems = res.sum;
            this.setPage(this.filtros.pagina);
            this.seo(res);
          } else {
            this.erro = true;
          }
        },
        err => err);
  }

  setPage(page: number) {
    this.pager = this.pagination.getPager(this.allItems, page);
  }

  abrirOrdem() {
    if (this.listaOrdem) {
      this.listaOrdem = false;
    } else {
      this.listaOrdem = true;
    }
  }

  abrirPublicado() {
    if (this.listaPublicado) {
      this.listaPublicado = false;
    } else {
      this.listaPublicado = true;
    }
  }

  ngOnInit() {

    observableFromEvent(this.localizacaoRef.nativeElement, 'keyup').pipe(
      map((evt: any) => evt.target.value),
      debounceTime(400),
      distinctUntilChanged(),)
      .subscribe((text: string) => {
        this._localizacao = text;

        this.request.get('/Buscas/localizacao?operacao=' + this.filtros.operacao + '&tipo=' + this.filtros.tipo + '&localizacao=' + this.localizacao)
          .then(res => {
              this.localizacaoOpcoesCidades = res.result.cidades;
              this.localizacaoOpcoesBairros = res.result.bairros;
            },
            err => err);
        this.localizacaoExibir = true;
      });

    observableFromEvent(this.valor_minRef.nativeElement, 'keyup').pipe(
      map((evt: any) => evt.target.value),
      debounceTime(600),
      distinctUntilChanged(),)
      .subscribe((text: string) => {
        this.setBusca('valor_min', text);
      });

    observableFromEvent(this.valor_maxRef.nativeElement, 'keyup').pipe(
      map((evt: any) => evt.target.value),
      debounceTime(600),
      distinctUntilChanged(),)
      .subscribe((text: string) => {
        this.setBusca('valor_max', text);
      });

    observableFromEvent(this.area_minRef.nativeElement, 'keyup').pipe(
      map((evt: any) => evt.target.value),
      debounceTime(600),
      distinctUntilChanged(),)
      .subscribe((text: string) => {
        this.setBusca('area_min', text);
      });

    observableFromEvent(this.area_maxRef.nativeElement, 'keyup').pipe(
      map((evt: any) => evt.target.value),
      debounceTime(600),
      distinctUntilChanged(),)
      .subscribe((text: string) => {
        this.setBusca('area_max', text);
      });

    this.loadImoveis();
  }

  seo(res) {
    //CARREGA AS TAGS PARA A PAGINA
    this.title.setTitle(res.title + ' - ' + this.global.Titulo_Portal);
    this.meta.addTag({
      name: 'description',
      content: res.description
    });
    this.meta.addTag({
      name: 'keywords',
      content: res.keywords
    });
    this.meta.addTag({
      name: 'author',
      content: 'Tráfego Integrada'
    });
  }
}

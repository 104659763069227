<app-header></app-header>

<main>
  <h1>Gerenciador de alerta de imóveis</h1>
  <section>
    <div class="card">
      <div class="gernciarAviseme">

        <p>Abaixo a lista dos alertas vinculados ao seu e-mail, para deixar de receber, basta inativar ou então ativar
          para receber as notificações </p>

        <ul class="item-busca" *ngIf="token">
          <ng-container *ngFor="let item of token">
            <li [ngClass]="{'inativo': item['ativo']==='0'}">
              <div>
                {{item['titulo']}}
                <ul>
                  <li><i>
                    <small>Data criação</small>
                    {{item['data']}}</i></li>
                  <li><i>
                    <small>Último envio</small>
                    {{item['data_envio']}}</i></li>
                </ul>

              </div>
              <button class="btn-inativar" (click)="inativarAlerta(item['token'], item['email'])"
                      *ngIf="item['ativo'] == 1"><i class="fas fa-minus-circle"></i> Inativar
              </button>
              <button class="btn-ativar" (click)="ativarAlerta(item['token'], item['email'])"
                      *ngIf="item['ativo'] != 1"><i class="fas fa-check-circle"></i> Ativar
              </button>
            </li>
          </ng-container>
        </ul>
        <div *ngIf="!token" class="confirma">
          <i class="far fa-thumbs-down"></i>
          Nenhum alerta foi encontrado.
        </div>
      </div>
    </div>

    <div class="acao">
      <a href="/avise-me?criar=1" class="criar" title="Criar um novo alerta">CRIAR ALERTA</a>
    </div>
  </section>
</main>

<modal-confirmacao-inativar-modal [token]="token_selecionado" [email]="email"
                                  [modal]="modal"></modal-confirmacao-inativar-modal>
<app-footer></app-footer>

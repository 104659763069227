import {Component} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import {Globals} from "../../app.global";

@Component({
	templateUrl: './termos.component.html',
	styleUrls: ['./termos.component.scss']
})
export class TermosComponent {

	constructor(private title: Title, private meta: Meta, public global: Globals) {
		title.setTitle('Termos de Uso Portal Imobiliário Tráfego Imoveis');
		meta.addTag({
			name: 'description',
			content: 'A Tráfego Imóveis não é uma imobiliária, é um portal imobiliário que encontra para você milhares de oportunidades em imóveis, seja para comprar ou alugar.'
		});
		meta.addTag({
			name: 'keywords',
			content: 'Imóveis à venda e Imóveis para alugar. Na Tráfego Imóveis você encontra casas e apartamentos novos e usados para compra, venda ou aluguel em  Americana, Santa Bárbara d\'Oeste, Sumaré, Nova Odessa. Anuncie seu imóvel!'
		});
		meta.addTag({
			name: 'author',
			content: 'Tráfego Integrada'
		});
	}
}

<div class="card imovel-lista" *ngFor="let imovel of imoveis; let i = index">
  <div class="item" [ngClass]="{ destaque: imovel['config']['patrocinado'] }">
    <span class="destaque" *ngIf="imovel['config']['patrocinado']"
      >DESTAQUE</span
    >
    <div
      class="imagem"
      [ngStyle]="{
        'background-url': 'url(' + global.Api_Imagem + 'portal/loading.gif)'
      }"
    >
      <ui-carousel
        (touchmove)="
          gerarEstatistica(imovel['id'], imovel['imobiliaria']['slug'])
        "
        (click)="gerarEstatistica(imovel['id'], imovel['imobiliaria']['slug'])"
        *ngIf="imovel['imagem'][1]"
        #uiCarousel
        [infinite]="false"
        [autoPlay]="false"
        [arrows]="true"
        [fade]="false"
        [speed]="200"
        [dots]="false"
      >
        <ui-carousel-item
          class="itemmm"
          *ngFor="let imagem of imovel['imagem']"
        >
          <a
            href="/imovel/{{ imovel['id'] }}/{{ imovel['url'] }}"
            title="Mais informações {{ imovel['titulo'] }}"
          >
            <img
              [ui-lazy-load]="
                this.global.Api_Imagem +
                'imoveis/' +
                imovel['imobiliaria']['id'] +
                '/' +
                imagem['name']
              "
              alt="Imagem {{ imovel['titulo'] }}"
            />
          </a>
        </ui-carousel-item>
      </ui-carousel>
      <a
        *ngIf="!imovel['imagem'][1]"
        href="/imovel/{{ imovel['id'] }}/{{ imovel['url'] }}"
        title="Mais informações {{ imovel['titulo'] }}"
      >
        <span class="imagem-unica">
          <img
            *ngIf="imovel['imagem'][0] && !imovel['imagem'][1]"
            [src]="
              this.global.Api_Imagem +
              'imoveis/' +
              imovel['imobiliaria']['id'] +
              '/' +
              imovel['imagem'][0]['name']
            "
            alt="Imagem {{ imovel['titulo'] }}"
          />
          <img
            *ngIf="!imovel['imagem']"
            [src]="this.global.Api_Imagem + 'imoveis/imovel-sem-foto.jpg'"
            alt="Imóvel {{ imovel['titulo'] }} sem foto"
          />
        </span>
      </a>
    </div>
    <div class="detalhes">
      <nav class="compartilhar">
        <button class="btn laranja" (click)="abrirCompatilhe(i)">
          <i class="fas fa-share-alt"></i>
        </button>
        <ul *ngIf="opcao_compartilhar[i]">
          <li>
            <a
              title="Compartilhar {{ imovel['titulo'] }} no Facebook"
              href="https://www.facebook.com/sharer/sharer.php?u={{
                this.global.Portal_Url
              }}/imovel/{{ imovel['id'] }}/{{ imovel['url'] }}"
              target="_blank"
            >
              <i class="fab fa-facebook-f"></i>
              <span>Facebook</span>
            </a>
          </li>
          <li>
            <a
              title="Compartilhar {{ imovel['titulo'] }} no Twitter"
              href="https://twitter.com/home?status={{
                this.global.Portal_Url
              }}/imovel/{{ imovel['id'] }}/{{ imovel['url'] }}"
              target="_blank"
            >
              <i class="fab fa-twitter"></i>
              <span>Twitter</span>
            </a>
          </li>
          <li>
            <a
              title="Compartilhar {{ imovel['titulo'] }} no Whatsapp"
              [href]="
                sanitize(
                  'whatsapp://send?text=' +
                    this.global.Portal_Url +
                    '/imovel/' +
                    imovel['id'] +
                    '/' +
                    imovel['url']
                )
              "
              target="_blank"
            >
              <i class="fab fa-whatsapp"></i>
              <span>Whatsapp</span>
            </a>
          </li>
        </ul>
      </nav>
      <a
        href="/imovel/{{ imovel['id'] }}/{{ imovel['url'] }}"
        title="Mais informações {{ imovel['titulo'] }}"
      >
        <h2>
          <small>{{ imovel["subtitulo"] }}</small>
          <br />
          {{ imovel["tituloN"] }}
        </h2>
        <div class="valores">
          <h4 *ngIf="imovel['valores']">{{ imovel["valores"]["valor"] }}</h4>
          <div
            class="outros"
            *ngIf="
              imovel['valores']['valor_entrada'] ||
              imovel['valores']['valor_condominio'] ||
              imovel['valores']['valor_m2']
            "
          >
            <span></span>
            <i class="fas fa-arrow-right"></i>
            <div class="item" *ngIf="imovel['valores']['valor_entrada']">
              <small>Valor da entrada</small>
              <h6>{{ imovel["valores"]["valor_entrada"] }}</h6>
            </div>
            <div class="item" *ngIf="imovel['valores']['valor_condominio']">
              <small>Valor do condomínio</small>
              <h6>{{ imovel["valores"]["valor_condominio"] }}</h6>
            </div>
            <div class="item" *ngIf="imovel['valores']['valor_m2']">
              <small>Valor do m²</small>
              <h6>{{ imovel["valores"]["valor_m2"] }}</h6>
            </div>
          </div>
        </div>
        <ul
          *ngIf="
            imovel['caracteristicas'] &&
            ((imovel['caracteristicas']['quarto'] &&
              imovel['caracteristicas']['quarto'] > 0) ||
              (imovel['caracteristicas']['banheiro'] &&
                imovel['caracteristicas']['banheiro'] > 0) ||
              (imovel['caracteristicas']['vaga'] &&
                imovel['caracteristicas']['vaga'] > 0) ||
              (imovel['caracteristicas']['area_util'] &&
                imovel['caracteristicas']['area_total'] > 0) ||
              (imovel['caracteristicas']['area_util'] &&
                imovel['caracteristicas']['area_util'] > 0))
          "
          class="caracteristicas"
        >
          <li
            *ngIf="
              imovel['caracteristicas']['quarto'] &&
              imovel['caracteristicas']['quarto'] > 0
            "
          >
            <img
              src="{{ global.Api_Imagem + 'portal/ico-quarto.png' }}"
              width="20"
              alt="Ícone de ilustração para os quartos do imóvel"
            />
            {{ imovel["caracteristicas"]["quarto"] }} Quarto(s)
          </li>
          <li
            *ngIf="
              imovel['caracteristicas']['banheiro'] &&
              imovel['caracteristicas']['banheiro'] > 0
            "
          >
            <img
              src="{{ global.Api_Imagem + 'portal/ico-banheiro.png' }}"
              width="20"
              alt="Ícone de ilustração para os banheiros do imóvel"
            />
            {{ imovel["caracteristicas"]["banheiro"] }} Banheiro(s)
          </li>
          <li
            *ngIf="
              imovel['caracteristicas']['vaga'] &&
              imovel['caracteristicas']['vaga'] > 0
            "
          >
            <img
              src="{{ global.Api_Imagem + 'portal/ico-garagem.png' }}"
              width="20"
              alt="Ícone de ilustração para as vagas de garagem do imóvel"
            />
            {{ imovel["caracteristicas"]["vaga"] }} Vaga(s)
          </li>
          <li
            *ngIf="
              imovel['caracteristicas']['area_util'] &&
              imovel['caracteristicas']['area_total'] > 0
            "
          >
            <img
              src="{{ global.Api_Imagem + 'portal/ico-area.png' }}"
              width="20"
              alt="Ícone de ilustração para as área total do imóvel"
            />
            {{ imovel["caracteristicas"]["area_total"] }}m² Área total
          </li>
          <li
            *ngIf="
              imovel['caracteristicas']['area_util'] &&
              imovel['caracteristicas']['area_util'] > 0
            "
          >
            <img
              src="{{ global.Api_Imagem + 'portal/ico-area.png' }}"
              width="20"
              alt="Ícone de ilustração para as área útil do imóvel"
            />
            {{ imovel["caracteristicas"]["area_util"] }}m² Área útil
          </li>
        </ul>
        <p>{{ imovel["descricao"] }}</p>
      </a>
    </div>
  </div>
  <div class="acao">
    <div class="logo-imobiliaria">
      <img
        *ngIf="!imovel['imobiliaria']['diretoProprietario']"
        src="{{
          global.Api_Imagem + 'logo/' + imovel['imobiliaria']['id']
        }}.jpg"
        title="Logo {{ imovel['imobiliaria']['nome'] }}"
      />
      {{ imovel["imobiliaria"]["proprietario"] }}
    </div>
    <div class="botoes">
      <a
        href="/imovel/{{ imovel['id'] }}/{{ imovel['url'] }}"
        title="Mais informações {{ imovel['titulo'] }}"
        class="btn azul"
      >
        <i class="fas fa-plus"></i> Detalhes
      </a>
      <a
        (click)="entrarContato(imovel['id'])"
        title="Entrar em contato com a {{
          imovel['imobiliaria']['nome']
        }} sobre {{ imovel['titulo'] }}"
        class="btn azul btn-entraremcontato"
      >
        <i class="fas fa-share-square"></i> Entrar em contato
      </a>
      <a
        href="https://api.whatsapp.com/send?phone={{
          imovel['whatsapp']
        }}&text='Olá, tenho interesse no imóvel código {{
          imovel['codigo']
        }}, {{ imovel['titulo'] }}', encontrei ele no {{
          global.Titulo_Portal
        }}. Aguardo o contato. Obrigado. {{ this.global.Portal_Url }}/imovel/{{
          imovel['id']
        }}/{{ imovel['url'] }}';"
        class="btn whatsapp"
        target="_blank"
        title="Falar no whatsapp com a {{
          imovel['imobiliaria']['nome']
        }} sobre {{ imovel['titulo'] }}"
        *ngIf="imovel['whatsapp']"
        (click)="contatoWhatsapp(imovel['id'], imovel['imobiliaria']['slug'])"
      >
        Conversar no <i class="fab fa-whatsapp"></i>
      </a>
    </div>
  </div>
</div>

<app-modal-contato [imovel_id]="imovel_id" [modal]="modal"></app-modal-contato>

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title, Meta} from '@angular/platform-browser';
import {Globals} from '../../app.global';
import {RequestService} from '../../shared/services/request.service';

@Component({
  templateUrl: './editar-aviseme.component.html',
  styleUrls: ['./editar-aviseme.component.scss']
})
export class EditarAvisemeComponent implements OnInit{
  form_excluir: any = [];

  public erroEmailExcluir = false;
  public loadingEmailExcluir = false;
  public confirmouEmailExcluir = false;
  public boxExcluir = false;
  public boxCriar = false;

  public nome: any = '';
  public email: any = '';
  public token: any = '';

  constructor(private route: ActivatedRoute, private title: Title, private meta: Meta, public global: Globals,
              public request: RequestService) {

    title.setTitle('Alerta de imóveis' + ' - ' + this.global.Titulo_Portal);
    meta.addTag({
      name: 'description',
      content: 'Não encontrou o que procura? Cadastre seus interesses que encontraremos pra você! Crie um alerta no ' + this.global.Titulo_Portal
    });
    meta.addTag({
      name: 'keywords',
      content: 'criar alerta, cadastre seus interesses, encontraremos seu imóvel, avise-me, cadastrar'
    });
    meta.addTag({
      name: 'author',
      content: 'Tráfego Integrada'
    });



  }

  ngOnInit(){

    this.route.queryParamMap.subscribe(queryParams => {
      if (queryParams['params']['criar']) {
        this.boxExcluir = false;
        this.boxCriar = true;

        this.confirmouEmailExcluir = false;
        this.erroEmailExcluir = false;
        this.loadingEmailExcluir = false;
      }
    });
  }

  onSubmitExcluir() {
    this.confirmouEmailExcluir = false;
    this.erroEmailExcluir = false;
    this.loadingEmailExcluir = true;
    let email = '';
    if (this.form_excluir.email) {
      email = this.form_excluir.email;
    }
    if (email) {
      this.request.get('/Aviseme/solicitarExclusao?email=' + email)
        .then(res => {
            if (res && res.status) {
              this.request.get('/Envio/envioExcluirAviseme?key=' + res.chave + '&email=' + email)
                .then(res => {
                    if (res && res.status) {
                      this.loadingEmailExcluir = false;
                      this.confirmouEmailExcluir = true;
                    } else {
                      this.loadingEmailExcluir = false;
                      this.confirmouEmailExcluir = false;
                      this.erroEmailExcluir = true;
                    }
                  },
                  err => err);
            } else {
              this.loadingEmailExcluir = false;
              this.confirmouEmailExcluir = false;
              this.erroEmailExcluir = true;
            }
          },
          err => err);
    }
  }

  acaoClick(opcao) {
    this.boxExcluir = false;
    this.boxCriar = false;

    this.confirmouEmailExcluir = false;
    this.erroEmailExcluir = false;
    this.loadingEmailExcluir = false;

    if (opcao === 'excluir') {
      this.boxExcluir = true;
    } else {
      this.boxCriar = true;
    }

  }
}

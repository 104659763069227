<app-header></app-header>

<main>
	<h1>
		Anunciantes do {{global.Titulo_Portal}}
		<br>
		<small>Conheça as imobiliárias e corretores que participam do portal.</small>
	</h1>

	<section>
		<nav *ngIf="cidades">
			<div class="card ">
				<div class="header laranja">
					<h2>Filtre por Cidade</h2>
				</div>
				<div class="content">
					<div *ngIf="!cidades" class="loading-conteudo">
						<img src="{{global.Api_Imagem + 'portal/loading.gif'}}" width="100%" alt="">
					</div>
					<ul *ngIf="cidades">
						<li *ngFor="let city of cidades">
							<a href="/anunciantes/{{city.url_sigla_estado + '/' + city.url_cidade}}"
							   [ngClass]="{'active': cidade===city.url_cidade}"
							   title="Conheça os anunciantes de {{city.cidade + ', ' + city.estado}}">
								{{city.cidade}}
							</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
		<div class="imobiliarias">
			<div *ngIf="!imobiliarias" class="loading-conteudo">
				<img src="{{global.Api_Imagem + 'portal/loading.gif'}}" alt="">
			</div>
			<ul *ngIf="imobiliarias">
				<li *ngFor="let imob of imobiliarias">
					<div class="card">
						<div class="content">
							<a href="/anunciante/{{imob.slug}}" title="{{imob.nome}}" title="Veja os imóveis da {{imob.nome}} de {{imob.cidade}} {{imob.estado}}">
								<img src="{{global.Api_Imagem + 'logo/' + imob.id}}.jpg" alt="{{imob.nome}}">
								<div>
									<h4>{{imob.nome}}</h4>
									<h6>CRECI: {{imob.creci}}</h6>
								</div>
							</a>
						</div>
						<div class="footer">
							<a href="/anunciante/{{imob.slug}}"
							   title="Veja os imóveis da {{imob.nome}} de {{imob.cidade}} {{imob.estado}}"
							   class="btn azul">
								Ver Imóveis
							</a>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</section>
	<app-chamada-anuncie></app-chamada-anuncie>
</main>

<app-footer></app-footer>

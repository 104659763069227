<app-header></app-header>

<main>
  <h1>Anuncie no {{ global.Titulo_Portal }}</h1>

  <section>
    <div class="card">
      <div class="informacoes">
        <div class="dados">
          <h2>CORRETORES E IMOBILIÁRIAS</h2>
          <p>
            Organize o atendimento dos seus clientes e obtenha melhores
            resultados
          </p>
          <ul class="chamadas">
            <li>
              <i class="fas fa-users"></i>
              <h3>
                Cadastro de proprietários e interessados
                <br />
                <small
                  >Simples e objetivo, organize os seus contatos e tenha sempre
                  o total controle.</small
                >
              </h3>
            </li>
            <li>
              <i class="fas fa-home"></i>
              <h3>
                Cadastro de imóveis
                <br />
                <small
                  >Em algumas etapas, seu imóvel será divulgado para milhares de
                  pessoas.</small
                >
              </h3>
            </li>
            <li>
              <i class="fas fa-retweet"></i>
              <h3>
                Cadastro de permuta
                <br />
                <small
                  >Com alguns cliques encontre oportunidade de troca para seu
                  cliente.</small
                >
              </h3>
            </li>
            <li>
              <i class="fas fa-arrows-alt"></i>
              <h3>
                Cruazamento de informações
                <br />
                <small
                  >Os dados serão cruzados para fazer o melhor negócio para seu
                  cliente.</small
                >
              </h3>
            </li>
            <li>
              <i class="far fa-address-card"></i>
              <h3>
                Ficha de atendimento
                <br />
                <small
                  >Moderno e dinâmico, auxilia diariamente nos processos de
                  atendimento.</small
                >
              </h3>
            </li>
            <li>
              <i class="fas fa-share-alt"></i>
              <h3>
                Integração com outros portais/sistemas
                <br />
                <small
                  >Integramos seus imóveis sem precisar ter que cadastrar
                  novamente.</small
                >
              </h3>
            </li>
            <li>
              <i class="fas fa-tv"></i>
              <h3>
                Site personalizado e dinâmico
                <br />
                <small
                  >Tenha total liberdade de alterar as principais informações de
                  seu site.</small
                >
              </h3>
            </li>
          </ul>
        </div>
        <div class="formulario" style="max-width: 430px">
          <div class="opcoes">
            <button
              class="btn-opt {{ Proprietario ? '' : 'active' }}"
              (click)="onOption(false)"
            >
              Sou corretor/imobiliaria
            </button>
            <button
              class="btn-opt {{ Proprietario ? 'active' : '' }}"
              (click)="onOption(true)"
            >
              Sou proprietário
            </button>
          </div>
          <div class="form" *ngIf="!Proprietario">
            <h2>
              Preencha o formulário abaixo e nossa equipe comercial entrará em
              contato!
            </h2>
            <form (ngSubmit)="onSubmit()" #testForm="ngForm" ngNativeValidate>
              <div *ngIf="ExibirFormulario">
                <label for="cmp-anuncie-nome">Seu nome</label>
                <div class="campo">
                  <input
                    type="text"
                    id="cmp-anuncie-nome"
                    placeholder="Seu nome"
                    required
                    [(ngModel)]="form.nome"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>

                <label for="cmp-anuncie-email">Seu e-mail</label>
                <div class="campo">
                  <input
                    type="text"
                    placeholder="E-mail"
                    autocomplete="new-password"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                    id="cmp-anuncie-email"
                    name="email"
                    [(ngModel)]="form.email"
                    [ngModelOptions]="{ standalone: true }"
                    #emailref="ngModel"
                  />
                </div>
                <div
                  class="alerta"
                  *ngIf="
                    emailref.errors && (emailref.touched || emailref.dirty)
                  "
                >
                  <p [hidden]="!emailref.errors?.pattern">
                    <i class="fas fa-exclamation-circle"></i>
                    Informe um e-mail válido
                  </p>
                </div>

                <label for="cmp-anuncie-telefone">Seu telefone</label>
                <div class="campo">
                  <input
                    type="text"
                    placeholder="Telefone"
                    [brmasker]="{ phone: true }"
                    #telefoneRef
                    id="cmp-anuncie-telefone"
                    required
                    #telefoneref="ngModel"
                    [(ngModel)]="form._telefone"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div
                  class="alerta"
                  *ngIf="
                    telefoneref.errors &&
                    (telefoneref.touched || telefoneref.dirty)
                  "
                >
                  <p [hidden]="!telefoneref.errors?.pattern">
                    <i class="fas fa-exclamation-circle"></i> Informe um
                    telefone válido
                  </p>
                </div>

                <label for="cmp-anuncie-cidade">Sua cidade</label>
                <div class="campo">
                  <input
                    type="text"
                    id="cmp-anuncie-cidade"
                    placeholder="Cidade"
                    [(ngModel)]="form.cidade"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>

                <label for="cmp-anuncie-tipo">Tipo de anunciante</label>
                <div class="campo">
                  <select
                    id="cmp-anuncie-tipo"
                    required
                    [(ngModel)]="form.tipo"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="">Selecione a opção</option>
                    <option value="Imobiliária">Imobiliária</option>
                    <option value="Corretor">Corretor</option>
                    <option value="Particular">Particular</option>
                  </select>
                </div>

                <label for="cmp-anuncie-conheceu">Como nos conheceu</label>
                <div class="campo">
                  <select
                    id="cmp-anuncie-conheceu"
                    required
                    [(ngModel)]="form.conheceu"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="">Selecione a opção</option>
                    <option value="E-mail">E-mail</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Google">Google</option>
                    <option value="Indicação">Indicação</option>
                    <option value="Outros">Outros</option>
                  </select>
                </div>

                <button
                  type="submit"
                  [disabled]="!testForm.form.valid"
                  class="btn azul"
                >
                  Enviar mensagem!
                </button>
              </div>
            </form>
            <div *ngIf="ExibirFormularioEnviando" class="loading-conteudo">
              <div>
                <img src="{{ global.Api_Imagem + 'portal/loading.gif' }}" />
                <br />
                <h3>Estamos enviando sua mensagem nesse momento!</h3>
              </div>
            </div>
            <div *ngIf="ExibirFormularioEnviado" class="loading-conteudo">
              <div>
                <h3>
                  <i class="far fa-thumbs-up"></i>
                  <br />
                  Obrigado, iremos avaliar sua mensagem e entraremos em contato
                  o mais breve possível!
                </h3>
              </div>
            </div>
          </div>
          <div class="form proprietario" *ngIf="Proprietario">
            <h2>Anuncie seu imóvel!</h2>
            <p>
              É fácil e rápido! Em menos de 10 minutos seu imóvel pode estar
              visível para milhares de possiveis contatos.
            </p>
            <a
              class="btn-anunciar"
              href="https://anunciar.trafegoimoveis.com.br"
              >Anunciar agora</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="vantagens">
      <h3>
        Descubra as vantagens de anunciar<br />no
        <strong>{{ this.global.Titulo_Portal }}</strong>
      </h3>

      <app-chamada-nossos-numeros [tipo]="'linha'"></app-chamada-nossos-numeros>

      <ul>
        <li>
          <div class="card">
            <div>
              <i class="fas fa-sitemap"></i>
              <h3>Divulgação ilimitada de sua carteira de imóveis</h3>
            </div>
            <p>
              No {{ this.global.Titulo_Portal }} o associado
              (imobiliária/corretor) não tem limites nos anúncios de imóveis
              inseridos no portal. Sua carteira de imóveis estará disponível
              para milhões de internautas todos os dias.
            </p>
          </div>
        </li>
        <li>
          <div class="card">
            <div>
              <i class="fas fa-desktop"></i>
              <h3>Tenha um site totalmente personalizado</h3>
            </div>
            <p>
              Associe-se ao {{ this.global.Titulo_Portal }} e ganhe um site
              personalizado que possui: consultoria na compra do domínio
              (endereço eletrônico); hospedagem; pacote com 3 contas de e-mails;
              manutenção do site;
            </p>
          </div>
        </li>
        <li>
          <div class="card">
            <div>
              <i class="fas fa-cogs"></i>
              <h3>Sistema que proporciona resultados</h3>
            </div>
            <p>
              Avise-me: disparo de anúncios de imóveis por e-mail para
              interessados. Integração com JBI Software House, Union Softwares,
              InGaia, Viva Real, Imoview, OLX, Mercado Livre e Code49. Painel de
              controle com sistema de atendimento.
            </p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</main>

<app-footer></app-footer>

import {Component, Input} from '@angular/core';
import {Globals} from "../../app.global";
import {RequestService} from "../../shared/services/request.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
	selector: 'app-chamada-nossos-numeros',
	templateUrl: './chamada-nossos-numeros.component.html',
	styleUrls: ['./chamada-nossos-numeros.component.scss']
})
export class ChamadaNossosNumerosComponent {
	numeros: string;
	@Input() tipo: string = 'padrao';
	constructor(public global: Globals, public request: RequestService, public deviceService: DeviceDetectorService) {
		console.log(this.tipo);
		if (!this.deviceService.isMobile()) {
			//CARREGAS OS NUMEROS
			this.request.get('/Numeros/portal')
				.then(res => {
						this.numeros = res.result;
					},
					err => err);
		}
	}
}

import {Component, ElementRef, Input, AfterViewInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title, Meta} from '@angular/platform-browser';
import {Globals} from '../../app.global';
import {RequestService} from '../../shared/services/request.service';

declare const google: any;

@Component({
  templateUrl: './fale-conosco.component.html',
  styleUrls: ['./fale-conosco.component.scss']
})
export class FaleConoscoComponent implements AfterViewInit {
  form: any = [];
  public latitude: any = -22.73387;
  public longitude: any = -47.335762;

  public nome: any = '';
  public _telefone: any = '';
  public email: any = '';
  public mensagem: any = '';

  public ExibirFormularioEnviando = false;
  public ExibirFormulario = true;
  public ExibirFormularioEnviado = false;

  @ViewChild('telefoneRef') telefoneRef: ElementRef;

  @ViewChild('map') mapElement: any;
  map: google.maps.Map;

  @Input()
  set telefone(telefone: string) {
    this._telefone = telefone;
  }

  constructor(private route: ActivatedRoute, private title: Title, private meta: Meta, public global: Globals,
              public request: RequestService) {
    title.setTitle('Fale conosco' + ' - ' + this.global.Titulo_Portal);
    meta.addTag({
      name: 'description',
      content: 'Fale com o  ' + this.global.Titulo_Portal + ', somos de Americana/SP estamos sempre pronto para tirar suas dúvidas.'
    });
    meta.addTag({
      name: 'keywords',
      content: 'fale conosco, anunciar imóvel, como anunciar meu imóvel, anunciar casa, anunciar apartamento, ' +
        'como anunciar meu apartamento, vender imóvel, vender apartamento, alugar imovel, alugar casa, vender casa, ' +
        'alugar apartamento, anunciar imóvel direto proprietário'
    });
    meta.addTag({
      name: 'author',
      content: 'Tráfego Integrada'
    });
  }

  ngAfterViewInit(): void {
    let marker;
    let contentString;
    let infowindow;
    const marcar = {lat: this.latitude, lng: this.longitude};

    const mapProperties = {
      center: new google.maps.LatLng(this.latitude, this.longitude),
      zoom: 14,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      title: this.global.Titulo_Portal
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);

    contentString = '<div id="content">' +
      '<div id="siteNotice">' +
      '</div>' +
      '<h3 id="firstHeading" class="firstHeading" style="margin:0px 0px 10px 0px;  color:#404E67">' + this.global.Titulo_Portal +
      '</h3><div id="bodyContent">' +
      '<p style="margin: 0px; font-size: 14px; color:#404E67">' +
      '(019) 3387-7547' +
      '<br>' +
      'falecom@trafegoimoveis.com.br</p>' +
      '</div>' +
      '</div>';
    marker = new google.maps.Marker({
      position: marcar,
      map: this.map,
      title: this.global.Titulo_Portal
    });

    infowindow = new google.maps.InfoWindow({
      content: contentString
    });

    infowindow.open(this.map, marker);
    marker.setMap(this.map);
  }

  onSubmit() {
    this.ExibirFormularioEnviando = true;
    this.ExibirFormulario = false;
    this.ExibirFormularioEnviado = false;
    this.request.get('/Envio/enviosPortal?referencia=fale-conosco&assunto=Fale Conosco&nome=' + this.nome + '&email=' + this.email +
      '&telefone=' + this._telefone + '&mensagem=' + this.mensagem)
      .then(res => {
          this.ExibirFormularioEnviando = false;
          this.ExibirFormulario = false;
          this.ExibirFormularioEnviado = true;

          this.nome = '';
          this.telefone = '';
          this._telefone = '';
          this.email = '';
          this.mensagem = '';
        },
        err => err);
  }
}

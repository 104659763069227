<app-header></app-header>
<main>
  <aside [ngClass]="{'exibir':exibirBusca}">
    <div class="busca">
      <button class="btn alerta fechar-busca" (click)="abrirBusca()">
        <i class="fas fa-times"></i>
      </button>
      <h1>Editar Filtros</h1>
      <form autocomplete="off">
        <div class="grupo filtro-operacao">
          <div class="operacao">
            <ul>
              <li>
                <a (click)="setBusca('operacao', 'Venda')" class="btn"
                   [ngClass]="{'active': filtros.operacao == 'Venda'}">Venda</a>
              </li>
              <li>
                <a (click)="setBusca('operacao', 'Aluguel')" class="btn"
                   [ngClass]="{'active': filtros.operacao == 'Aluguel'}">Aluguel</a>
              </li>
              <li>
                <a (click)="setBusca('operacao', 'Temporada')" class="btn"
                   [ngClass]="{'active': filtros.operacao == 'Temporada'}">Temporada</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="grupo">
          <div>
            <label for="cmp-busca-localizacao">Localização</label>
            <input type="text" style="display: none" name="new-password" id="new-password">
            <div class="campo">
              <div class="campo-loading"
                   *ngIf="localizacaoExibir && !localizacaoOpcoesCidades && !localizacaoOpcoesBairros">
                <img src="{{global.Api_Imagem + 'portal/loading.gif'}}" alt="">
              </div>
              <input type="text" placeholder="Bairro ou Cidade"
                     id="cmp-busca-localizacao" autocomplete="new-password" autofill="off"
                     name="cmp-busca-localizacao" required
                     [(ngModel)]="_localizacao" #localizacaoRef (focusout)="focusOut()" (focus)="focus()"
                     [ngModelOptions]="{standalone: true}">
              <div class="sugestoes"
                   *ngIf="localizacaoExibir && (localizacaoOpcoesCidades || localizacaoOpcoesBairros)">
                <ul>
                  <h2 *ngIf="localizacaoOpcoesCidades">Cidades</h2>
                  <li *ngFor="let item of localizacaoOpcoesCidades; let i = index">
                    <a (click)="onChangeLocalizacao(i, 'c')">
                      {{item['cidade']}}/{{item['estado']}}
                    </a>
                  </li>
                  <h2 *ngIf="localizacaoOpcoesBairros">Bairros</h2>
                  <li *ngFor="let item of localizacaoOpcoesBairros; let i = index">
                    <a (click)="onChangeLocalizacao(i, 'b')">
                      {{item['bairro']}}, {{item['cidade']}}/{{item['estado']}}
                    </a>
                  </li>
                </ul>
              </div>
              <ul class="item-busca" *ngIf="localizacaoFiltros">
                <ng-container *ngFor="let item of localizacaoFiltros">
                  <li *ngIf="item != 'Todos'">
                    {{item}}
                    <button class="btn-excluir" (click)="removeLocalizacao(item)">x</button>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
        <div class="grupo">
          <div>
            <label for="cmp-busca-tipo">Tipo do Imóvel</label>
            <div class="campo">
              <select class="form-control" id="cmp-busca-tipo"
                      required
                      (change)="onChangeTipo()"
                      [(ngModel)]="tipo"
                      [ngModelOptions]="{standalone: true}">
                <option *ngFor="let valor of tipoOpcoes"
                        [value]="valor['titulo']">
                  {{ valor['titulo'] }}
                </option>
              </select>
              <ul class="item-busca" *ngIf="tipoFiltros">
                <ng-container *ngFor="let item of tipoFiltros">
                  <li *ngIf="item != 'Todos'">
                    {{item}}
                    <button class="btn alerta" (click)="removeTipo(item)">x</button>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
        <div class="grupo">
          <div>
            <label for="cmp-busca-valor-min">Valor mínimo</label>
            <div class="campo icone">
              <input type="text" pattern="\d*" inputmode="numeric" id="cmp-busca-valor-min"
                     class="form-control min" name="cmp-busca-valor-min"
                     [(ngModel)]="_valor_min" autocomplete="off"
                     placeholder="Ilimitado"
                     currencyMask
                     [options]="{ prefix: '', thousands: '.', decimal: ',' }"
                     #valor_minRef
                     [ngModelOptions]="{standalone: true}">
              <i class="fas fa-dollar-sign"></i>
            </div>
          </div>
          <div>
            <label for="cmp-busca-valor-max">Valor máximo</label>
            <div class="campo icone">
              <input type="text" id="cmp-busca-valor-max"
                     class="form-control max" name="cmp-busca-valor-max" #valor_maxRef currencyMask placeholder="Ilimitado"
                     [options]="{ prefix: '', thousands: '.', decimal: ',' }"
                     [(ngModel)]="_valor_max" [ngModelOptions]="{standalone: true}" autocomplete="off">
              <i class="fas fa-dollar-sign"></i>
            </div>
          </div>
        </div>
        <div class="grupo">
          <div>
            <label for="cmp-busca-area-min">Área mínima</label>
            <div class="campo icone">
              <input type="tel" pattern="\d*" inputmode="numeric" id="cmp-busca-area-min"
                     class="form-control min" name="cmp-busca-area-min"
                     [(ngModel)]="_area_min" autocomplete="off"
                     placeholder="Ilimitado"
                     currencyMask
                     [options]="{ prefix: '', thousands: '.', decimal: ',' }"
                     #area_minRef
                     [ngModelOptions]="{standalone: true}">
              <i class="fas fa-ruler-combined"></i>
            </div>
          </div>
          <div>
            <label for="cmp-busca-area-max">Área máxima</label>
            <div class="campo icone">
              <input type="tel" pattern="\d*" inputmode="numeric" id="cmp-busca-area-max"
                     class="form-control max" name="cmp-busca-area-max"
                     [(ngModel)]="_area_max" autocomplete="off"
                     placeholder="Ilimitado"
                     currencyMask
                     [options]="{ prefix: '', thousands: '.', decimal: ',' }"
                     #area_maxRef
                     [ngModelOptions]="{standalone: true}">
              <i class="fas fa-ruler-combined"></i>
            </div>
          </div>
        </div>
        <div class="grupo">
          <div>
            <label>Quartos</label>
            <div class="itens">
              <button type="button" (click)="setBusca('quarto', '1')"
                      [ngClass]="[filtros.quarto == 1 ? 'btn active' : 'btn']">1+
              </button>
              <button type="button" (click)="setBusca('quarto', '2')"
                      [ngClass]="[filtros.quarto == 2 ? 'btn active' : 'btn']">2+
              </button>
              <button type="button" (click)="setBusca('quarto', '3')"
                      [ngClass]="[filtros.quarto == 3 ? 'btn active' : 'btn']">3+
              </button>
              <button type="button" (click)="setBusca('quarto', '4')"
                      [ngClass]="[filtros.quarto == 4 ? 'btn active' : 'btn']">4+
              </button>
              <button type="button" (click)="setBusca('quarto', '5')"
                      [ngClass]="[filtros.quarto == 5 ? 'btn active' : 'btn']">5+
              </button>
            </div>
          </div>
        </div>
        <div class="grupo">
          <div>
            <label>Banheiros</label>
            <div class="itens">
              <button type="button" (click)="setBusca('banheiro', '1')"
                      [ngClass]="[filtros.banheiro == 1 ? 'btn active' : 'btn']">1+
              </button>
              <button type="button" (click)="setBusca('banheiro', '2')"
                      [ngClass]="[filtros.banheiro == 2 ? 'btn active' : 'btn']">2+
              </button>
              <button type="button" (click)="setBusca('banheiro', '3')"
                      [ngClass]="[filtros.banheiro == 3 ? 'btn active' : 'btn']">3+
              </button>
              <button type="button" (click)="setBusca('banheiro', '4')"
                      [ngClass]="[filtros.banheiro == 4 ? 'btn active' : 'btn']">4+
              </button>
              <button type="button" (click)="setBusca('banheiro', '5')"
                      [ngClass]="[filtros.banheiro == 5 ? 'btn active' : 'btn']">5+
              </button>
            </div>
          </div>
        </div>
        <div class="grupo">
          <div>
            <label>Vagas</label>
            <div class="itens">
              <button type="button" (click)="setBusca('vaga', '1')"
                      [ngClass]="[filtros.vaga == 1 ? 'btn active' : 'btn']">1+
              </button>
              <button type="button" (click)="setBusca('vaga', '2')"
                      [ngClass]="[filtros.vaga == 2 ? 'btn active' : 'btn']">2+
              </button>
              <button type="button" (click)="setBusca('vaga', '3')"
                      [ngClass]="[filtros.vaga == 3 ? 'btn active' : 'btn']">3+
              </button>
              <button type="button" (click)="setBusca('vaga', '4')"
                      [ngClass]="[filtros.vaga == 4 ? 'btn active' : 'btn']">4+
              </button>
              <button type="button" (click)="setBusca('vaga', '5')"
                      [ngClass]="[filtros.vaga == 5 ? 'btn active' : 'btn']">5+
              </button>
            </div>
          </div>
        </div>
      </form>
      <button class="btn destaque aplicar-busca" (click)="abrirBusca()">
        <i class="fas fa-check-double"></i> Aplicar Filtros
      </button>
    </div>
    <div class="aviseme">
      <button class="btn azul" (click)="abrirAviseme()"><i class="far fa-bell"></i> Criar Alerta</button>
    </div>
  </aside>
  <div class="conteudo">
    <div class="geral">
      <button class="btn destaque filtro-mobile" (click)="abrirBusca()">
        <i class="fas fa-search"></i> Filtrar Imóveis
      </button>
      <section class="filtros-rapidos">
        <ul class="filtro-operacao">
          <li>
            <a (click)="setBusca('operacao', 'Venda')" class="btn"
               [ngClass]="{'active': filtros.operacao == 'Venda'}">Venda</a>
          </li>
          <li>
            <a (click)="setBusca('operacao', 'Aluguel')" class="btn"
               [ngClass]="{'active': filtros.operacao == 'Aluguel'}">Aluguel</a>
          </li>
          <li>
            <a (click)="setBusca('operacao', 'Temporada')" class="btn"
               [ngClass]="{'active': filtros.operacao == 'Temporada'}">Temporada</a>
          </li>
        </ul>
        <ul>
          <li>
            <a class="btn" [ngSwitch]="filtros.publicado" (click)="abrirPublicado()">
              <i class="far fa-calendar-alt"></i>
              <span *ngSwitchCase="''">Publicado em qualquer data</span>
              <span *ngSwitchCase="3">Publicado até 3 dias</span>
              <span *ngSwitchCase="7">Publicado até 7 dias</span>
              <span *ngSwitchCase="15">Publicado até 15 dias</span>
              <span *ngSwitchCase="30">Publicado até 30 dias</span>
            </a>
            <ul *ngIf="listaPublicado">
              <li>
                <a [ngClass]="{'active': filtros.publicado == ''}" (click)="setBusca('publicado', '')">Qualquer
                  data</a>
              </li>
              <li>
                <a [ngClass]="{'active': filtros.publicado == 3}" (click)="setBusca('publicado', '3')">3
                  dias</a>
              </li>
              <li>
                <a [ngClass]="{'active': filtros.publicado == 7}" (click)="setBusca('publicado', '7')">7
                  dias</a>
              </li>
              <li>
                <a [ngClass]="{'active': filtros.publicado == 15}"
                   (click)="setBusca('publicado', '15')">15 dias</a>
              </li>
              <li>
                <a [ngClass]="{'active': filtros.publicado == 30}"
                   (click)="setBusca('publicado', '30')">30 dias</a>
              </li>
            </ul>
          </li>
          <li>
            <a class="btn" [ngSwitch]="filtros.ordenar" (click)="abrirOrdem()">
              <i class="fas fa-sort"></i>
              <span *ngSwitchCase="''">Ordenar listagem por</span>
              <span *ngSwitchCase="'valorImovel ASC'">Ordenar por menor valor</span>
              <span *ngSwitchCase="'valorImovel DESC'">Ordenar por maior valor</span>
              <span *ngSwitchCase="'cidade'">Ordenar por cidade</span>
              <span *ngSwitchCase="'bairro'">Ordenar por bairro</span>
              <span *ngSwitchCase="'sys_data_created'">Ordenar por mais recente</span>
            </a>
            <ul *ngIf="listaOrdem">
              <li>
                <a [ngClass]="{'active': filtros.ordenar == 'valorImovel ASC'}"
                   (click)="setBusca('ordenar', 'valorImovel ASC')">Menor valor</a>
              </li>
              <li>
                <a [ngClass]="{'active': filtros.ordenar == 'valorImovel DESC'}"
                   (click)="setBusca('ordenar', 'valorImovel DESC')">Maior valor</a>
              </li>
              <li>
                <a [ngClass]="{'active': filtros.ordenar == 'cidade'}"
                   (click)="setBusca('ordenar', 'cidade')">Cidade</a>
              </li>
              <li>
                <a [ngClass]="{'active': filtros.ordenar == 'bairro'}"
                   (click)="setBusca('ordenar', 'bairro')">Bairro</a>
              </li>
              <li>
                <a [ngClass]="{'active': filtros.ordenar == 'sys_data_created'}"
                   (click)="setBusca('ordenar', 'sys_data_created')">Mais recente</a>
              </li>
            </ul>
          </li>
        </ul>
      </section>
      <section>
        <div *ngIf="!imoveis && !erro" class="loading-conteudo">
          <img src="{{global.Api_Imagem + 'portal/loading.gif'}}" alt="">
        </div>
        <div class="card total_imoveis" *ngIf="imoveis && !erro">
          <h1>Foram encontrados <strong>{{total_imoveis | numeroPipe:locale}}</strong> {{titulo}}.</h1>
        </div>
        <app-lista-imoveis *ngIf="imoveis && !erro" [imoveis]="imoveis"></app-lista-imoveis>
      </section>
      <div *ngIf="imoveis && !erro">
        <nav class="paginacao" aria-label="Paginação dos Imóveis">
          <!-- pager -->
          <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
            <li *ngIf="pager.currentPage > 1" class="page-item">
              <a class="page-link" (click)="setBusca('pagina', '1')">Primeira</a>
            </li>
            <li *ngIf="pager.currentPage > 1" class="page-item btn-anterior"
                [ngClass]="{disabled:pager.currentPage == 1}">
              <a class="page-link" (click)="setBusca('pagina', pager.currentPage - 1)">Anterior</a>
            </li>
            <li class="page-item" *ngFor="let page of pager.pages"
                [ngClass]="{active:pager.currentPage === page}">
              <a class="page-link" (click)="setBusca('pagina', page)">{{page}}</a>
            </li>
            <li *ngIf="pager.currentPage < pager.totalPages" class="page-item btn-proximo">
              <a class="page-link btn-proximo"
                 (click)="setBusca('pagina', pager.currentPage + 1)">Próxima</a>
            </li>
            <li *ngIf="pager.currentPage < pager.totalPages" class="page-item">
              <a class="page-link" (click)="setBusca('pagina', pager.totalPages)">Última</a>
            </li>
          </ul>
        </nav>
      </div>
      <app-chamada-erro *ngIf="erro"></app-chamada-erro>
      <app-footer></app-footer>
    </div>
  </div>
</main>

<app-modal-aviseme [abrir]="exibirAviseme" [localidade]="localizacaoFiltros" [operacao]="filtros['operacao']"
                   [tipo]="tipoFiltros" [valor_max]="filtros['valor_max']" [valor_min]="filtros['valor_min']"
                   [area_min]="filtros['area_min']" [area_max]="filtros['area_max']"></app-modal-aviseme>

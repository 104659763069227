import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {RequestService} from '../../shared/services/request.service';
import {Globals} from '../../app.global';

@Component({
  selector: 'modal-confirmacao-inativar-modal',
  templateUrl: './confirmacao-inativar-modal.component.html',
  styleUrls: ['./confirmacao-inativar-modal.component.scss']
})

export class ConfirmacaoInativarModalComponent implements OnChanges {
  @Input() email: '';
  @Input() token: '';
  @Input() modal: '';
  public abrir_modal: boolean = false;
  ExibirFormulario: boolean = true;
  ExibirFormularioEnviando: boolean = true;

  form: any = [];

  motivoOpcoes = [
    {titulo: 'A frequência de envios é muito alta.'},
    {titulo: 'Já encontrei meu imóvel.'},
    {titulo: 'O conteúdo é irrelevante.'},
    {titulo: 'Prefiro buscar informações por outros meios.'}
  ];

  constructor(public request: RequestService, public global: Globals) {
  }

  ngOnChanges(changes: SimpleChanges) {

    this.form.motivo = '';

    this.ExibirFormulario = true;
    this.ExibirFormularioEnviando = true;
    if (this.modal && this.token) {
      this.abrir_modal = true;
    }
  }

  onSubmit() {

    let token = this.token;
    let motivo;
    let email;

    if (this.email) {
      email = this.email;
    }
    if (this.form.motivo) {
      motivo = this.form.motivo;
    }

    this.ExibirFormulario = false;
    this.ExibirFormularioEnviando = true;

    this.request.get('/Aviseme/inativar?token=' + token + '&email=' + email + '&motivo=' + motivo)
      .then(res => {
          this.ExibirFormularioEnviando = false;
          this.ExibirFormulario = false;

          this.form.motivo = '';
        },
        err => err);
  }

  fechar() {
    this.abrir_modal = false;
    window.location.reload();
  }
}

import { fromEvent as observableFromEvent, Observable } from "rxjs";

import { distinctUntilChanged, map } from "rxjs/operators";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { Globals } from "../../app.global";
import { RequestService } from "../../shared/services/request.service";

@Component({
  templateUrl: "./anunciar.component.html",
  styleUrls: ["./anunciar.component.scss"],
})
export class AnunciarComponent implements AfterViewInit {
  numeros: string;
  form: any = [];

  public ExibirFormularioEnviando: boolean = false;
  public ExibirFormulario: boolean = true;
  public ExibirFormularioEnviado: boolean = false;
  public Proprietario: boolean = false;

  @ViewChild("telefoneRef") telefoneRef: ElementRef;
  @Input()
  set telefone(telefone: string) {
    this.form._telefone = telefone;
  }

  constructor(
    private title: Title,
    private meta: Meta,
    public global: Globals,
    public request: RequestService
  ) {
    this.telefone = "";
    title.setTitle(
      "Corretores e Imobiliárias" + " - " + this.global.Titulo_Portal
    );
    meta.addTag({
      name: "description",
      content:
        "Anuncie no " +
        this.global.Titulo_Portal +
        " e ganhe um site personalizado que possui: Consultoria na compra do domínio(endereço eletrônico); Hospedagem; Pacote com 5 contas de e-mails (com limite de 5GB para cada conta); Manutenção do site; ",
    });
    meta.addTag({
      name: "keywords",
      content:
        "anunciar imóvel, como anunciar meu imóvel, anunciar casa, anunciar apartamento, como anunciar meu apartamento, vender imóvel, vender apartamento, alugar imovel, alugar casa, vender casa, alugar apartamento, anunciar imóvel direto proprietário",
    });
    meta.addTag({
      name: "author",
      content: "Tráfego Integrada",
    });
  }

  ngAfterViewInit() {
    this.form.nome = "";
    this.form._telefone = "";
    this.form.email = "";
    this.form.cidade = "";
    this.form.tipo = "";
    this.form.conheceu = "";

    //CARREGAS OS NUMEROS
    this.request.get("/Numeros/portal").then(
      (res) => {
        this.numeros = res.result;
      },
      (err) => err
    );

    observableFromEvent(this.telefoneRef.nativeElement, "keyup")
      .pipe(
        map((evt: any) => evt.target.value),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        console.log("teste");
        this.form._telefone = text;
      });
  }

  onOption(value) {
    this.Proprietario = value;
  }

  onSubmit() {
    this.ExibirFormularioEnviando = true;
    this.ExibirFormulario = false;
    this.ExibirFormularioEnviado = false;
    this.request
      .get(
        "/Envio/enviosPortal?referencia=anunciar&assunto=Anunciar&nome=" +
          this.form.nome +
          "&email=" +
          this.form.email +
          "&telefone=" +
          this.form._telefone +
          "&cidade=" +
          this.form.cidade +
          "&tipo=" +
          this.form.tipo +
          "&conheceu=" +
          this.form.conheceu
      )
      .then(
        (res) => {
          this.ExibirFormularioEnviando = false;
          this.ExibirFormulario = false;
          this.ExibirFormularioEnviado = true;

          this.form.nome = "";
          this.form._telefone = "";
          this.form.email = "";
          this.form.cidade = "";
          this.form.tipo = "";
          this.form.conheceu = "";
        },
        (err) => err
      );
  }
}

import { ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { Globals } from '../../app.global';
import { RequestService } from '../../shared/services/request.service';
var AvisemeComponent = /** @class */ (function () {
    function AvisemeComponent(request, global) {
        this.request = request;
        this.global = global;
        this.filtros = {
            operacao: '',
            tipo: '',
            localizacao: '',
            valor_min: '',
            valor_max: '',
            area_max: '',
            area_min: ''
        };
        this.ExibirFormulario = true;
        this.ExibirFormularioEnviando = false;
        this.ExibirFormularioEnviado = false;
        this._valor_min = this.valor_min;
        this._valor_max = this.valor_max;
        this._area_max = this.area_max;
        this._area_min = this.area_min;
        this.abrir_modal = false;
        this.abrir_cont = 0;
        this.form = [];
        this.aviso = false;
        this.form.nome = '';
        this.form.email = '';
        this.form.tipo = '';
        this.form.localizacao = '';
        this.form.valor_max = '';
        this.form.valor_min = '';
        this.form.area_max = '';
        this.form.area_min = '';
        this.ExibirFormulario = true;
        this.ExibirFormularioEnviando = false;
        this.ExibirFormularioEnviado = false;
    }
    Object.defineProperty(AvisemeComponent.prototype, "localizacao", {
        set: function (localizacao) {
            this.filtros.localizacao = localizacao;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvisemeComponent.prototype, "operacao", {
        set: function (operacao) {
            this.filtros.operacao = operacao;
        },
        enumerable: true,
        configurable: true
    });
    AvisemeComponent.prototype.ngOnChanges = function (changes) {
        this.ExibirFormularioEnviado = false;
        this.ExibirFormulario = true;
        this.ExibirFormularioEnviando = false;
        this._valor_min = this.valor_min;
        this._valor_max = this.valor_max;
        this._area_max = this.area_max;
        this._area_min = this.area_min;
        this.filtros.localizacao = this.localidade;
        this.filtros.tipo = this.tipo;
        if (this.abrir && this.abrir_cont !== this.abrir) {
            this.abrir_cont = this.abrir;
            this.abrir_modal = true;
        }
    };
    AvisemeComponent.prototype.fechar = function () {
        this.abrir_modal = false;
    };
    AvisemeComponent.prototype.onSubmit = function () {
        var _this = this;
        var nome = '';
        var email = '';
        var operacao = '';
        var tipo = '';
        var localidade = '';
        var valor_max = '';
        var valor_min = '';
        var area_max = '';
        var area_min = '';
        if (this.form.nome) {
            nome = this.form.nome;
        }
        if (this.form.email) {
            email = this.form.email;
        }
        if (this.form.operacao_venda) {
            if (operacao != '') {
                operacao += ',';
            }
            operacao += 'Venda';
        }
        if (this.form.operacao_locacao) {
            if (operacao != '') {
                operacao += ',';
            }
            operacao += 'Locação';
        }
        if (this.form.operacao_temporada) {
            if (operacao != '') {
                operacao += ',';
            }
            operacao += 'Temporada';
        }
        if (this.filtros.tipo) {
            tipo = this.filtros.tipo;
        }
        if (this.filtros.localizacao) {
            localidade = this.filtros.localizacao;
        }
        if (this._valor_max) {
            valor_max = this._valor_max;
        }
        if (this._valor_min) {
            valor_min = this._valor_min;
        }
        if (this._area_max) {
            area_max = this._area_max;
        }
        if (this._area_min) {
            area_min = this._area_min;
        }
        console.log(nome);
        console.log(email);
        console.log(operacao);
        console.log(tipo);
        console.log(localidade);
        if (nome && email && this.filtros.operacao && this.filtros.tipo && this.filtros.localizacao) {
            this.ExibirFormularioEnviado = false;
            this.ExibirFormulario = false;
            this.ExibirFormularioEnviando = true;
            this.request.get('/Aviseme/criar?operacao=' + this.filtros.operacao + '&tipo=' + this.filtros.tipo + '&localidade=' + this.filtros.localizacao + '&valor_max=' + valor_max +
                '&valor_min=' + valor_min + '&area_max=' + area_max + '&area_min=' + area_min + '&nome=' + nome + '&email=' + email)
                .then(function (res) {
                if (res && res.status) {
                    _this.ExibirFormularioEnviando = false;
                    _this.ExibirFormularioEnviado = true;
                }
                else {
                    return false;
                }
            }, function (err) { return err; });
        }
        else {
            this.aviso = true;
        }
    };
    AvisemeComponent.prototype.ngOnInit = function () {
        /*
                this.getTipo();
                this.setFiltos();
                */
    };
    return AvisemeComponent;
}());
export { AvisemeComponent };
